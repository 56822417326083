import gql from "graphql-tag";

export const noticeProductCategoriesAndCurrenciesQuery = gql`
  query noticeProductCategoriesAndCurrencies($id: ID!) {
    notice(id: $id) {
      name
      description
      category {
        id
      }
      currency {
        id
      }
      price
      quantity
      location
      isNew
      condition
      isActive
      contactName
      contactPhone
      files {
        id
        name
        link
      }
    }
    productCategories {
      id
      description
      parent
    }
    currencies {
      id
      symbol
      code
    }
  }
`;

import i18n from "@/plugins/vue-i18n";

const Leads = () => import(/* webpackChunkName: "leads" */ "@/views/leads/index");

const leads = [
  {
    path: "/leads",
    name: "Leads",
    component: Leads,
    meta: {
      title: i18n.t("title.leads.pageTitle"),
    },
  },
];

export default leads;

<template>
  <div ref="radioInput" class="radio-wrapper">
    <t-radio
      :id="id"
      :disabled="disabled"
      class="radio"
      :name="radioName"
      :value="value"
      :data-cy="dataCy"
      :checked="checked"
      @focus="onFocus"
    />

    <div>
      <label v-if="label" :for="id" class="radio-label">{{ label }}</label>

      <!-- @slot Use it to add some description. -->
      <div class="radio-description">
        <slot name="description" :description="description">
          <label v-if="description" :for="id">{{ description }}</label>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import { getRandomId } from "@/services/_bit/UiService";

import TRadio from "vue-tailwind/dist/t-radio";

export default {
  name: "OurRadio",

  components: { TRadio },

  props: {
    /**
     * Set radio value.
     */
    value: {
      type: [String, Number],
      default: "",
    },

    /**
     * Set label.
     */
    label: {
      type: String,
      default: "",
    },

    /**
     * Set description.
     */
    description: {
      type: [String, Object],
      default: "",
    },

    /**
     * Set name for radio.
     */
    name: {
      type: String,
      default: "",
    },

    /**
     * Make radio group inactive.
     */
    disabled: {
      type: Boolean,
      default: false,
    },

    /**
     * Make a radio group item checked.
     */
    checked: {
      type: Boolean,
      default: false,
    },

    /**
     * Generates unique element id.
     * @ignore
     */
    id: {
      type: String,
      default: () => getRandomId(),
    },

    /**
     * Sets data-cy attribute for correct element sampling in tests.
     */
    dataCy: {
      type: String,
      default: "",
    },
  },

  data: () => ({
    selectedItem: "",
    radioName: "",
  }),

  mounted() {
    const radioGroup = this.$refs.radioInput.__vue__.$parent;

    this.radioName = this.name || radioGroup.name;

    this.setDefaultValue();
  },

  methods: {
    onFocus(event) {
      this.setValue();

      this.$emit("input", event.target.value);
    },

    setValue() {
      const radioGroup = this.$refs.radioInput.__vue__.$parent;

      this.selectedItem = this.value;

      if (radioGroup.$refs.OurRadioGroup) {
        radioGroup.selectedItem = this.selectedItem;
      }
    },

    setDefaultValue() {
      const radioGroup = this.$refs.radioInput.__vue__.$parent;

      if (radioGroup.selectedItem && radioGroup.selectedItem !== this.value) {
        return;
      }

      this.selectedItem = this.value;
    },
  },
};
</script>

<style lang="postcss" scoped>
.radio {
  @apply border border-solid border-gray-300;
  @apply h-6 w-6;

  &:hover {
    @apply border-gray-400;
  }

  &:active {
    @apply border-gray-900 bg-gray-900;
  }

  &:focus {
    @apply border-gray-500 !bg-gray-900 ring-4 ring-gray-200;
  }

  &:checked {
    @apply border-gray-900 bg-gray-900;

    &:hover {
      @apply bg-gray-900;
    }
  }

  &:disabled {
    @apply border-gray-100 bg-gray-100;
  }

  &-wrapper {
    @apply inline-flex;
  }

  &-label {
    @apply block;
    @apply text-base font-normal text-gray-900;
    @apply pl-4 pt-0.5;
  }

  &-description {
    @apply text-xs font-normal text-gray-500/[85];
    @apply pl-4 pt-1;
  }
}
</style>

import i18n from "@/plugins/vue-i18n";

export default {
  namespaced: true,
  state: {
    status: {
      collectionOfInformation: {
        text: i18n.t("serviceCases.status.collectionOfInformation"),
        color: "blue",
        value: "collectionOfInformation",
      },
      deliveryForDiagnostics: {
        text: i18n.t("serviceCases.status.deliveryForDiagnostics"),
        color: "orange",
        value: "deliveryForDiagnostics",
      },
      diagnosticsAndRepair: {
        text: i18n.t("serviceCases.status.diagnosticsAndRepair"),
        color: "orange",
        value: "diagnosticsAndRepair",
      },
      serviceFromManufacturer: {
        text: i18n.t("serviceCases.status.serviceFromManufacturer"),
        color: "orange",
        value: "serviceFromManufacturer",
      },
      settlementsOrReturn: {
        text: i18n.t("serviceCases.status.settlementsOrReturn"),
        color: "orange",
        value: "settlementsOrReturn",
      },
      returnOfSubstitution: {
        text: i18n.t("serviceCases.status.returnOfSubstitution"),
        color: "orange",
        value: "returnOfSubstitution",
      },
      closed: {
        text: i18n.t("serviceCases.status.closed"),
        color: "green",
        value: "closed",
      },
      rejected: {
        text: i18n.t("serviceCases.status.rejected"),
        color: "red",
        value: "rejected",
      },
    },
    filtersForm: null,
  },
  getters: {
    getStatuses(state) {
      return Object.values(state.status);
    },

    getStatus(state) {
      return (status) => {
        return state.status[status];
      };
    },

    getFiltersOption() {
      return (column, value) => {
        const columns = ["hasWarranty", "status"];
        const operator = columns.includes(column) ? "" : "like";

        return {
          method: "where",
          column,
          operator,
          value,
        };
      };
    },

    getFiltersOptions(state, getters) {
      return () => {
        const filtersForm = state.filtersForm;
        let filtersOptions = [];

        if (filtersForm) {
          Object.keys(filtersForm).forEach((key) => {
            if (filtersForm[key] && key !== "date") {
              const column = key;
              const value = filtersForm[key];

              filtersOptions.push(getters.getFiltersOption(column, value));
            }
          });

          if (Object.values(filtersForm.date).length) {
            filtersOptions.unshift({
              method: "where",
              column: "createdAt",
              operator: "date>=",
              value: filtersForm.date.from.toString(),
            });

            filtersOptions.unshift({
              method: "where",
              column: "createdAt",
              operator: "date<=",
              value: filtersForm.date.to.toString(),
            });
          }
        }

        return filtersOptions;
      };
    },
  },
  mutations: {
    SET_FILTERS_FORM(state, form) {
      state.filtersForm = form;
    },
  },
};

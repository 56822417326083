<template>
  <div class="our-groups">
    <!-- @slot Use it to add something inside. -->
    <slot />
  </div>
</template>

<script>
export default {
  name: "OurGroups",
};
</script>

<style scoped lang="postcss">
.our-groups {
  @apply flex flex-col space-y-14;
}
</style>

import i18n from "@/plugins/vue-i18n";

export default {
  namespaced: true,
  state: {
    status: {
      pending: {
        text: i18n.t("priceList.status.pending"),
        color: "blue",
        value: "pending",
      },
      processing: {
        text: i18n.t("priceList.status.processing"),
        color: "yellow",
        value: "processing",
      },
      done: {
        text: i18n.t("priceList.status.done"),
        color: "green",
        value: "done",
      },
      failed: {
        text: i18n.t("priceList.status.failed"),
        color: "red",
        value: "failed",
      },
    },
  },
  getters: {
    getStatus(state) {
      return (status) => {
        return state.status[status];
      };
    },
  },
};

<template>
  <div ref="OurRadioGroup" :class="themeClass" :data-cy="dataCy">
    <h3 v-if="label" class="label-title">{{ label }}</h3>

    <div :class="labelClass" class="radio-group-wrapper">
      <slot />
    </div>
  </div>
</template>

<script>
import { getThemeClass } from "@/services/_bit/UiService";

import OurRadio from "@/components/_bit/InputRadio";

export default {
  name: "OurRadioGroup",

  components: {
    OurRadio,
  },

  props: {
    /**
     * Set radio group label.
     */
    label: {
      type: String,
      default: "",
    },

    /**
     * Set component value.
     */
    value: {
      type: [String, Number],
      default: "",
    },

    /**
     * Set test dataCy for a radio group.
     */
    dataCy: {
      type: String,
      default: "radio",
    },
  },

  computed: {
    labelClass() {
      return {
        "label-margin": this.label,
      };
    },

    themeClass() {
      return getThemeClass();
    },

    selectedItem: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  watch: {
    selectedItem: {
      handler: "onChangeSelectedItem",
      deep: true,
    },
  },

  methods: {
    onChangeSelectedItem() {
      const items = this.$refs.OurRadioGroup.__vue__.$children;

      for (let i = 0; i < items.length; i++) {
        items[i].setDefaultValue();
      }
    },
  },
};
</script>

<style lang="postcss" scoped>
.label {
  &-title {
    @apply text-sm font-normal text-gray-500;
    @apply mb-6;
  }

  &-margin {
    @apply mb-4 ml-4;
  }
}

.radio-group-wrapper {
  @apply flex flex-col;
  @apply space-y-4;
}
</style>

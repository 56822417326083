import gql from "graphql-tag";

export const knowledgeBaseSectionQuery = gql`
  query knowledgeBaseSection($id: ID!) {
    knowledgeBaseSection(id: $id) {
      id
      name
    }
  }
`;

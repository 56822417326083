<template>
  <OurModal v-model="isShownModal" :title="$t('title.fleaMarket.filters')" mobile-footer-reverse>
    <FilterForm v-model="form" />

    <template #footer-left>
      <OurButton :text="$t('button.apply')" @click="onClickApplyButton" />

      <OurButton :text="$t('button.close')" variant="secondary" @click="onClickCloseButton" />
    </template>

    <template #footer-right>
      <OurButton
        class="button"
        :text="$t('button.clear')"
        variant="thirdary"
        @click="onClickCleanButton"
      />
    </template>
  </OurModal>
</template>

<script>
import { mapState, mapMutations } from "vuex";

import FilterForm from "./components/FilterForm";

export default {
  name: "FilterModal",

  components: {
    FilterForm,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      form: {
        lastName: "",
        firstName: "",
        email: "",
        id: "",
        roleId: "",
        status: "",
      },
    };
  },

  computed: {
    ...mapState("employeeUsers", ["filtersForm"]),

    isShownModal: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },

  watch: {
    isShownModal: "onChangeShownModal",
  },

  beforeDestroy() {
    this.cleanForm();
    this.setFiltersForm();
  },

  methods: {
    ...mapMutations("employeeUsers", ["SET_FILTERS_FORM"]),

    onChangeShownModal() {
      if (this.isShownModal) {
        if (this.filtersForm) {
          this.getForm();
        }
      } else {
        this.cleanForm();
      }
    },

    getForm() {
      this.form = { ...this.filtersForm };
    },

    cleanForm() {
      this.form = {
        lastName: "",
        firstName: "",
        email: "",
        id: "",
        roleId: "",
        status: "",
      };
    },

    closeModal() {
      this.isShownModal = false;
      this.cleanForm();
    },

    onClickCloseButton() {
      this.closeModal();
    },

    onClickApplyButton() {
      this.setFiltersForm();
      this.closeModal();
    },

    onClickCleanButton() {
      this.cleanForm();
    },

    setFiltersForm() {
      const form = { ...this.form };

      this.SET_FILTERS_FORM(form);
    },
  },
};
</script>

import gql from "graphql-tag";

export const managersQuery = gql`
  query oneCEmployees {
    oneCEmployees {
      id
      name
    }
  }
`;

export const allCompaniesQuery = gql`
  query allCompanies {
    allCompanies {
      id
      name
    }
  }
`;

export const companiesQuery = gql`
  query companies($filters: Filter, $page: Int, $perPage: Int, $trashed: Trashed) {
    companies(
      orderBy: { column: "createdAt", direction: desc }
      filters: $filters
      page: $page
      first: $perPage
      trashed: $trashed
    ) {
      paginatorInfo {
        currentPage
        perPage
        total
        lastPage
      }
      data {
        id
        name
        isActive
        taxNumber
        oneCEmployee {
          name
          oneCId
        }
        customer {
          name
        }
      }
    }
  }
`;

export const allCustomersQuery = gql`
  query allCustomer($filters: Filter) {
    allCustomer(filters: $filters) {
      id
      name
    }
  }
`;

<template>
  <OurCard class="order-card mb-4" border-radius="sm">
    <div class="order-card-wrapper" @click="onClick">
      <div v-if="status" class="flex w-full items-center justify-between">
        <OurTag class="status" size="md" :text="status.text" :color="status.color" />
        <div class="text-sm text-gray-500">{{ `#${id}` }}</div>
      </div>
      <OurTextField :label="$t('page.users.user')">
        <div class="flex flex-col">
          <span>{{ user.primaryRow }}</span>
          <span class="text-xs text-gray-500">{{ visit }}</span>
        </div>
      </OurTextField>
      <OurTextField :label="$t('page.users.role')" :value="user.secondaryRow" />
      <OurTextField :label="$t('page.users.contacts')">
        <div class="flex flex-col">
          <span v-if="contacts?.phone">{{ contacts?.phone }}</span>
          <span v-if="contacts?.email" class="underline">{{ contacts?.email }}</span>
        </div>
      </OurTextField>
      <OurTextField v-if="customer" :label="$t('page.users.customer')" :value="customer" />
    </div>
  </OurCard>
</template>

<script>
export default {
  name: "UsersCard",
  props: {
    status: {
      type: Object,
      default: () => {},
    },
    user: {
      type: Object,
      default: () => {},
    },
    contacts: {
      type: Object,
      default: () => {},
    },
    customer: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    visit: {
      type: String,
      default: "",
    },
  },

  methods: {
    onClick() {
      this.$emit("clickOnOrder");
    },
  },
};
</script>

<style scoped lang="postcss">
.order-card {
  .order-card-wrapper:deep {
    @apply flex flex-col items-start space-y-4;

    .status {
      @apply w-auto;
    }
  }
}
</style>

export default {
  namespaced: true,
  state: {
    filtersForm: {
      date: {
        from: "",
        to: "",
      },
      contract: "",
      order: "",
      type: "",
    },
  },

  mutations: {
    SET_FILTERS_FORM_DATE(state, date) {
      state.filtersForm.date = date;
    },

    SET_FILTERS_FORM_CONTRACT(state, contract) {
      state.filtersForm.contract = contract;
    },

    SET_FILTERS_FORM_ORDER(state, order) {
      state.filtersForm.order = order;
    },

    SET_FILTERS_FORM_TYPE(state, type) {
      state.filtersForm.type = type;
    },
  },
};

import gql from "graphql-tag";

export const noticesQuery = gql`
  query notices($filters: Filter, $own: Boolean, $page: Int, $perPage: Int, $trashed: Trashed) {
    notices(
      orderBy: { column: "updatedAt", direction: desc }
      filters: $filters
      own: $own
      page: $page
      first: $perPage
      trashed: $trashed
    ) {
      paginatorInfo {
        currentPage
        perPage
        total
        lastPage
      }
      data {
        contactName
        id
        name
        description
        category {
          description
        }
        price
        quantity
        location
        currency {
          symbol
          code
        }
        isActive
        isNew
        moderationStatus
        files {
          link
        }
      }
    }
    productCategories {
      id
      description
      parent
    }
  }
`;

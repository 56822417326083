import i18n from "@/plugins/vue-i18n";

export default {
  namespaced: true,
  state: {
    status: {
      notActive: {
        text: i18n.t("fleaMarket.status.notActive"),
        color: "red",
      },
      active: {
        text: i18n.t("fleaMarket.status.active"),
        color: "green",
      },
    },
    moderationStatus: {
      inModeration: {
        text: i18n.t("fleaMarket.moderationStatus.inModeration"),
        color: "blue",
      },
      confirmed: {
        text: i18n.t("fleaMarket.moderationStatus.confirmed"),
        color: "green",
      },
      rejected: {
        text: i18n.t("fleaMarket.moderationStatus.rejected"),
        color: "orange",
      },
    },
    categories: [],
    filtersForm: {
      searchAll: "",
      searchOwn: "",
      categoryAll: {},
      categoryOwn: {},
    },
  },

  getters: {
    getStatus(state) {
      return (isActive) => {
        if (isActive) {
          return state.status.active;
        } else {
          return state.status.notActive;
        }
      };
    },
    getModerationStatus(state) {
      return (moderationStatus) => {
        if (moderationStatus === "inModeration") {
          return state.moderationStatus.inModeration;
        } else if (moderationStatus === "confirmed") {
          return state.moderationStatus.confirmed;
        } else {
          return state.moderationStatus.rejected;
        }
      };
    },

    getFiltersOptions(state) {
      const { searchAll, searchOwn, categoryOwn, categoryAll } = state.filtersForm;

      return (isOwn) => {
        let categoryId, name;
        let filtersOptions = [];

        if (isOwn) {
          categoryId = categoryOwn.id;
          name = searchOwn;
        } else {
          categoryId = categoryAll.id;
          name = searchAll;
        }

        if (categoryId) {
          filtersOptions.push({
            method: "where",
            column: "categoryId",
            operator: categoryId !== "null" ? "" : categoryId,
            value: categoryId || "",
          });
        }

        if (name) {
          filtersOptions.push({
            method: "where",
            column: "name",
            operator: "like",
            value: name,
          });
        }

        return filtersOptions;
      };
    },
  },

  mutations: {
    // TODO: use when filters will be
    // SET_FILTERS_AND_FORM(state, data) {
    //   const { filtersOptions, form } = data;
    //
    //   state.filtersOptions = filtersOptions;
    //   state.filtersForm = form;
    // },

    SET_CATEGORIES(state, categories) {
      state.categories = categories
        .filter(({ parent }) => parent === null)
        .map((category) => {
          const { id, description: label } = category;

          return {
            id,
            label,
          };
        });
    },

    SET_SEARCH(state, data) {
      const { isOwn, search } = data;

      if (isOwn) state.filtersForm.searchOwn = search;
      else state.filtersForm.searchAll = search;
    },

    SET_CATEGORY(state, data) {
      const { isOwn, category } = data;

      if (isOwn) state.filtersForm.categoryOwn = category;
      else state.filtersForm.categoryAll = category;
    },
  },
};

import gql from "graphql-tag";

export const updateArticleMutation = gql`
  mutation updateArticle(
    $id: ID!
    $sectionId: ID!
    $name: String!
    $type: ArticleType!
    $content: String
    $files: [ID!]
    $contentImages: [ID!]
  ) {
    updateArticle(
      id: $id
      sectionId: $sectionId
      name: $name
      type: $type
      content: $content
      files: $files
      contentImages: $contentImages
    ) {
      id
    }
  }
`;

<template>
  <div :data-cy="dataCy">
    <OurAccordionItem
      v-for="(item, index) in items"
      :key="item.name"
      :ref="`item-${item.name}`"
      :title="item.title"
      :description="item.description"
      :name="item.name"
      :size="size"
      :data-cy="`${dataCy}-item-${index}`"
      @itemClicked="onItemClicked"
    />
  </div>
</template>

<script>
import { getRandomId } from "@/services/_bit/UiService";

import OurCard from "@/components/_bit/ContainerCard";
import OurAccordionItem from "@/components/_bit/AccordionItem";

export default {
  name: "OurAccordion",
  components: {
    OurCard,
    OurAccordionItem,
  },

  props: {
    /**
     * Set data for accordion item.
     */
    items: {
      type: Array,
      default: () => [],
    },

    /**
     * The size of the accordion item.
     * @values sm, md, lg
     */
    size: {
      type: String,
      default: "md",
    },

    /**
     * Sets data-cy attribute for correct element sampling in tests.
     */
    dataCy: {
      type: String,
      default: "",
    },
  },

  created() {
    this.items.forEach((item) => (item.name = getRandomId()));
  },

  methods: {
    onItemClicked(node) {
      for (let key in this.$refs) {
        const [component] = this.$refs[key];

        component?.closeDescription(node);
      }
    },
  },
};
</script>

<template>
  <div class="our-group" :class="formGroupClasses" :data-cy="dataCy">
    <div v-if="title" class="our-group-header-wrapper">
      <OurDivider v-if="isUpperlined" class="our-group-upperlined" size="xl" no-top-padding />

      <div class="our-group-header" :class="headerClass">
        <OurHeader v-if="title" class="our-group-block-title" :text="title" size="xs" />

        <!-- @slot Use it to add something right side of the header. -->
        <slot name="right" />
      </div>

      <OurDivider v-if="isUnderlined" class="our-group-underlined" size="xl" no-top-padding />
    </div>

    <div class="our-group-content">
      <!-- @slot Use it to add something instead form. -->
      <slot />
    </div>
  </div>
</template>

<script>
import { getThemeClass, componentConfig } from "@/services/_bit/UiService";

import OurDivider from "@/components/_bit/Divider";
import OurHeader from "@/components/_bit/TextHeader";

export default {
  name: "OurGroup",

  components: {
    OurDivider,
    OurHeader,
  },

  props: {
    /**
     * Set header title.
     */
    title: {
      type: String,
      default: "",
    },

    /**
     * The size of the button.
     * @values sm, md, lg
     */
    size: {
      type: String,
      default: "md",
    },

    /**
     * Show line above the header.
     */
    upperlined: {
      type: Boolean,
      default: undefined,
    },

    /**
     * Show line under the header.
     */
    underlined: {
      type: Boolean,
      default: undefined,
    },

    /**
     * Sets data-cy attribute for correct element sampling in tests.
     */
    dataCy: {
      type: String,
      default: "",
    },
  },

  computed: {
    formGroupClasses() {
      const themeClass = getThemeClass();
      const size = `size-${this.size}`;

      return [themeClass, size];
    },

    isUpperlined() {
      const upperlined = componentConfig.ourGroup?.upperlined;

      if (this.upperlined === undefined && upperlined === undefined) return true;
      else if (this.upperlined !== undefined) return this.upperlined;

      return upperlined;
    },

    isUnderlined() {
      if (this.underlined !== undefined) return this.underlined;

      return componentConfig.ourGroup?.underlined;
    },

    headerClass() {
      return !this.isUnderlined ? "padding-bottom" : "";
    },
  },
};
</script>

<style lang="postcss" scoped>
.size {
  &-sm {
    .our-group-content {
      @apply space-y-2;
    }
  }

  &-md {
    .our-group-content {
      @apply space-y-4;
    }
  }

  &-lg {
    .our-group-content {
      @apply space-y-6;
    }
  }
}

.our-group {
  &-header {
    @apply flex items-center justify-between;

    &.padding-bottom {
      @apply pb-6;
    }
  }

  &-title {
    @apply mb-6;
  }

  &-underlined {
    @apply pt-1.5;
  }

  &-content {
    @apply space-y-4;
  }
}
</style>

<template>
  <div class="user-form">
    <OurGroups>
      <OurGroup class="contact-information">
        <OurInput
          v-model="form.lastName"
          :label="$t('label.lastName')"
          :placeholder="$t('placeholder.lastName')"
          :error="lastNameError"
          :disabled="userDeleted"
        />

        <OurInput
          v-model="form.firstName"
          :label="$t('label.name')"
          :placeholder="$t('placeholder.name')"
          :error="firstNameError"
          :disabled="userDeleted"
        />

        <OurInput
          v-model="form.email"
          :label="$t('label.email')"
          :placeholder="$t('placeholder.email')"
          :error="emailError"
          :disabled="userDeleted"
        />

        <OurInput
          v-if="showPassword"
          v-model="form.password"
          :label="$t('label.password._')"
          :placeholder="$t('placeholder.writePassword')"
          :error="passwordError"
          :disabled="userDeleted"
        />

        <OurSelect
          v-model="form.roleId"
          :options="roles"
          :label="$t('label.role')"
          :placeholder="$t('placeholder.selectRole')"
          :error="roleError"
          :disabled="userDeleted"
        />

        <div v-if="!userDeleted" class="footer-block">
          <OurCheckbox v-model="form.isActive" :label="$t('label.userActive')" />
        </div>
      </OurGroup>
    </OurGroups>
  </div>
</template>

<script>
import { email, required, requiredIf, minLength, maxLength } from "vuelidate/lib/validators";
import ValidationService from "@/services/validation.service";

const { PASSWORD_MIN_LENGTH } = ValidationService;
const { PASSWORD_MAX_LENGTH } = ValidationService;
const { TEXT_MAX_LENGTH } = ValidationService;

export default {
  name: "EmployeeForm",
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    userDeleted: {
      type: Boolean,
      default: false,
    },
    roles: {
      type: Array,
      default() {
        return [];
      },
    },
    showPassword: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      form: {
        firstName: "",
        lastName: "",
        email: "",
        roleId: "",
        password: "",
        isActive: false,
      },
    };
  },
  validations: {
    form: {
      firstName: { required, maxLength: maxLength(TEXT_MAX_LENGTH) },
      lastName: { required, maxLength: maxLength(TEXT_MAX_LENGTH) },
      email: { required, email, maxLength: maxLength(TEXT_MAX_LENGTH) },
      roleId: { required },
      password: {
        required: requiredIf(function () {
          if (this.showPassword) {
            return true;
          }
        }),
        minLength: minLength(PASSWORD_MIN_LENGTH),
        maxLength: maxLength(PASSWORD_MAX_LENGTH),
      },
    },
  },
  computed: {
    firstNameError() {
      const isDirty = this.$v.form.firstName.$dirty;
      const isFilled = this.$v.form.firstName.required;
      const maxLength = this.$v.form.firstName.maxLength;

      let error = "";

      if (isDirty && !isFilled) {
        error = this.$t("validation.required");
      } else if (isDirty && !maxLength) {
        error = this.$t("validation.textMaxLength", {
          length: TEXT_MAX_LENGTH,
        });
      }

      return error;
    },

    lastNameError() {
      const isDirty = this.$v.form.lastName.$dirty;
      const isFilled = this.$v.form.lastName.required;
      const maxLength = this.$v.form.lastName.maxLength;

      let error = "";

      if (isDirty && !isFilled) {
        error = this.$t("validation.required");
      } else if (isDirty && !maxLength) {
        error = this.$t("validation.textMaxLength", {
          length: TEXT_MAX_LENGTH,
        });
      }

      return error;
    },

    passwordError() {
      const isDirty = this.$v.form.password.$dirty;
      const isFilled = this.$v.form.password.required;
      const minLength = this.$v.form.password.minLength;
      const maxLength = this.$v.form.password.maxLength;

      let error = "";

      if (isDirty && !isFilled) {
        error = this.$t("validation.required");
      } else if (isDirty && !minLength) {
        error = this.$t("validation.passwordMinLength", {
          length: PASSWORD_MIN_LENGTH,
        });
      } else if (isDirty && !maxLength) {
        error = this.$t("validation.passwordMaxLength", {
          length: PASSWORD_MAX_LENGTH,
        });
      }

      return error;
    },

    emailError() {
      const isDirty = this.$v.form.email.$dirty;
      const isFilled = this.$v.form.email.required;
      const isEmail = this.$v.form.email.email;
      const maxLength = this.$v.form.email.maxLength;

      let error = "";

      if (isDirty && !isFilled) {
        error = this.$t("validation.required");
      } else if (isDirty && !isEmail) {
        error = this.$t("validation.mustBeEmail");
      } else if (isDirty && !maxLength) {
        error = this.$t("validation.textMaxLength", {
          length: TEXT_MAX_LENGTH,
        });
      }

      return error;
    },

    roleError() {
      const isDirty = this.$v.form.roleId.$dirty;
      const isFilled = this.$v.form.roleId.required;

      let error = "";

      if (isDirty && !isFilled) {
        error = this.$t("validation.required");
      }

      return error;
    },
  },
  watch: {
    value: {
      handler: "getForm",
      deep: true,
    },
  },
  methods: {
    getForm() {
      if (this.value) {
        this.form = { ...this.value };
      }
    },
    emitForm() {
      if (this.$v.$invalid) {
        this.$v.$touch();

        return;
      }

      this.form.isValid = true;
      this.$emit("input", this.form);
    },
  },
};
</script>

<template>
  <OurCard class="order-card mb-4" border-radius="sm">
    <div class="order-card-wrapper" @click="onClick">
      <div v-if="status" class="flex w-full items-center justify-between">
        <OurTag class="status" size="md" :text="status.text" :color="status.color" />
        <div class="text-sm text-gray-500">{{ `#${id}` }}</div>
      </div>
      <OurTextField v-if="company" :label="$t('page.dealersCompany.company')" :value="company" />
      <OurTextField
        :label="$t('page.dealersCompany.accessGroup')"
        :value="accessGroup ? accessGroup : '-'"
      />
      <div class="flex w-full items-center justify-between">
        <OurTextField class="w-fit" :label="$t('page.dealersCompany.user')">
          <div>
            {{ user }}
          </div>
        </OurTextField>
        <OurTextField :label="$t('page.dealersCompany.taxNumber')">
          <div>
            {{ taxNumber ? taxNumber : "-" }}
          </div>
        </OurTextField>
      </div>
    </div>
  </OurCard>
</template>

<script>
export default {
  name: "UsersCard",
  props: {
    status: {
      type: Object,
      default: () => {},
    },
    user: {
      type: String,
      default: "",
    },
    company: {
      type: String,
      default: "",
    },
    accessGroup: {
      type: String,
      default: "",
    },
    taxNumber: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
  },

  methods: {
    onClick() {
      this.$emit("clickOnOrder");
    },
  },
};
</script>

<style scoped lang="postcss">
.order-card {
  .order-card-wrapper:deep {
    @apply flex flex-col items-start space-y-4;

    .status {
      @apply w-auto;
    }
  }
}
</style>

<template>
  <OurConfirmModal
    v-model="isShownModal"
    :title="$t('title.article.delete')"
    :action-button-text="$t('button.remove')"
    color="gray"
    @actionConfirmed="onClickDeleteButton"
  >
    <OurTextBlock v-html="$t('page.article.removeConfirm', { name: articleName })" />
  </OurConfirmModal>
</template>

<script>
import apolloMixin from "@/mixins/apollo.mixin";
import { deleteArticleMutation } from "./gql/mutations";

export default {
  name: "DeleteModal",

  mixins: [apolloMixin],

  props: {
    show: {
      type: Boolean,
      default: false,
    },

    articleId: {
      type: String,
      default: "",
    },

    articleName: {
      type: String,
      default: "",
    },

    delayNotify: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isShownModal: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },

  methods: {
    closeModal() {
      this.isShownModal = false;
    },

    onClickCloseButton() {
      this.closeModal();
    },

    async onClickDeleteButton() {
      const { deleteArticle } = await this.$post(
        deleteArticleMutation,
        { id: this.articleId },
        { delaySuccessNotify: this.delayNotify }
      );

      if (deleteArticle.id) this.$emit("deleteArticle");
    },
  },
};
</script>

<template>
  <OurPage
    class="users-add"
    :title="$t('title.dealersCompany.add')"
    :back-route="backRoute"
    width="md"
  >
    <CompanyForm
      ref="companyForm"
      v-model="form"
      class="user-form"
      :employees="managers"
      @openModal="show = !show"
    />

    <template #footer-left>
      <OurButton class="button" :text="$t('button.add')" @click="onClickSaveButton" />
      <OurLink :route="backRoute">
        <OurButton class="button" :text="$t('button.back')" variant="secondary" />
      </OurLink>
    </template>
  </OurPage>
</template>

<script>
import apolloMixin from "@/mixins/apollo.mixin";
import { employeesQuery } from "./gql/queries";
import { createDealerCompany } from "./gql/mutations";
import CompanyForm from "@/views/settings/dealer-company/_components/CompanyForm.vue";
import { mapMutations, mapState } from "vuex";

export default {
  name: "DealerCompanyAdd",

  components: {
    CompanyForm,
  },

  mixins: [apolloMixin],

  data() {
    return {
      backRoute: {
        name: "DealerCompany",
        title: this.$t("title.dealersCompany._"),
      },
      form: {
        title: "",
        managerId: "",
        isActive: false,
      },
      show: false,
    };
  },

  computed: {
    ...mapState("dealerCompany", ["managers"]),
  },

  created() {
    this.getData();
  },

  methods: {
    ...mapMutations("dealerCompany", ["SET_MANAGERS"]),

    async getData() {
      const { employees } = await this.$get(employeesQuery);

      this.SET_MANAGERS(employees);
    },

    async onClickSaveButton() {
      this.$refs.companyForm.emitForm();

      if (this.form.isValid) {
        const form = { ...this.form };

        const { createCompany } = await this.$post(createDealerCompany, form, {
          delaySuccessNotify: true,
        });

        if (createCompany.id) this.$router.push({ name: "DealerCompany" });
      }
    },
  },
};
</script>

<style lang="postcss" scoped>
.users-add {
  @apply relative;

  .user-form {
    @apply mb-6;
  }
}
</style>

import gql from "graphql-tag";
export const accessGroupsQuery = gql`
  query accessGroups {
    accessGroups {
      id
      name
    }
  }
`;
export const employeesQuery = gql`
  query employees {
    employees {
      data {
        id
        firstName
        lastName
        roles {
          code
        }
      }
    }
  }
`;

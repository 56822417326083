<template>
  <div
    ref="OurButtonGroupItem"
    :class="radioButtonsClasses"
    class="our-button-group-item"
    :data-cy="dataCy"
    @click="onClickSetValue"
  >
    <t-radio :id="value" v-model="selectedItem" class="radio" :name="name" :value="value" />

    <label :for="value" class="label">
      <span v-if="isShownSlot.left" class="label-slot">
        <!-- @slot Use it to add some content before label. -->
        <slot name="left" />
      </span>

      <span v-if="label || isShownSlot.default" class="label-text label-slot">
        {{ label }}
        <!-- @slot Use it to add some content instead of label. -->
        <slot />
      </span>

      <span v-if="isShownSlot.right" class="label-slot">
        <!-- @slot Use it to some content after label. -->
        <slot name="right" />
      </span>
    </label>
  </div>
</template>

<script>
import { getThemeClass } from "@/services/_bit/UiService";

import TRadio from "vue-tailwind/dist/t-radio";

export default {
  name: "ButtonGroupItem",

  components: { TRadio },

  props: {
    /**
     * Set data for button.
     */
    value: {
      type: [String, Number],
      default: "",
    },

    /**
     * Set label.
     */
    label: {
      type: String,
      default: "",
    },

    /**
     * Sets data-cy attribute for correct element sampling in tests.
     */
    dataCy: {
      type: String,
      default: "",
    },
  },

  data: () => ({
    selectedItem: "",
    name: "",
    size: "md",
  }),

  computed: {
    radioButtonsClasses() {
      const size = `size-${this.size}`;
      const themeClass = getThemeClass();

      return [themeClass, size];
    },

    isShownSlot() {
      return {
        default: !!this.$slots["default"],
        left: !!this.$slots["left"],
        right: !!this.$slots["right"],
      };
    },

    buttonGroup() {
      let buttonGroup = this.$refs.OurButtonGroupItem.__vue__.$parent;

      if (!buttonGroup.wide) {
        buttonGroup = buttonGroup.$parent.$parent;
      }

      return buttonGroup;
    },
  },

  mounted() {
    this.name = this.buttonGroup.name;
    this.size = this.buttonGroup.size;

    this.setDefaultValue();
  },

  methods: {
    setValue() {
      this.selectedItem = this.value;
      this.buttonGroup.selectedItem = this.selectedItem;
    },

    setDefaultValue() {
      if (this.buttonGroup.selectedItem !== this.value) {
        return;
      }

      this.setValue();
    },

    onClickSetValue() {
      this.setValue();
    },
  },
};
</script>

<style lang="postcss" scoped>
.our-button-group-item {
  @apply rounded-none;

  .radio {
    @apply absolute -z-10 opacity-0;

    &:focus {
      & + .label {
        @apply border-gray-100 bg-opacity-10;
        @apply ring-4 ring-gray-600/15;
      }
    }

    &:checked {
      & + .label {
        @apply bg-gray-900/15;
      }
    }
  }

  .label {
    @apply flex items-center justify-between;
    @apply bg-gray-900/5;
    @apply cursor-pointer;

    &:hover {
      @apply bg-gray-900/10;
    }

    &:active {
      @apply bg-gray-900/15;
    }

    &-text {
      @apply font-medium;
    }

    &-slot:deep(.svg-icon) {
      @apply block;
    }
  }

  &.size {
    &-sm {
      .label {
        @apply space-x-1.5 p-3;

        &-text {
          @apply text-sm;
        }
      }
    }

    &-md {
      .label {
        @apply space-x-2 p-4;

        &-text {
          @apply text-base;
        }
      }
    }

    &-lg {
      .label {
        @apply space-x-2.5 p-5;

        &-text {
          @apply text-lg;
        }
      }
    }
  }
}
</style>

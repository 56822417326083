import { brandName } from "@/configs/ourApp.config";

export default {
  namespaced: true,
  state: {
    settings: null,
  },

  getters: {
    brandName(state) {
      return state.settings?.companyName || brandName;
    },
  },
};

<template>
  <OurPage class="access-groups" :title="$t('title.knowledgeBase.chapters')" width="md">
    <template #header-right>
      <OurLink v-if="!isUserDealer && getPermission['knowledge_base.create']" :route="addRoute">
        <OurButton :text="$t('button.add')" />
      </OurLink>
    </template>

    <DeleteModal
      :show.sync="isShownDeleteModal"
      :knowledge-base-sections-id="knowledgeBaseSectionsId"
      :knowledge-base-sections-name="knowledgeBaseSectionsName"
      @deleteKnowledgeBaseSection="getData"
    />

    <OurTableList
      :have-permission-delete="getPermission['knowledge_base.delete']"
      :have-permission-edit="getPermission['knowledge_base.update']"
      :list="knowledgeBaseSections"
      @clickEdit="onClickEdit"
      @clickDelete="onClickDelete"
      @clickChapter="onClickChapter"
      @dragSort="dragHandler"
    />
  </OurPage>
</template>

<script>
import { mapGetters } from "vuex";
import apolloMixin from "@/mixins/apollo.mixin";
import { knowledgeBaseSectionsQuery } from "./gql/queries";
import DeleteModal from "@/views/settings/base-knowledge/delete/Modal.vue";
import { reorderKnowledgeBaseSectionsQuery } from "@/views/settings/base-knowledge/index/gql/mutations";

export default {
  name: "BaseKnowledge",
  components: { DeleteModal },

  mixins: [apolloMixin],

  data() {
    return {
      knowledgeBaseSections: [],
      isShownDeleteModal: false,
      knowledgeBaseSectionsId: "",
      knowledgeBaseSectionsName: "",
    };
  },

  computed: {
    ...mapGetters("user", ["isUserDealer", "getPermission"]),

    addRoute: () => ({ name: "BaseKnowledgeAdd" }),
  },

  created() {
    this.getData();
  },

  methods: {
    async getData() {
      const { knowledgeBaseSections } = await this.$get(knowledgeBaseSectionsQuery);

      if (knowledgeBaseSections) {
        this.knowledgeBaseSections = knowledgeBaseSections.map((knowledgeBaseSection) => {
          const { id, name } = knowledgeBaseSection;

          return {
            id,
            name,
          };
        });
      }
    },

    onClickEdit(id) {
      this.$router.push({ name: "BaseKnowledgeEdit", params: { id } });
    },

    onClickChapter(id) {
      this.$router.push({ name: "Chapter", params: { id } });
    },

    onClickDelete(id) {
      this.isShownDeleteModal = true;
      this.knowledgeBaseSectionsId = id;
      this.knowledgeBaseSectionsName = this.knowledgeBaseSections.find(
        (group) => group.id === id
      ).name;
    },

    async dragHandler(data) {
      const order = data.map((object) => {
        return object.id;
      });

      await this.$post(
        reorderKnowledgeBaseSectionsQuery,
        { order: order },
        {
          delaySuccessNotify: false,
        }
      );
    },
  },
};
</script>

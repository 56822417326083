import i18n from "@/plugins/vue-i18n";

export default {
  namespaced: true,
  state: {
    status: {
      activated: {
        text: i18n.t("dealerUsers.status.activated"),
        color: "green",
        value: "activated",
      },
      deactivated: {
        text: i18n.t("dealerUsers.status.deactivated"),
        color: "gray",
        value: "deactivated",
      },
    },
    filtersForm: null,
    accessGroups: [],
    managers: [],
    companies: [],
  },

  getters: {
    getStatuses(state) {
      return Object.values(state.status);
    },

    getStatus(state) {
      return (status) => {
        return state.status[status];
      };
    },

    getFiltersOption() {
      return (column, value) => {
        const option = {
          method: "where",
          column,
          value,
        };

        if (column === "status") {
          option.column = "companies.isActive";
          option.value = value !== "deactivated";
        }

        return option;
      };
    },

    getFiltersOptions(state, getters) {
      return () => {
        const filtersForm = state.filtersForm;
        let filtersOptions = [];

        if (filtersForm) {
          Object.keys(filtersForm).forEach((key) => {
            if (filtersForm[key]) {
              const column = key;
              const value = filtersForm[key];

              filtersOptions.push(getters.getFiltersOption(column, value));
            }
          });
        }

        return filtersOptions;
      };
    },
  },

  mutations: {
    SET_ACCESS_GROUPS(state, accessGroups) {
      if (accessGroups) {
        state.accessGroups = accessGroups.map((accessGroup) => {
          const { id, name: label } = accessGroup;

          return { id, label };
        });
      }
    },

    SET_MANAGERS(state, managers) {
      state.managers = managers.map((manager) => {
        const { id, name: label } = manager;

        return { id, label };
      });
    },

    SET_COMPANIES(state, companies) {
      state.companies = companies.map((company) => {
        const { id, name: label } = company;

        return { id, label };
      });
    },

    SET_FILTERS_FORM(state, form) {
      state.filtersForm = form;
    },
  },
};

import i18n from "@/plugins/vue-i18n";

import NewNotices from "@/views/settings/new-notices/index";
import NewNoticesDetails from "../../../../views/settings/new-notices/details";
import NewNoticesEdit from "../../../../views/settings/new-notices/edit";

const newNotices = [
  {
    path: "settings/new-notices",
    name: "NewNotices",
    component: NewNotices,
    meta: {
      title: i18n.t("title.newNotices._"),
    },
  },
  {
    path: "settings/new-notices/:id/details",
    name: "NewNoticesDetails",
    component: NewNoticesDetails,
    meta: {
      title: i18n.t("title.newNotices.details"),
    },
  },
  {
    path: "settings/new-notices/:id/edit",
    name: "NewNoticesEdit",
    component: NewNoticesEdit,
    meta: {
      title: i18n.t("title.newNotices.edit"),
    },
  },
];

export default newNotices;

export default {
  namespaced: true,
  state: {
    storages: [],
  },
  mutations: {
    SET_STORAGES(state, storages) {
      state.storages = storages;
    },
  },
};

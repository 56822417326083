<template>
  <div class="access-groups-form">
    <OurGroup>
      <OurInput
        v-model="form.name"
        :label="$t('page.catalog.name')"
        :placeholder="$t('placeholder.articleTitle')"
        :error="nameError"
      />
      <OurSelect
        v-model="form.type"
        :options="types"
        :label="$t('label.typeIs')"
        :placeholder="$t('placeholder.selectArticleCategory')"
        :error="typeError"
      />
      <OurInput
        v-if="form.type === 'link'"
        v-model="form.content"
        :label="$t('label.link')"
        :placeholder="$t('placeholder.enterLink')"
        :error="linkError"
      />
      <OurFileInput
        v-if="form.type === 'article'"
        :label="$t('label.document')"
        :files="files"
        button-size="xl"
        multiple
        :max-files="maxFiles"
        @changeFiles="onChangeFiles"
        @deleteFile="deleteFile"
      />
      <vue-editor
        v-if="form.type === 'article'"
        v-model="form.content"
        class="custom-vue__editor"
        :placeholder="'Введіть текст статті'"
        :editor-toolbar="customToolbar"
        use-custom-image-handler
        @image-added="handleImageAdded"
      />
    </OurGroup>
  </div>
</template>

<script>
import { required, maxLength, requiredIf } from "vuelidate/lib/validators";
import ValidationService from "@/services/validation.service";
import { uploadFilesMutation } from "@/views/settings/base-knowledge/chapter/add/gql/mutations";
import apolloMixin from "@/mixins/apollo.mixin";

const { TEXT_MAX_LENGTH } = ValidationService;

export default {
  name: "ArticleForm",
  mixins: [apolloMixin],
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    uploadFiles: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      maxFiles: 10,
      files: [],
      form: {
        name: "",
        content: "",
        type: "",
        files: [],
      },
      contentImages: [],
      types: [
        {
          id: "article",
          label: "Cтаття",
        },
        {
          id: "link",
          label: "Посилання",
        },
      ],
      customToolbar: [
        [
          {
            header: [false, 1, 2, 3, 4, 5, 6],
          },
        ],
        ["bold", "italic", "underline", "strike"],
        [
          {
            align: "",
          },
          {
            align: "center",
          },
          {
            align: "right",
          },
          {
            align: "justify",
          },
        ],
        ["image", "blockquote", "code-block"],
        [
          {
            list: "ordered",
          },
          {
            list: "bullet",
          },
        ],
        ["link", "video"],
      ],
    };
  },

  validations: {
    form: {
      name: { required, maxLength: maxLength(TEXT_MAX_LENGTH) },
      type: { required },
      content: {
        required: requiredIf(function () {
          if (this.form.type === "link") {
            return true;
          }
        }),
      },
    },
  },

  computed: {
    nameError() {
      const isDirty = this.$v.form.name.$dirty;
      const isFilled = this.$v.form.name.required;

      const maxLength = this.$v.form.name.maxLength;

      let error = "";

      if (isDirty && !isFilled) {
        error = this.$t("validation.required");
      } else if (isDirty && !maxLength) {
        error = this.$t("validation.textMaxLength", {
          length: TEXT_MAX_LENGTH,
        });
      }

      return error;
    },
    typeError() {
      const isDirty = this.$v.form.type.$dirty;
      const isFilled = this.$v.form.type.required;

      let error = "";

      if (isDirty && !isFilled) {
        error = this.$t("validation.required");
      }

      return error;
    },
    linkError() {
      const isDirty = this.$v.form.content.$dirty;
      const isFilled = this.$v.form.content.required;

      let error = "";

      if (isDirty && !isFilled) {
        error = this.$t("validation.required");
      }

      return error;
    },
  },

  watch: {
    value: {
      handler: "getForm",
      deep: true,
    },
    uploadFiles: {
      handler: "getUploadFiles",
      deep: true,
    },
    files: {
      handler: "getFormFiles",
      deep: true,
    },
  },

  methods: {
    getForm() {
      if (this.value) {
        this.form = { ...this.value };
      }

      this.$nextTick(() => {
        if (this.form.files.length) {
          this.files = this.form.files.map((file) => {
            const { id, name, link: imageUrl } = file;

            return { id, name, imageUrl };
          });
        }
      });
    },

    async handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      const { uploadFiles: files } = await this.$post(
        uploadFilesMutation,
        { files: file },
        { withNotify: true }
      );

      this.contentImages.push(files[0].id);

      if (files) {
        const url = files[0].link;

        Editor.insertEmbed(cursorLocation, "image", url);
        resetUploader();
      }
    },

    getFormFiles() {
      this.form.files = this.files.map((file) => file.id);
    },

    async onChangeFiles(selectedFiles) {
      if (selectedFiles.length) {
        await this.$emit("uploadFiles", selectedFiles);
      }
    },

    getUploadFiles() {
      const files = this.uploadFiles;

      files.forEach((file) => {
        const { id, name, link: url } = file;

        this.files.push({ id, name, url });
      });
    },

    async deleteFile(id) {
      await this.$emit("deleteFiles", id);

      this.files.forEach((file) => {
        if (file.id !== id) return file;
      });
    },

    emitForm() {
      if (this.$v.$invalid) {
        this.$v.$touch();

        return;
      }

      this.form.isValid = true;
      this.form.contentImages = [...this.contentImages];
      this.$emit("input", this.form);
    },
  },
};
</script>

<style lang="postcss">
.access-groups-form {
  .ql-container {
    img {
      @apply inline-block;
    }
  }
}

.custom-vue__editor {
  .ql-tooltip {
    @apply left-0 !important;
  }
}
</style>

<template>
  <OurPage
    :back-route="backRoute"
    class="access-groups"
    :title="$t('title.knowledgeBase.editChapter')"
    width="md"
  >
    <DeleteModal
      :show.sync="isShownDeleteModal"
      :article-id="$route.params.articleId"
      :article-name="form.name"
      @deleteArticle="goToList"
    />

    <ArticleForm
      ref="articleForm"
      v-model="form"
      :upload-files="uploadFiles"
      @uploadFiles="uploadFilesMutation"
      @deleteFiles="deleteFileMutation"
    />

    <template #footer-left>
      <OurButton class="button" :text="$t('button.save')" @click="onClickSaveButton" />
      <OurLink :route="backRoute">
        <OurButton class="button" :text="$t('button.back')" variant="secondary" />
      </OurLink>
    </template>

    <template #footer-right>
      <OurButton
        v-if="getPermission['knowledge_base.delete']"
        class="button"
        variant="thirdary"
        :text="$t('button.remove')"
        @click="onClickDeleteButton"
      />
    </template>
  </OurPage>
</template>

<script>
import ArticleForm from "@/views/settings/base-knowledge/chapter/_components/ArticleForm.vue";
import DeleteModal from "@/views/settings/base-knowledge/chapter/delete/Modal.vue";
import apolloMixin from "@/mixins/apollo.mixin";
import { articleQuery } from "@/views/settings/base-knowledge/chapter/edit/gql/queries";
import {
  deleteFileMutation,
  uploadFilesMutation,
} from "@/views/settings/base-knowledge/chapter/add/gql/mutations";
import { updateArticleMutation } from "@/views/settings/base-knowledge/chapter/edit/gql/mutations";
import { mapGetters } from "vuex";

export default {
  name: "ChapterEdit",
  components: { DeleteModal, ArticleForm },
  mixins: [apolloMixin],
  data() {
    return {
      isShownDeleteModal: false,
      backRoute: {
        name: "Chapter",
        title: this.$t("title.knowledgeBase.chapter"),
      },
      form: {
        name: "",
        type: "",
        content: "",
        files: [],
      },
      uploadFiles: [],
    };
  },
  computed: {
    ...mapGetters("user", ["getPermission"]),
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      const { article } = await this.$get(articleQuery, {
        id: this.$route.params.articleId,
      });

      this.form = {
        ...article,
      };
    },

    async uploadFilesMutation(selectedFiles) {
      const { uploadFiles: files } = await this.$post(
        uploadFilesMutation,
        { files: selectedFiles },
        { withLoader: false, withNotify: false }
      );

      this.uploadFiles = files;
    },

    async deleteFileMutation(id) {
      await this.$post(deleteFileMutation, { id }, { withLoader: false, withNotify: false });
    },

    async onClickSaveButton() {
      this.$refs.articleForm.emitForm();

      if (this.form.isValid) {
        const { updateArticle } = await this.$post(
          updateArticleMutation,
          { id: this.$route.params.articleId, sectionId: this.$route.params.id, ...this.form },
          {
            delaySuccessNotify: true,
          }
        );

        if (updateArticle.id) await this.$router.push({ name: "Chapter" });
      }
    },
    onClickDeleteButton() {
      this.isShownDeleteModal = true;
    },
    goToList() {
      this.$router.push({ name: "Chapter" });
    },
  },
};
</script>

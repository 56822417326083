import gql from "graphql-tag";

export const createDealerMutation = gql`
  mutation createDealer(
    $firstName: String!
    $lastName: String!
    $email: String!
    $phone: String!
    $roleId: ID
    $customerId: ID
    $comment: String
  ) {
    createDealer(
      firstName: $firstName
      lastName: $lastName
      email: $email
      phone: $phone
      roleId: $roleId
      customerId: $customerId
      comment: $comment
    ) {
      id
    }
  }
`;

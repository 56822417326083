<template>
  <div class="form">
    <OurGroups>
      <OurGroup class="form-group" :title="$t('title.users.contact')">
        <div v-for="(email, index) in form.emails" :key="email.id" class="flex items-start">
          <OurInput
            v-model="email.email"
            :label="$t('label.email')"
            :placeholder="$t('placeholder.email')"
            :error="emailError(index)"
            @focus="letsEditEmail(email.id)"
            @blur="closeEdit"
          />
          <div v-if="email.id" class="flex">
            <OurButton
              v-if="getPermission['notice.notifications']"
              class="settings-btn ms-3"
              :text="$t('button.edit')"
              variant="secondary"
              @click="updateEmail(email.id, email.email)"
            >
              <OurSvgIcon :src="icons.Edit" size="sm" />
            </OurButton>
            <OurButton
              v-if="getPermission['notice.notifications']"
              class="settings-btn ms-3"
              :text="$t('button.delete')"
              variant="secondary"
              @click="deleteEmail(email.id)"
            >
              <OurSvgIcon :src="icons.Trash" size="sm" />
            </OurButton>
          </div>
          <OurButton
            v-else
            class="settings-btn ms-4"
            :text="$t('button.save')"
            @click="createEmail(email.email)"
          />
        </div>
        <OurButton
          v-if="getPermission['notice.notifications'] && hasId"
          class="w-full"
          :text="$t('button.addEmail')"
          variant="secondary"
          @click="addEmail"
        />
      </OurGroup>
    </OurGroups>
  </div>
</template>

<script>
import { email, required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";

export default {
  name: "SettingsForm",

  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      form: {
        emails: [],
      },
      edited: null,
    };
  },

  validations: {
    form: {
      emails: {
        $each: {
          email: { required, email },
        },
      },
    },
  },

  computed: {
    ...mapGetters("user", ["getPermission"]),
    icons: () => ({
      Trash: require("@/components/_bit/IconSvg/icons/Trash.svg"),
      Edit: require("@/components/_bit/IconSvg/icons/Edit.svg"),
    }),
    hasId() {
      return this.form.emails.every((email) => email.id);
    },
  },

  watch: {
    value: {
      handler: "setForm",
      deep: true,
      immediate: true,
    },

    form: {
      handler: "onChangeForm",
      deep: true,
    },
  },

  methods: {
    emailError(index) {
      const isDirty = this.$v.form.emails.$each[index].email.$dirty;
      const isFilled = this.$v.form.emails.$each[index].email.required;
      const isEmail = this.$v.form.emails.$each[index].email.email;

      let error = "";

      if (isDirty && !isFilled) {
        error = this.$t("validation.required");
      } else if (isDirty && !isEmail) {
        error = this.$t("validation.mustBeEmail");
      }

      return error;
    },

    setForm() {
      this.form = this.value;
    },

    onChangeForm() {
      this.$emit("input", this.form);
    },

    letsEditEmail(id) {
      this.edited = id;
    },

    closeEdit() {
      this.edited = null;
    },

    createEmail(email) {
      this.$emit("create", email);
    },

    updateEmail(id, email) {
      this.$emit("update", { id, email });
    },

    deleteEmail(id) {
      this.$emit("delete", id);
    },

    addEmail() {
      this.form.emails.push({ email: "" });
    },

    emitForm() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        this.form.isValid = false;

        return;
      }

      this.form.isValid = true;
      this.$emit("input", this.form);
    },
  },
};
</script>

<style scoped>
.settings-btn {
  height: 57px;
}
</style>

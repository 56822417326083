import i18n from "@/plugins/vue-i18n";

const Orders = () => import(/* webpackChunkName: "orders" */ "@/views/orders/index");

const OrdersDetails = () => import(/* webpackChunkName: "orders" */ "@/views/orders/order/details");

const OrdersDraftDetails = () =>
  import(/* webpackChunkName: "orders" */ "@/views/orders/draft/details");

const order = [
  {
    path: "/orders",
    name: "Orders",
    component: Orders,
    meta: {
      title: i18n.t("title.orders._"),
    },
  },
  {
    path: "/orders/:id",
    name: "OrdersDetails",
    component: OrdersDetails,
    meta: {
      title: i18n.t("title.orders._"),
    },
  },
  {
    path: "/orders/draft/:id",
    name: "OrdersDraftDetails",
    component: OrdersDraftDetails,
    meta: {
      title: i18n.t("title.orders._"),
    },
  },
];

export default order;

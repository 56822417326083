<template>
  <OurPage class="access-groups" :title="$t('title.roles._')" width="md">
    <template #header-right>
      <OurLink v-if="!isUserDealer && getPermission['role.create']" :route="addRoute">
        <OurButton :text="$t('button.add')" />
      </OurLink>
    </template>

    <DeleteModal
      :show.sync="isShownDeleteModal"
      :role-id="rolesId"
      :role-name="rolesName"
      @deleteRole="getRoles"
    />

    <OurTabs v-model="selectedTab" class="tabs" size="lg">
      <OurTab
        v-for="item in noticesOptions"
        :key="item.value"
        :label="item.label"
        :value="item.value"
        :disabled="item.disabled"
      />
    </OurTabs>

    <div v-if="isSelectedListEmployee">
      <OurTableList
        :have-permission-delete="getPermission['role.delete']"
        :have-permission-edit="getPermission['role.update']"
        :list="employees"
        @clickEdit="onClickEdit"
        @clickDelete="onClickDelete"
      />
    </div>
    <div v-else>
      <OurTableList
        :have-permission-delete="getPermission['role.delete']"
        :have-permission-edit="getPermission['role.update']"
        :list="dealers"
        @clickEdit="onClickEdit"
        @clickDelete="onClickDelete"
      />
    </div>
  </OurPage>
</template>

<script>
import apolloMixin from "@/mixins/apollo.mixin";
import { rolesQuery } from "@/views/settings/dealer-users/index/gql/queries";
import { mapGetters } from "vuex";
import DeleteModal from "@/views/settings/roles/delete/Modal.vue";

export default {
  name: "Roles",
  components: { DeleteModal },
  mixins: [apolloMixin],
  data() {
    return {
      isShownDeleteModal: false,
      dealers: [],
      employees: [],
      roles: [],
      rolesId: "",
      rolesName: "",
      selectedTab: "employee",
      noticesOptions: [
        { value: "employee", label: this.$t("title.roles.employees") },
        { value: "dealer", label: this.$t("title.roles.dealers") },
      ],
    };
  },
  computed: {
    ...mapGetters("user", ["isUserDealer", "getPermission"]),

    isSelectedListEmployee() {
      return this.selectedTab === "employee";
    },

    addRoute() {
      return { name: "RolesAdd", params: { id: this.selectedTab } };
    },
  },
  created() {
    this.getRoles();
  },
  methods: {
    async getRoles() {
      const { roles } = await this.$get(rolesQuery);

      if (roles) {
        this.dealers = this.filterRoles(roles, "dealer", "dealerUsers");
        this.employees = this.filterRoles(roles, "employee", "employeeUsers");
        this.roles = roles;
      }
    },

    filterRoles(roles, code) {
      return roles
        .filter((role) => role.parent?.code === code)
        .map((role) => {
          const { id, name } = role;

          return {
            id,
            name: name,
          };
        });
    },

    onClickEdit(id) {
      this.$router.push({ name: "RolesEdit", params: { id } });
    },

    onClickDelete(id) {
      this.isShownDeleteModal = true;
      this.rolesId = id;
      this.rolesName = this.roles.find((role) => role.id === id).name;
    },
  },
};
</script>

<template>
  <div class="access-groups-form">
    <OurGroup>
      <OurInput
        v-model="form.name"
        :label="$t('page.catalog.name')"
        :placeholder="$t('placeholder.accessGroupsName')"
        :error="nameError"
      />

      <OurMultiselect
        v-model="form.productCategoryIds"
        :options="categories"
        :label="$t('label.category')"
        :placeholder="$t('placeholder.selectCategory')"
      />

      <OurMultiselect
        v-if="storages"
        v-model="form.storageIds"
        :options="storages"
        :label="$t('label.storage')"
        :placeholder="$t('placeholder.selectStorage')"
        item-label="name"
      />

      <OurCheckbox v-model="form.isActive" :label="$t('page.accessGroups.active')" />
    </OurGroup>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapState } from "vuex";

export default {
  name: "AccessGroupsForm",

  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      form: {
        name: "",
        productCategoryIds: [],
        storageIds: [],
        isActive: false,
      },
    };
  },

  validations: {
    form: {
      name: { required },
    },
  },

  computed: {
    ...mapState("fleaMarket", ["categories"]),
    ...mapState("accessGroups", ["storages"]),

    nameError() {
      const isDirty = this.$v.form.name.$dirty;
      const isFilled = this.$v.form.name.required;

      let error = "";

      if (isDirty && !isFilled) {
        error = this.$t("validation.required");
      }

      return error;
    },
  },

  watch: {
    value: {
      handler: "getForm",
      deep: true,
    },
  },

  methods: {
    getForm() {
      if (this.value) {
        this.form = { ...this.value };
      }
    },

    emitForm() {
      if (this.$v.$invalid) {
        this.$v.$touch();

        return;
      }

      this.form.isValid = true;
      this.$emit("input", this.form);
    },
  },
};
</script>

<template>
  <div>
    <h2 class="mb-5 text-xl font-bold">Перелік дозволів:</h2>
    <div v-for="(item, index) in permissions" :key="index">
      <div class="mb-5">
        <div class="align-center mb-4 flex justify-between">
          <span class="text-lg font-bold">
            {{ item.groupName }}
          </span>
          <div>
            <OurSwitch
              v-model="item.isActive"
              color="gray"
              name="condition"
              size="md"
              @on-click-change-switch="handleMainGroupSwitch(item.isActive, index)"
            />
          </div>
        </div>
        <div v-for="listItem in item.groupList" :key="listItem.id" class="permission">
          <div class="mb-4 flex items-center justify-between">
            <div>
              {{ listItem.name }}
            </div>
            <OurSwitch
              v-model="listItem.isActive"
              color="green"
              name="condition"
              size="sm"
              @on-click-change-switch="handleListItemSwitch(index)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    permissionsList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      permissions: this.permissionsList,
    };
  },
  watch: {
    permissions: {
      handler(list) {
        this.$emit("updated-permissions-list", list);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    handleMainGroupSwitch(groupIsActive, index) {
      this.permissions = this.permissions.map((item, idx) => {
        return {
          ...item,
          groupList: item.groupList.map((subItem) => {
            return {
              ...subItem,
              isActive: index === idx ? groupIsActive : subItem.isActive,
            };
          }),
        };
      });
    },
    handleListItemSwitch(index) {
      this.permissions = this.permissions.map((item, idx) => {
        const groupItemsSwitchInactive = item.groupList.every((ev) => ev.isActive === true);
        const someListItemSwitchIsActive = item.groupList.some((sm) => sm.isActive);

        return {
          ...item,
          isActive:
            index === idx
              ? groupItemsSwitchInactive
                ? groupItemsSwitchInactive
                : someListItemSwitchIsActive
              : item.isActive,
        };
      });
    },
  },
};
</script>

<style lang="postcss" scoped>
.permission:deep {
  @apply flex flex-col;
  .switch-wrapper {
    width: unset;
  }
}
</style>

<template>
  <div class="our-text-field" :class="fieldClasses" :data-cy="dataCy">
    <div class="our-text-field-label">
      {{ label }}
    </div>

    <!-- @slot Use it to change structure. -->
    <slot :value="value">
      <div class="our-text-field-value">
        {{ value }}
      </div>
    </slot>
  </div>
</template>

<script>
export default {
  name: "OurTextField",

  props: {
    /**
     * Set label.
     */
    label: {
      type: String,
      default: "",
    },

    /**
     * Set value.
     */
    value: {
      type: String,
      default: "–",
    },

    /**
     * Set size
     * @values sm, md, lg
     */
    size: {
      type: String,
      default: "md",
    },

    /**
     * Sets data-cy attribute for correct element sampling in tests.
     */
    dataCy: {
      type: String,
      default: "",
    },
  },

  computed: {
    fieldClasses() {
      const size = `size-${this.size}`;

      return [size];
    },
  },
};
</script>

<style lang="postcss" scoped>
.our-text-field {
  &-label {
    @apply font-normal text-gray-500;
  }

  &-value {
    @apply font-normal text-gray-800;
    word-break: break-word;
  }
}

.size {
  &-sm {
    .our-text-field-label {
      @apply mb-0.5 text-xs;
    }

    .our-text-field-value {
      @apply text-sm;
    }
  }

  &-md {
    .our-text-field-label {
      @apply mb-1 text-sm;
    }

    .our-text-field-value {
      @apply text-base;
    }
  }

  &-lg {
    .our-text-field-label {
      @apply mb-1.5 text-base;
    }

    .our-text-field-value {
      @apply text-lg;
    }
  }
}
</style>

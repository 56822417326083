<template>
  <OurPage class="users-add" :title="$t('title.customers.add')" :back-route="backRoute" width="md">
    <CustomerForm
      ref="customerForm"
      v-model="form"
      class="user-form"
      @openModal="show = !show"
      @openAddDealersModal="openAddDealersModal"
    />

    <AddDealersModal :show.sync="isShownAddDealersModal" @updateDealers="getData" />

    <AddAccessGroupModal :show.sync="show" @getData="getData" />

    <template #footer-left>
      <OurButton class="button" :text="$t('button.add')" @click="onClickSaveButton" />

      <OurLink :route="backRoute">
        <OurButton class="button" :text="$t('button.back')" variant="secondary" />
      </OurLink>
    </template>
  </OurPage>
</template>

<script>
import { mapMutations } from "vuex";
import apolloMixin from "@/mixins/apollo.mixin";
import { accessGroupsQuery, companiesQuery, storagesQuery } from "./gql/queries";
import { createCustomerMutation } from "./gql/mutations";
import CustomerForm from "../_components/CustomerForm";
import AddAccessGroupModal from "@/views/settings/dealer-company/_components/AddAccessGroupModal.vue";
import AddDealersModal from "@/views/settings/dealer-company/_components/AddDealersModal.vue";

export default {
  name: "CustomersAdd",

  components: {
    AddDealersModal,
    AddAccessGroupModal,
    CustomerForm,
  },

  mixins: [apolloMixin],

  data() {
    return {
      backRoute: {
        name: "Customers",
        title: this.$t("title.customers._"),
      },
      form: {
        name: "",
        storageId: "",
        accessGroupId: "",
        companies: [],
        isActive: true,
      },
      show: false,
      isShownAddDealersModal: false,
    };
  },

  created() {
    this.getData();
  },

  methods: {
    ...mapMutations("dealerUsers", ["SET_COMPANIES"]),
    ...mapMutations("accessGroups", ["SET_STORAGES"]),
    ...mapMutations("dealerCompany", ["SET_ACCESS_GROUPS"]),

    openAddDealersModal() {
      this.isShownAddDealersModal = true;
    },

    openDealersModal() {
      this.isShownDealersModal = true;
    },

    async getData() {
      const filters = {
        where: {
          column: "customerId",
          operator: "null",
          value: "",
        },
      };

      const { allCompanies } = await this.$get(companiesQuery, { filters });

      this.SET_COMPANIES(allCompanies);

      const { storages } = await this.$get(storagesQuery);

      this.SET_STORAGES(storages);

      const { accessGroups } = await this.$get(accessGroupsQuery);

      this.SET_ACCESS_GROUPS(accessGroups);
    },

    async onClickSaveButton() {
      this.$refs.customerForm.emitForm();

      if (this.form.isValid) {
        const form = { ...this.form, defaultCompanyId: this.form.companies[0].id };

        const { createCustomer } = await this.$post(createCustomerMutation, form, {
          delaySuccessNotify: true,
        });

        if (createCustomer.id) await this.$router.push({ name: "Customers" });
      }
    },
  },
};
</script>

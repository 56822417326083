<template>
  <div ref="OurButtonGroup" class="our-button-group" :class="componentClasses" :data-cy="dataCy">
    <slot>
      <OurButtonGroupItem
        v-for="(item, index) in options"
        :key="item.value"
        :value="item.value"
        :size="size"
        :label="item.label"
        :data-cy="`${dataCy}-item-${index}`"
      />
    </slot>
  </div>
</template>

<script>
import OurButtonGroupItem from "@/components/_bit/ButtonGroupItem";

export default {
  name: "OurButtonGroup",

  components: { OurButtonGroupItem },

  props: {
    /**
     * Set buttons name.
     */
    name: {
      type: String,
      required: true,
      default: "",
    },

    /**
     * Set data for buttons.
     */
    options: {
      type: Array,
      default: () => [],
    },

    /**
     * Set current value.
     */
    value: {
      type: [String, Number],
      default: "",
    },

    /**
     * The size of the buttons.
     * @values sm, md, lg
     */
    size: {
      type: String,
      default: "md",
    },

    /**
     * Fit buttons to the parent block.
     */
    wide: {
      type: Boolean,
      default: false,
    },

    /**
     * Sets data-cy attribute for correct element sampling in tests.
     */
    dataCy: {
      type: String,
      default: "",
    },
  },

  computed: {
    selectedItem: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    componentClasses() {
      const wide = this.wide ? "our-button-group-wide" : "";

      return [wide];
    },
  },

  watch: {
    selectedItem: {
      handler: "onChangeSelectedItem",
      deep: true,
    },
  },

  methods: {
    onChangeSelectedItem() {
      let items = this.$refs.OurButtonGroup.__vue__.$children;
      const isItemWrappedInOurLink = items[0].$options._componentTag === "OurLink";

      // check if OurButtonGroupItem wrapped in OurLink
      if (isItemWrappedInOurLink) {
        for (let i = 0; i < items.length; i++) {
          items[i].$children[0].$children[0].setDefaultValue();
        }
      }
      // no nesting
      else {
        for (let i = 0; i < items.length; i++) {
          items[i].setDefaultValue();
        }
      }
    },
  },
};
</script>

<style lang="postcss" scoped>
.our-button-group {
  @apply flex space-x-px;

  &-wide {
    &:deep(.our-button-group-item) {
      @apply w-full;

      .label {
        @apply w-full justify-center;
      }
    }
  }

  &:deep(.our-button-group-item) {
    &:first-child {
      .label {
        @apply rounded-l-lg;
      }
    }

    &:last-child {
      .label {
        @apply rounded-r-lg;
      }
    }
  }

  &:deep(.our-link-wrapper) {
    .our-button-group-item {
      .label {
        @apply rounded-none;
      }
    }

    &:first-child {
      .label {
        @apply rounded-l-lg;
      }
    }

    &:last-child {
      .label {
        @apply rounded-r-lg;
      }
    }
  }
}
</style>

import ApiService from "@/services/api.service";

const CART_VALUES = {
  storage: "fromStorage",
  object: "toObject",
  post: "fromPost",
  card: "card",
  account: "account",
  other: "other",
};

async function fullTextSearch(searchData) {
  const { search, page, perPage, name, categoryId, status } = searchData;
  let resource = `/products/search?search=${search}&pagination[page]=${page}&pagination[perPage]=${perPage}`;

  if (name) resource += `&filters[name]=${name}`;
  if (categoryId) resource += `&filters[categoryId]=${categoryId}`;
  if (status) resource += `&filters[status]=${status}`;

  const response = await ApiService.get(resource);

  return response.data.data;
}

export { CART_VALUES, fullTextSearch };

import i18n from "@/plugins/vue-i18n";

const FleaMarket = () => import(/* webpackChunkName: "flea-market" */ "@/views/flea-market/index");
const FleaMarketAdd = () => import(/* webpackChunkName: "flea-market" */ "@/views/flea-market/add");
const FleaMarketDetails = () =>
  import(/* webpackChunkName: "flea-market" */ "@/views/flea-market/details");
const FleaMarketEdit = () =>
  import(/* webpackChunkName: "flea-market" */ "@/views/flea-market/edit");

const fleaMarket = [
  {
    path: "/flea-market",
    name: "FleaMarket",
    component: FleaMarket,
    meta: {
      title: i18n.t("title.fleaMarket._"),
    },
  },
  {
    path: "/flea-market/add",
    name: "FleaMarketAdd",
    component: FleaMarketAdd,
    meta: {
      title: i18n.t("title.fleaMarket.add"),
    },
  },
  {
    path: "/flea-market/:id",
    name: "FleaMarketDetails",
    component: FleaMarketDetails,
    meta: {
      title: i18n.t("title.fleaMarket.details"),
    },
  },
  {
    path: "/flea-market/:id/details",
    name: "FleaMarketEdit",
    component: FleaMarketEdit,
    meta: {
      title: i18n.t("title.fleaMarket.details"),
    },
  },
];

export default fleaMarket;

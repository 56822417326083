import i18n from "@/plugins/vue-i18n";

const ServiceCases = () =>
  import(/* webpackChunkName: "service-cases" */ "@/views/service-cases/index");

const serviceCases = [
  {
    path: "/service-cases",
    name: "ServiceCases",
    component: ServiceCases,
    meta: {
      title: i18n.t("title.serviceCases._"),
    },
  },
];

export default serviceCases;

<template>
  <div class="text-header" :class="headerClasses" :data-cy="dataCy">
    {{ text }}
  </div>
</template>

<script>
import { getThemeClass } from "@/services/_bit/UiService";

export default {
  name: "OurHeader",

  props: {
    /**
     * Set text.
     */
    text: {
      type: String,
      default: "",
    },

    /**
     * Set size.
     * @values xs, sm, md, lg, xl, 2xl
     */
    size: {
      type: String,
      default: "md",
    },

    /**
     * Set weight.
     * @values regular, medium, bold
     */
    weight: {
      type: String,
      default: "medium",
    },

    /**
     * Show bottom line.
     */
    underlined: {
      type: Boolean,
      default: false,
    },

    /**
     * Text color.
     * @values accent, gray, red, orange, yellow, green, blue, violet, fuchsia, black, white
     */
    color: {
      type: String,
      default: "gray",
    },

    /**
     * Sets data-cy attribute for correct element sampling in tests.
     */
    dataCy: {
      type: String,
      default: "",
    },
  },

  computed: {
    headerClasses() {
      const size = `size-${this.size}`;
      const themeClass = getThemeClass();
      const weight = `weight-${this.weight}`;
      const underline = this.underlined ? "under-line" : "";
      const color = `color-${this.color}`;

      return [themeClass, size, weight, underline, color];
    },
  },
};
</script>

<style lang="postcss" scoped>
.size-xs {
  @apply text-lg;
}

.size-sm {
  @apply text-xl;
}

.size-md {
  @apply text-2xl;
}

.size-lg {
  @apply text-xl sm:text-3xl;
}

.size-xl {
  @apply text-4xl;
}

.size-2xl {
  @apply text-5xl;
}

.under-line {
  @apply border-b border-accent;

  &.size-xs {
    @apply pb-1.5;
  }

  &.size-sm {
    @apply pb-2;
  }

  &.size-md {
    @apply pb-2;
  }

  &.size-lg {
    @apply pb-2.5;
  }

  &.size-xl {
    @apply pb-2.5;
  }

  &.size-2xl {
    @apply pb-3;
  }
}

.weight {
  &-regular {
    @apply font-normal;
  }

  &-medium {
    @apply font-medium;
  }

  &-bold {
    @apply font-bold;
  }
}

.color {
  &-accent {
    @apply text-accent;

    &.under-line {
      @apply border-accent;
    }
  }

  &-white {
    @apply text-white;

    &.under-line {
      @apply border-white;
    }
  }

  &-black {
    @apply text-gray-900;

    &.under-line {
      @apply border-gray-900;
    }
  }

  &-gray {
    @apply text-gray-800;

    &.under-line {
      @apply border-gray-800;
    }
  }

  &-red {
    @apply text-red-800;

    &.under-line {
      @apply border-red-800;
    }
  }

  &-orange {
    @apply text-orange-800;

    &.under-line {
      @apply border-orange-800;
    }
  }

  &-yellow {
    @apply text-yellow-800;

    &.under-line {
      @apply border-yellow-800;
    }
  }

  &-green {
    @apply text-green-800;

    &.under-line {
      @apply border-green-800;
    }
  }

  &-blue {
    @apply text-blue-800;

    &.under-line {
      @apply border-blue-800;
    }
  }

  &-violet {
    @apply text-violet-800;

    &.under-line {
      @apply border-violet-800;
    }
  }

  &-fuchsia {
    @apply text-fuchsia-800;

    &.under-line {
      @apply border-fuchsia-800;
    }
  }
}
</style>

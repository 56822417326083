<template>
  <OurModal v-model="isShownModal" :title="$t('title.accessGroups.add')" mobile-footer-reverse>
    <AccessGroupsForm ref="accessGroupsForm" v-model="form" class="access-groups-form" />
    <template #footer-left>
      <OurButton :text="$t('button.add')" @click="onClickApplyButton" />
      <OurButton :text="$t('button.close')" variant="secondary" @click="onClickCloseButton" />
    </template>
  </OurModal>
</template>

<script>
import AccessGroupsForm from "@/views/settings/access-groups/_components/AccessGroupsForm.vue";
import { mapMutations } from "vuex";
import { productCategoriesAndStoragesQuery } from "@/views/settings/access-groups/add/gql/queries";
import { createAccessGroupMutation } from "@/views/settings/access-groups/add/gql/mutations";
import apolloMixin from "@/mixins/apollo.mixin";

export default {
  name: "AddAccessGroupModal",
  components: { AccessGroupsForm },
  mixins: [apolloMixin],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        name: "",
        productCategoryIds: [],
        storageIds: [],
        isActive: false,
      },
    };
  },
  computed: {
    isShownModal: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  created() {
    this.getData();
  },

  methods: {
    ...mapMutations("fleaMarket", ["SET_CATEGORIES"]),
    ...mapMutations("accessGroups", ["SET_STORAGES"]),

    closeModal() {
      this.isShownModal = false;
    },

    onClickCloseButton() {
      this.closeModal();
    },

    async onClickApplyButton() {
      this.$refs.accessGroupsForm.emitForm();

      if (this.form.isValid) {
        const { createAccessGroup } = await this.$post(createAccessGroupMutation, this.form);

        if (createAccessGroup.id) await this.$emit("getData");
        this.closeModal();
      }
    },

    async getData() {
      const { productCategories, storages } = await this.$get(productCategoriesAndStoragesQuery);

      this.SET_CATEGORIES(productCategories);
      this.SET_STORAGES(storages);
    },
  },
};
</script>

<template>
  <div class="text-block" :class="[themeClass, textBlockClasses]" :data-cy="dataCy">
    <!-- @slot Use it to add something. -->
    <slot />
  </div>
</template>

<script>
import { getThemeClass } from "@/services/_bit/UiService";

export default {
  name: "OurTextBlock",

  props: {
    /**
     * Set text block size.
     * @values sm, md, lg
     */
    size: {
      type: String,
      default: "md",
    },

    /**
     * Set component align.
     * @values left , center, right
     */
    align: {
      type: String,
      default: "left",
    },

    /**
     * Sets data-cy attribute for correct element sampling in tests.
     */
    dataCy: {
      type: String,
      default: "",
    },
  },

  computed: {
    themeClass() {
      return getThemeClass();
    },

    textBlockClasses() {
      const size = `size-${this.size}`;
      const align = `position-${this.align}`;

      return [size, align];
    },
  },
};
</script>

<style lang="postcss" scoped>
.text-block:deep() {
  @apply space-y-4;
  @apply font-normal leading-normal;

  a {
    @apply font-medium;
    @apply border-b border-dashed;
    @apply transition duration-100 ease-in-out;

    &:hover {
      @apply opacity-80;
    }
  }

  b {
    @apply font-bold;
  }

  i {
    @apply italic;
  }

  ul,
  ol {
    @apply font-normal leading-[1.125rem];
    @apply list-inside;
    @apply ml-2;
  }

  ul {
    @apply list-disc;
  }

  ol {
    @apply list-decimal;
  }

  &.size {
    &-sm {
      @apply text-sm;
    }

    &-md {
      @apply text-base;
    }

    &-lg {
      @apply text-lg;
    }
  }

  &.position {
    &-left {
      @apply text-left;
    }

    &-center {
      @apply text-center;
    }

    &-right {
      @apply text-right;
    }
  }
}
</style>

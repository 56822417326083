<template>
  <label ref="inputCheckbox" class="checkbox-wrap" :class="themeClass">
    <t-checkbox
      v-model="selected"
      class="checkbox"
      :class="checkboxSizeClass"
      :disabled="disabled"
      :value="values.select"
      :unchecked-value="values.unselect"
      :data-cy="dataCy"
    />

    <OurSvgIcon
      v-if="isShownPartialIcon"
      :src="icons.Minus2"
      :size="size"
      class="checkbox-partial-icon"
      color="white"
    />

    <div>
      <span v-if="label" class="label">
        {{ label }}
      </span>

      <!-- @slot Use it to add some description. -->
      <div v-if="isShownDescription" class="checkbox-description">
        <slot name="description" :description="description">
          <span>{{ description }}</span>
        </slot>
      </div>
    </div>
  </label>
</template>

<script>
import { getThemeClass } from "@/services/_bit/UiService";

import TCheckbox from "vue-tailwind/dist/t-checkbox";
import OurSvgIcon from "@/components/_bit/IconSvg";

export default {
  name: "OurCheckbox",

  components: { OurSvgIcon, TCheckbox },

  props: {
    /**
     * Set checkbox value.
     */
    value: {
      type: [Boolean, String, Number],
      default: false,
    },

    /**
     * Set own values for checkbox states (selected | unselected).
     */
    values: {
      type: Object,
      default: () => ({
        select: true,
        unselect: false,
      }),
    },

    /**
     * Set checkbox label.
     */
    label: {
      type: String,
      default: "",
    },

    /**
     * Set checkbox size.
     * @values sm, md, lg
     */
    size: {
      type: String,
      default: "md",
    },

    /**
     * Set description.
     */
    description: {
      type: String,
      default: "",
    },

    /**
     * Make checkbox inactive.
     */
    disabled: {
      type: Boolean,
      default: false,
    },

    /**
     * Make checkbox partially selected (change the selected tick to a minus).
     */
    partial: {
      type: Boolean,
      default: false,
    },

    /**
     * Sets data-cy attribute for correct element sampling in tests.
     */
    dataCy: {
      type: String,
      default: "",
    },
  },

  computed: {
    selected: {
      get() {
        const { select, unselect } = this.values;

        return this.value === select ? select : unselect;
      },
      set(value) {
        const checkboxGroup = this.$refs.inputCheckbox.__vue__.$parent.$refs.OurCheckboxGroup;

        if (checkboxGroup) {
          let selectedItems = checkboxGroup.__vue__.selectedItems;

          if (!value) {
            const checkboxIndexInGroup = selectedItems.findIndex(
              (item) => item === this.values.select
            );

            selectedItems.splice(checkboxIndexInGroup, 1);
          } else {
            checkboxGroup.__vue__.selectedItems.push(value);
          }
        } else {
          this.$emit("input", value);
        }
      },
    },

    icons: () => ({
      Minus2: require("@/components/_bit/IconSvg/icons/Minus2.svg"),
    }),

    isShownDescription() {
      return this.description || !!this.$slots["description"];
    },

    checkboxSizeClass() {
      return {
        "size-sm": this.size === "sm",
        "size-md": this.size === "md",
        "size-lg": this.size === "lg",
      };
    },

    isShownPartialIcon() {
      const { select } = this.values;

      return this.partial && this.selected === select;
    },

    themeClass() {
      return getThemeClass();
    },
  },
};
</script>

<style lang="postcss" scoped>
.checkbox-wrap {
  @apply flex max-w-max items-center;
  @apply relative;

  .checkbox {
    @apply border border-solid border-gray-300;
    @apply mr-4;

    &:disabled {
      @apply border-gray-100 bg-gray-100;
    }

    &:hover {
      @apply border-gray-400;
    }

    &:focus {
      @apply border-gray-500 ring-4 ring-gray-200;
    }

    &:active {
      @apply border-gray-900 bg-gray-900;
    }

    &:checked {
      @apply border-gray-900 bg-gray-900;
    }

    &-partial-icon {
      @apply absolute rounded bg-gray-900;
    }
  }

  .label {
    @apply text-base font-normal text-gray-900;
  }
}

.checkbox {
  &-description {
    @apply text-xs font-normal text-gray-500/[85];
    @apply pl-4 pt-1;
  }
}

.size {
  &-sm {
    @apply h-5 w-5;

    & + .label {
      @apply text-sm;
    }
  }

  &-md {
    @apply h-6 w-6;
  }

  &-lg {
    @apply h-7 w-7;

    & + .label {
      @apply text-lg;
    }
  }
}
</style>

<!-- Accent theme -->
<style scoped lang="postcss">
.accent-theme {
  &.checkbox-wrap {
    .checkbox {
      @apply border border-solid border-gray-300;

      &:disabled {
        @apply bg-accent bg-opacity-10;
        @apply border-accent border-opacity-10;
      }

      &:hover {
        @apply border-accent border-opacity-40;
      }

      &:focus {
        @apply border-accent border-opacity-50;
        @apply ring-4 ring-accent ring-opacity-15;
      }

      &:active {
        @apply border-accent bg-accent;
      }

      &:checked {
        @apply border-accent bg-accent;
      }
    }

    .label {
      @apply text-sm font-normal text-gray-900;
      @apply ml-4;
    }
  }
}
</style>

import i18n from "@/plugins/vue-i18n";

/* eslint-disable no-console */

import { register } from "register-service-worker";

if (process.env.NODE_ENV === "production" && !window.Cypress) {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        "App is being served from cache by a service worker.\n" +
          "For more details, visit https://goo.gl/AFskqB"
      );
      Notification.requestPermission((status) => {
        console.log("Status", status);
      });
    },
    registered() {
      console.log("Service worker has been registered.");
    },
    cached() {
      console.log("Content has been cached for offline use.");
    },
    updatefound() {
      console.log("New content is downloading.");
    },
    updated(registration) {
      console.log("New content is available; please refresh.");
      const confirmationResult = confirm(i18n.t("pwa.reloadConfirm"));

      if (confirmationResult) registration.waiting.postMessage({ action: "skipWaiting" });
    },
    offline() {
      console.log("No internet connection found. App is running in offline mode.");
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    },
  });

  let refreshing;

  navigator.serviceWorker.addEventListener("controllerchange", () => {
    if (refreshing) return;

    window.location.reload();
    refreshing = true;
  });
}

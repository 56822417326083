import gql from "graphql-tag";

export const updateNoticeMutation = gql`
  mutation updateNotice(
    $id: ID!
    $name: String!
    $description: String!
    $product: AssociateNullable
    $category: AssociateNullable
    $currency: Associate!
    $price: Float!
    $quantity: Int
    $location: String
    $isNew: Boolean
    $condition: Int
    $contactName: String!
    $contactPhone: String!
    $isActive: Boolean
    $files: SyncAssociations
  ) {
    updateNotice(
      id: $id
      name: $name
      description: $description
      product: $product
      category: $category
      currency: $currency
      price: $price
      quantity: $quantity
      location: $location
      isNew: $isNew
      condition: $condition
      contactName: $contactName
      contactPhone: $contactPhone
      isActive: $isActive
      files: $files
    ) {
      id
    }
  }
`;

export const uploadFilesMutation = gql`
  mutation uploadFiles($files: [Upload!]!) {
    uploadFiles(files: $files) {
      id
      name
      link
    }
  }
`;

export const deleteFileMutation = gql`
  mutation deleteFile($id: ID!) {
    deleteFile(id: $id) {
      id
      name
    }
  }
`;

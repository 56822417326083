import i18n from "@/plugins/vue-i18n";
const WishList = () =>
  import(/* webpackChunkName: "mutual-settlements" */ "@/views/wish-list/index");

const wish = [
  {
    path: "/wish-list",
    name: "WishList",
    component: WishList,
    meta: {
      title: i18n.t("title.wishList._"),
    },
  },
];

export default wish;

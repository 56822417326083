<template>
  <div class="h-full" :class="appClasses" :style="cssVariables">
    <router-view />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { convertHexInRgb, isPWA } from "@/services/_bit/UiService";

export default {
  name: "App",

  computed: {
    ...mapState("user", ["accentColor"]),

    cssVariables() {
      return {
        "--accent-color": convertHexInRgb(this.accentColor),
      };
    },

    appClasses() {
      return {
        "pwa-app": isPWA,
      };
    },
  },
};
</script>

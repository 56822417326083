<template>
  <div class="accordion-item" :class="sizeClass" :data-cy="dataCy" @click="onClickItem">
    <div class="item-info">
      <div class="info-title">
        <div>
          {{ title }}
        </div>

        <OurSvgIcon :src="currentIcon" class="info-icon" :size="size" color="gray" />
      </div>

      <div :ref="name" class="info-description">
        {{ description }}
      </div>
    </div>

    <div class="separator" />
  </div>
</template>

<script>
import OurSvgIcon from "@/components/_bit/IconSvg";

export default {
  name: "AccordionItem",
  components: {
    OurSvgIcon,
  },

  props: {
    /**
     * Set component title.
     */
    title: {
      type: String,
      required: true,
    },

    /**
     * Set component description.
     */
    description: {
      type: String,
      required: true,
    },

    /**
     * Set unique block name.
     * @ignore
     */
    name: {
      type: String,
      required: true,
    },

    /**
     * The size of component.
     * @values sm, md, lg
     */
    size: {
      type: String,
      default: "md",
    },

    /**
     * Sets data-cy attribute for correct element sampling in tests.
     */
    dataCy: {
      type: String,
      default: "",
    },
  },

  data: () => ({
    currentIcon: "",
  }),

  computed: {
    descriptionNode() {
      return this.$refs[this.name];
    },

    sizeClass() {
      return `size-${this.size}`;
    },

    icons: () => ({
      Minus2: require("@/components/_bit/IconSvg/icons/Minus2.svg"),
      Plus2: require("@/components/_bit/IconSvg/icons/Plus2.svg"),
    }),
  },

  created() {
    this.currentIcon = this.icons.Plus2;
  },

  methods: {
    onClickItem() {
      this.descriptionNode.classList.toggle("show-description");

      const hasShowClass = this.descriptionNode.classList.contains("show-description");
      const { Minus2, Plus2 } = this.icons;

      this.currentIcon = hasShowClass ? Minus2 : Plus2;

      this.$emit("itemClicked", this.name);
    },

    closeDescription(node) {
      const hasShowClass = this.descriptionNode.classList.contains("show-description");

      if (hasShowClass && node !== this.name) {
        this.descriptionNode.classList.remove("show-description");

        this.currentIcon = this.icons.Plus2;
      }
    },
  },
};
</script>

<style scoped lang="postcss">
.accordion-item {
  @apply cursor-pointer;

  &:not(:first-child) {
    @apply pt-6;
  }

  &:last-child {
    .separator {
      @apply hidden;
    }
  }

  .item-info {
    @apply leading-6;

    .info-title {
      @apply flex items-center justify-between;
      @apply font-medium text-gray-800;

      .info-icon:deep(g rect) {
        @apply opacity-100;
      }
    }

    .info-description {
      @apply h-0 overflow-hidden opacity-0;
      @apply text-gray-600;
    }

    .show-description {
      @apply h-full pt-3 opacity-100;
      @apply transition-all duration-300 ease-in-out;
    }
  }

  .separator {
    @apply mt-2 h-px w-full lg:mt-6;
    @apply bg-gray-100;
    @apply mt-2.5;
  }
}

.size {
  &-sm {
    @apply text-sm;

    .info-description {
      @apply text-xs;
    }
  }

  &-md {
    @apply text-base;

    .info-description {
      @apply text-sm;
    }
  }

  &-lg {
    @apply text-lg;

    .info-description {
      @apply text-base;
    }
  }
}
</style>

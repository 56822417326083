import DataServiceDefault from "@/services/_bit/DataService";

class DataService extends DataServiceDefault {
  // You can redefine some part of default class here

  /**
   * Use for return prepared phone
   * @returns String
   */
  getPreparedPhone(phone) {
    const preparedPhone = phone.replace(/[\s+]/g, "");

    return `+${preparedPhone}`;
  }

  /**
   * Use for return prepared price
   * @returns Number
   */
  getPreparedPrice(price) {
    if (toString(price).includes(",")) {
      return parseFloat(price.replace(",", "."));
    } else {
      return parseFloat(price);
    }
  }

  /**
   * Use for return prepared location
   * @returns String
   */
  getPreparedLocation(location) {
    location = location.split("");
    const firstLetter = location[0].toUpperCase();

    location.splice(0, 1, firstLetter);

    return location.join("");
  }

  /**
   * Use for return prepared status
   * @returns String
   */
  getPreparedStatus(status) {
    return status.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
  }

  /**
   * Use for return prepared id
   * @returns String
   */
  getPreparedId(id) {
    return id.replace(/[a-zA-Z-]/g, "");
  }
}

export default new DataService();

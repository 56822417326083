import gql from "graphql-tag";

export const articlesQuery = gql`
  query articles {
    articles {
      id
      name
      section {
        id
      }
    }
  }
`;

<template>
  <div>
    <OurGroups>
      <OurGroup>
        <OurInput
          v-model="form.name"
          :label="$t('label.title')"
          :placeholder="$t('placeholder.title')"
          :error="nameError"
        />
        <OurMultiselect
          v-model="form.companies"
          :options="companies"
          :label="$t('label.company') + ' *'"
          :placeholder="$t('placeholder.selectCompany')"
          item-label="label"
          :error="companiesError"
        />
        <p class="text-sm text-gray-500">
          * Перша вибрана компанія буде компанією за замовчуванням
        </p>
        <OurSelect
          v-model="form.accessGroupId"
          :options="accessGroups"
          :label="$t('label.accessGroup')"
          :placeholder="$t('placeholder.chooseFromList')"
          :add-option="true"
          :error="accessGroupError"
          @addOption="addAccessGroup"
        />
        <OurSelect
          v-model="form.storageId"
          :options="storages"
          :label="$t('label.storage')"
          :placeholder="$t('placeholder.selectStorage')"
          item-label="name"
          :error="storageError"
        />
        <OurLink
          v-if="dealers.length"
          size="sm"
          color="gray"
          :dashed="true"
          @click="openDealersTable"
        >
          <div>{{ $t("page.dealersCompany.users") }}: {{ dealers.length }}</div>
        </OurLink>
        <OurLink v-else size="sm" color="gray" :dashed="true" @click="openAddDealersModal">
          <div>{{ $t("page.dealersCompany.addUser") }}</div>
        </OurLink>
        <OurCheckbox v-model="form.isActive" :label="$t('label.active')" />
      </OurGroup>
    </OurGroups>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapState } from "vuex";

export default {
  name: "CustomerForm",

  props: {
    value: {
      type: Object,
      default: () => {},
    },

    editForm: {
      type: Boolean,
      default: false,
    },

    dealers: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      form: {
        name: "",
        storageId: "",
        accessGroupId: "",
        companies: [],
        isActive: true,
      },
    };
  },

  validations: {
    form: {
      name: { required },
      storageId: { required },
      accessGroupId: { required },
      companies: { required },
    },
  },

  computed: {
    ...mapState("dealerUsers", ["roles", "customers", "companies"]),
    ...mapState("dealerCompany", ["accessGroups"]),
    ...mapState("accessGroups", ["storages"]),

    nameError() {
      const isDirty = this.$v.form.name.$dirty;
      const isFilled = this.$v.form.name.required;

      let error = "";

      if (isDirty && !isFilled) {
        error = this.$t("validation.required");
      }

      return error;
    },

    storageError() {
      const isDirty = this.$v.form.storageId.$dirty;
      const isFilled = this.$v.form.storageId.required;

      let error = "";

      if (isDirty && !isFilled) {
        error = this.$t("validation.required");
      }

      return error;
    },

    accessGroupError() {
      const isDirty = this.$v.form.accessGroupId.$dirty;
      const isFilled = this.$v.form.accessGroupId.required;

      let error = "";

      if (isDirty && !isFilled) {
        error = this.$t("validation.required");
      }

      return error;
    },

    companiesError() {
      const isDirty = this.$v.form.companies.$dirty;
      const isFilled = this.$v.form.companies.required;

      let error = "";

      if (isDirty && !isFilled) {
        error = this.$t("validation.required");
      }

      return error;
    },
  },

  watch: {
    value: {
      handler: "getForm",
      deep: true,
    },
  },

  mounted() {
    this.getForm();
  },

  methods: {
    addAccessGroup() {
      this.$emit("openModal");
    },
    openDealersTable() {
      this.$emit("openDealersModal");
    },
    openAddDealersModal() {
      this.$emit("openAddDealersModal");
    },
    getForm() {
      if (this.value) {
        this.form = { ...this.value };
      }
    },
    emitForm() {
      if (this.$v.$invalid) {
        this.$v.$touch();

        return;
      }

      this.form.isValid = true;
      this.$emit("input", this.form);
    },
  },
};
</script>

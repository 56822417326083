import notFound from "./notFound";

const ErrorLayout = () => import(/* webpackChunkName: "error" */ "@/layouts/error");

const errorLayout = [
  {
    path: "/",
    name: "ErrorLayout",
    redirect: { name: "RequestList" },
    component: ErrorLayout,
    children: [...notFound],
  },
];

export default errorLayout;

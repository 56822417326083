<template>
  <OurCard class="order-card mb-4" border-radius="sm">
    <div class="order-card-wrapper" @click="onClick">
      <div v-if="item.status" class="flex w-full items-center justify-between">
        <OurTag class="status" size="md" :text="item.status.text" :color="item.status.color" />
        <div class="text-sm text-gray-500">{{ `#${item.id}` }}</div>
      </div>
      <OurTextField v-if="item.name" :label="$t('page.customers.title')" :value="item.name" />
      <OurTextField
        :label="$t('page.customers.accessGroup')"
        :value="item.accessGroup ? item.accessGroup : '-'"
      />
      <OurTextField v-if="item.companies.length" :label="$t('page.customers.companies')">
        <div class="flex flex-wrap">
          <div v-for="company in item.companies" :key="company.id" class="dealer-name me-3">
            {{ company.name }}
            <span>,</span>
          </div>
        </div>
      </OurTextField>
      <OurTextField v-if="item.dealers.length" :label="$t('page.customers.users')">
        <div class="flex flex-wrap">
          <div v-for="dealer in item.dealers" :key="dealer.dealer" class="dealer-name me-3">
            {{ dealer.firstName }} {{ dealer.lastName }}
            <span>,</span>
          </div>
        </div>
      </OurTextField>
    </div>
  </OurCard>
</template>

<script>
export default {
  name: "CustomersCard",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },

  methods: {
    onClick() {
      this.$emit("clickOnOrder");
    },
  },
};
</script>

<style scoped lang="postcss">
.order-card {
  .order-card-wrapper:deep {
    @apply flex flex-col items-start space-y-4;

    .status {
      @apply w-auto;
    }
  }
}
.dealer-name:last-child {
  span {
    display: none;
  }
}
</style>

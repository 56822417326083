<template>
  <div class="user-form">
    <OurGroups>
      <OurGroup class="contact-information" :title="$t('title.users.contact')">
        <OurInput
          v-model="form.lastName"
          :label="$t('label.lastName')"
          :placeholder="$t('placeholder.lastName')"
          :error="lastNameError"
          :disabled="userDeleted"
        />

        <OurInput
          v-model="form.firstName"
          :label="$t('label.name')"
          :placeholder="$t('placeholder.name')"
          :error="firstNameError"
          :disabled="userDeleted"
        />

        <OurInput
          v-model="form.email"
          :label="$t('label.email')"
          :placeholder="$t('placeholder.email')"
          :error="emailError"
          :disabled="userDeleted"
        />

        <OurPhoneInput
          v-model="form.phone"
          :label="$t('label.phoneNumber')"
          :placeholder="$t('placeholder.phone')"
          :flags="false"
          :error="phoneError"
          :disabled="userDeleted"
          dropdown-disabled
        />
      </OurGroup>

      <OurGroup class="main-information" :title="$t('title.users.company')">
        <OurSelect
          v-model="form.roleId"
          :options="roles"
          :label="$t('label.role')"
          :placeholder="$t('placeholder.selectRole')"
          :error="roleError"
          :disabled="userDeleted"
        />
        <OurSelect
          v-show="customerVisibility"
          v-model="form.customerId"
          :options="customers"
          :label="$t('label.customer')"
          :placeholder="$t('placeholder.selectCustomer')"
          :error="customerError"
          :disabled="userDeleted"
        />

        <OurTextarea
          v-model="form.comment"
          :label="$t('label.comment')"
          :placeholder="$t('placeholder.comment')"
          rows="4"
          :disabled="userDeleted"
        />

        <div v-if="editForm && !userDeleted" class="footer-block">
          <OurCheckbox v-model="form.isInactive" :label="$t('label.userInactive')" />
          <OurTag
            v-if="form.status"
            size="md"
            :text="form.status?.text"
            :color="form.status?.color"
          />
        </div>
      </OurGroup>
    </OurGroups>
  </div>
</template>

<script>
import ValidationService from "@/services/validation.service";
import { email, required } from "vuelidate/lib/validators";
import { mapGetters, mapState } from "vuex";

const { phoneMaxLength, phoneMinLength } = ValidationService;

export default {
  name: "UsersForm",

  props: {
    value: {
      type: Object,
      default: () => {},
    },

    editForm: {
      type: Boolean,
      default: false,
    },

    userDeleted: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      form: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        roleId: "",
        customerId: "",
        comment: "",
        isInactive: false,
        status: null,
      },
    };
  },

  validations: {
    form: {
      firstName: { required },
      lastName: { required },
      email: { required, email },
      phone: { required, phoneMinLength, phoneMaxLength },
      roleId: { required },
      customerId: { required },
    },
  },

  computed: {
    ...mapState("dealerUsers", ["roles", "customers"]),

    ...mapGetters("user", ["isUserDealerAdmin", "isUserDealer"]),

    firstNameError() {
      const isDirty = this.$v.form.firstName.$dirty;
      const isFilled = this.$v.form.firstName.required;

      let error = "";

      if (isDirty && !isFilled) {
        error = this.$t("validation.required");
      }

      return error;
    },

    lastNameError() {
      const isDirty = this.$v.form.lastName.$dirty;
      const isFilled = this.$v.form.lastName.required;

      let error = "";

      if (isDirty && !isFilled) {
        error = this.$t("validation.required");
      }

      return error;
    },

    emailError() {
      const isDirty = this.$v.form.email.$dirty;
      const isFilled = this.$v.form.email.required;
      const isEmail = this.$v.form.email.email;

      let error = "";

      if (isDirty && !isFilled) {
        error = this.$t("validation.required");
      } else if (isDirty && !isEmail) {
        error = this.$t("validation.mustBeEmail");
      }

      return error;
    },

    phoneError() {
      const isDirty = this.$v.form.phone.$dirty;
      const isFilled = this.$v.form.phone.required;

      let isMinLength;
      let isMaxLength;

      if ((this.editForm && this.form.phone) || !this.editForm) {
        isMinLength = this.$v.form.phone.phoneMinLength;
        isMaxLength = this.$v.form.phone.phoneMaxLength;
      }

      let error = "";

      if (isDirty) {
        if (!isFilled) error = this.$t("validation.required");
        else if (!isMinLength || !isMaxLength) error = this.$t("validation.phoneLength");
      }

      return error;
    },

    roleError() {
      const isDirty = this.$v.form.roleId.$dirty;
      const isFilled = this.$v.form.roleId.required;

      let error = "";

      if (isDirty && !isFilled) {
        error = this.$t("validation.required");
      }

      return error;
    },

    customerError() {
      const isDirty = this.$v.form.customerId.$dirty;
      const isFilled = this.$v.form.customerId.required;

      let error = "";

      if (isDirty && !isFilled) {
        error = this.$t("validation.required");
      }

      return error;
    },

    customerVisibility() {
      if (this.isUserDealer) {
        return this.editForm;
      }

      return true;
    },
  },

  watch: {
    value: {
      handler: "getForm",
      deep: true,
    },
    customers(customersList) {
      if (this.editForm) return;

      if (this.isUserDealer) {
        this.form.customerId = customersList && customersList[0]?.id;
      }
    },
  },

  mounted() {
    this.getForm();
  },

  methods: {
    getForm() {
      if (this.value) {
        this.form = { ...this.value };
      }
    },

    emitForm() {
      if (this.$v.$invalid) {
        this.$v.$touch();

        return;
      }

      this.form.isValid = true;
      this.$emit("input", this.form);
    },
  },
};
</script>

<style lang="postcss" scoped>
.user-form {
  .footer-block {
    @apply flex justify-between;
    @apply w-full;
    @apply pl-4;
  }
}
</style>

import gql from "graphql-tag";

export const createAccessGroupMutation = gql`
  mutation createAccessGroup(
    $name: String!
    $productCategoryIds: [ID]
    $storageIds: [ID]
    $isActive: Boolean
  ) {
    createAccessGroup(
      name: $name
      productCategoryIds: $productCategoryIds
      storageIds: $storageIds
      isActive: $isActive
    ) {
      id
    }
  }
`;

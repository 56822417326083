<template>
  <div ref="OurTabs" class="tabs" :class="tabsClass" :data-cy="dataCy">
    <slot>
      <OurTab
        v-for="(item, index) in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
        :disabled="item.disabled"
        :size="size"
        :data-cy="`${dataCy}-item-${index}`"
      />
    </slot>
  </div>
</template>

<script>
import OurTab from "@/components/_bit/Tab";

export default {
  name: "OurTabs",

  components: {
    OurTab,
  },

  props: {
    /**
     * Set component value.
     */
    value: {
      type: String,
      default: "",
    },

    /**
     * Set options for tabs.
     */
    options: {
      type: Array,
      default: () => [
        {
          label: "",
          value: "",
          disabled: false,
        },
      ],
    },

    /**
     * Add a line in bottom along the entire length.
     */
    bottomLine: {
      type: Boolean,
      default: false,
    },

    /**
     * The size of the tabs.
     * @values sm, md, lg
     */
    size: {
      type: String,
      default: "md",
    },

    /**
     * Sets data-cy attribute for correct element sampling in tests.
     */
    dataCy: {
      type: String,
      default: "",
    },
  },

  computed: {
    selectedItem: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    tabsClass() {
      return this.bottomLine ? "bottom-line" : "";
    },
  },

  watch: {
    selectedItem: {
      handler: "onChangeSelectedItem",
      deep: true,
    },
  },

  methods: {
    onChangeSelectedItem() {
      const items = this.$refs.OurTabs.__vue__.$children;

      for (let i = 0; i < items.length; i++) {
        items[i].setDefaultValue();
      }
    },
  },
};
</script>

<style lang="postcss" scoped>
.tabs {
  @apply mb-6 flex space-x-4 md:space-x-8;
}

.bottom-line {
  @apply border-b border-gray-100;
}
</style>

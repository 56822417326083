<template>
  <div class="our-row" :data-cy="dataCy">
    <!-- @slot Use it to add something inside. -->
    <slot />
  </div>
</template>

<script>
export default {
  name: "OurRow",

  props: {
    /**
     * Disables mobile adaptivity.
     */
    noMobile: {
      type: Boolean,
      default: false,
    },
    /**
     * Sets data-cy attribute for correct element sampling in tests.
     */
    dataCy: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="postcss" scoped>
.our-row {
  @apply flex w-full flex-wrap gap-4 sm:flex-nowrap;
}
</style>

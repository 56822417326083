import i18n from "@/plugins/vue-i18n";

export default {
  namespaced: true,
  state: {
    status: {
      activated: {
        text: i18n.t("dealerUsers.status.activated"),
        color: "green",
        value: "activated",
      },
      deactivated: {
        text: i18n.t("dealerUsers.status.deactivated"),
        color: "gray",
        value: "deactivated",
      },
    },
    filtersForm: null,
  },

  getters: {
    getStatuses(state) {
      return Object.values(state.status);
    },

    getStatus(state) {
      return (status) => {
        return state.status[status];
      };
    },

    getFiltersOption() {
      return (column, value) => {
        const columns = ["phone", "email"];
        const operator = columns.includes(column) ? "like" : "=";
        const option = {
          method: "where",
          column,
          operator,
          value,
        };

        if (column === "status") {
          option.column = "isActive";
          option.value = value !== "deactivated";
        }

        return option;
      };
    },

    getFiltersOptions(state, getters) {
      return () => {
        const filtersForm = state.filtersForm;
        let filtersOptions = [];

        if (filtersForm) {
          Object.keys(filtersForm).forEach((key) => {
            if (filtersForm[key]) {
              const column = key;
              const value = filtersForm[key];

              filtersOptions.push(getters.getFiltersOption(column, value));
            }
          });
        }

        return filtersOptions;
      };
    },
  },

  mutations: {
    SET_FILTERS_FORM(state, form) {
      state.filtersForm = form;
    },
  },
};

import gql from "graphql-tag";

export const rolesQuery = gql`
  query roles {
    roles {
      id
      name
      code
      parent {
        code
      }
    }
  }
`;
export const customersQuery = gql`
  query allCustomer($filters: Filter) {
    allCustomer(filters: $filters) {
      id
      name
    }
  }
`;

export default {
  logoPath: "static/logos/dark-outline.svg",
  logoLabel: [
    {
      routeNames: ["Login", "ForgotPassword"],
      label: "",
    },
    {
      routeNames: ["AdminLogin", "AdminForgotPassword"],
      label: "admin",
    },
  ],
};

import gql from "graphql-tag";

export const productCategoriesAndStoragesQuery = gql`
  query productCategories {
    productCategories {
      id
      description
      parent
    }
    storages {
      id
      name
    }
  }
`;

import i18n from "@/plugins/vue-i18n";

const Reserved = () => import(/* webpackChunkName: "leads" */ "@/views/reserved/index");

const reserved = [
  {
    path: "/reserved",
    name: "Reserved",
    component: Reserved,
    meta: {
      title: i18n.t("title.reserved.pageTitle"),
    },
  },
];

export default reserved;

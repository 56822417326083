<template>
  <div
    class="tag"
    :class="[themeClass, color, sizeClass]"
    tabindex="1"
    :data-cy="dataCy"
    @focus="onFocus"
    @keydown="onKeydown"
    @blur="onBlur"
    @mousedown="onMousedown"
  >
    <div class="body">
      <div>{{ text }}</div>

      <slot name="right">
        <OurSvgIcon
          v-if="tooltipText"
          :tooltip="tooltipText"
          :data-cy="`${dataCy}-info`"
          :src="icons.InfoCircle"
          size="xs"
          class="icon"
        />
      </slot>
    </div>
  </div>
</template>

<script>
import { getThemeClass } from "@/services/_bit/UiService";

import OurSvgIcon from "@/components/_bit/IconSvg";

export default {
  name: "OurTag",
  components: {
    OurSvgIcon,
  },

  props: {
    /**
     * The color of the tag.
     * @values gray, red, orange, yellow, green, blue, violet, fuchsia
     */
    color: {
      type: String,
      default: "blue",
    },

    /**
     * The size of the tag.
     * @values sm, md, lg
     */
    size: {
      type: String,
      default: "md",
    },

    /**
     * Set tag text.
     */
    text: {
      type: String,
      required: true,
    },

    /**
     * Set tooltip text.
     */
    tooltipText: {
      type: String,
      default: "",
    },

    /**
     * Sets data-cy attribute for correct element sampling in tests.
     */
    dataCy: {
      type: String,
      default: "",
    },
  },

  computed: {
    themeClass() {
      return getThemeClass();
    },

    icons: () => ({
      InfoCircle: require("@/components/_bit/IconSvg/icons/Info-circle.svg"),
    }),

    sizeClass() {
      return `size-${this.size}`;
    },
  },

  methods: {
    onFocus() {
      this.$emit("focus");
    },

    onKeydown() {
      this.$emit("keydown");
    },

    onBlur(event) {
      this.$emit("blur", event);
    },

    onMousedown() {
      this.$emit("mousedown");
    },
  },
};
</script>

<style lang="postcss" scoped>
.tag {
  @apply rounded-full;
  @apply inline-block whitespace-nowrap;

  .body {
    @apply flex items-center;
  }

  .icon {
    @apply ml-1;

    &:deep(g [fill]) {
      @apply fill-current;
    }
  }
}

.size-sm {
  @apply p-0.5 px-2;
  @apply text-2xs;
}

.size-md {
  @apply px-2 py-1;
  @apply text-xs;
}

.size-lg {
  @apply px-3 py-1.5;
  @apply text-sm;
}

.gray {
  @apply bg-gray-100 text-gray-900;
}

.red {
  @apply bg-red-100 text-red-800;
}

.orange {
  @apply bg-orange-100 text-orange-800;
}

.yellow {
  @apply bg-yellow-100 text-yellow-800;
}

.green {
  @apply bg-green-100 text-green-800;
}

.blue {
  @apply bg-blue-100 text-blue-800;
}

.violet {
  @apply bg-violet-100 text-violet-800;
}

.fuchsia {
  @apply bg-fuchsia-100 text-fuchsia-800;
}
</style>

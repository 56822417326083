import gql from "graphql-tag";

export const createArticleMutation = gql`
  mutation createArticle(
    $sectionId: ID!
    $name: String!
    $type: ArticleType!
    $content: String
    $files: [ID!]
    $contentImages: [ID!]
  ) {
    createArticle(
      sectionId: $sectionId
      name: $name
      type: $type
      content: $content
      files: $files
      contentImages: $contentImages
    ) {
      id
    }
  }
`;

export const uploadFilesMutation = gql`
  mutation uploadFiles($files: [Upload!]!) {
    uploadFiles(files: $files) {
      id
      name
      link
    }
  }
`;

export const deleteFileMutation = gql`
  mutation deleteFile($id: ID!) {
    deleteFile(id: $id) {
      id
      name
    }
  }
`;

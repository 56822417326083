<template>
  <OurPage
    class="access-groups-edit"
    :back-route="backRoute"
    :title="$t('title.roles.edit')"
    width="md"
  >
    <DeleteModal
      :show.sync="isShownDeleteModal"
      :role-id="rolesId"
      :role-name="form.name"
      delay-notify
      @deleteRole="goToList"
    />

    <RolesForm ref="rolesForm" v-model="form" :is-edit="true" />

    <template #footer-left>
      <OurButton class="button" :text="$t('button.save')" @click="onClickSaveButton" />
      <OurLink :route="backRoute">
        <OurButton class="button" :text="$t('button.back')" variant="secondary" />
      </OurLink>
    </template>

    <template #footer-right>
      <OurButton
        v-if="getPermission['role.delete']"
        class="button"
        variant="thirdary"
        :text="$t('button.remove')"
        @click="onClickDeleteButton"
      />
    </template>
  </OurPage>
</template>

<script>
import apolloMixin from "@/mixins/apollo.mixin";
import DeleteModal from "@/views/settings/roles/delete/Modal.vue";
import { updateRoleMutation } from "@/views/settings/roles/edit/gql/mutations";
import RolesForm from "@/views/settings/roles/_components/RolesForm.vue";
import { mapGetters } from "vuex";

export default {
  name: "BaseKnowledgeEdit",

  components: {
    RolesForm,
    DeleteModal,
  },

  mixins: [apolloMixin],

  data() {
    return {
      backRoute: {
        name: "Roles",
        title: this.$t("title.roles._"),
      },
      form: {
        name: "",
        permissions: [],
      },
      isShownDeleteModal: false,
    };
  },

  computed: {
    ...mapGetters("user", ["getPermission"]),
    rolesId() {
      return this.$route.params.id;
    },
  },

  methods: {
    async onClickSaveButton() {
      this.$refs.rolesForm.emitForm();
      this.form.id = this.$route.params.id;

      if (this.form.isValid) {
        const { updateRole } = await this.$post(updateRoleMutation, this.form, {
          delaySuccessNotify: true,
        });

        if (updateRole.id) await this.$router.push({ name: "Roles" });
      }
    },

    onClickDeleteButton() {
      this.isShownDeleteModal = true;
      this.$refs.rolesForm.emitForm();
    },

    goToList() {
      this.$router.push({ name: "Roles" });
    },
  },
};
</script>

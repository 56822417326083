import collect from "collect.js";

function queryMethods() {
  return collect([
    {
      name: "where",
    },
    {
      name: "orWhere",
      group: "where",
    },
  ]).map(function (method) {
    if (!Object.prototype.hasOwnProperty.call(method, "group")) {
      method.group = method.name;
    }

    return method;
  });
}

/**
 * @param { Array } params
 * @returns { Object } filters gql object
 */

export function filterBuilder(params) {
  const methods = queryMethods();

  params = collect(params).whereIn(
    // Filter and collect params
    "method",
    methods.pluck("name").all()
  );

  const result = methods.groupBy("group").mapWithKeys(function (method) {
    const methodsParams = params.whereIn("method", method.pluck("name").all());
    const firstParamsMethodName = methodsParams.first().method;
    const treeParams = treeConstructor(methodsParams);

    return [firstParamsMethodName, treeParams];
  });

  return result.all();
}

function treeConstructor(params) {
  const item = params.first();

  params.forget(0);

  if (params.isNotEmpty()) {
    item[params.first().method] = treeConstructor(params);
  }

  delete item.method;

  return item;
}

/**
 * @param { Array } fields
 * @param { Object } filters
 * @returns { Array } filters options array
 */

export function serializeFilters(fields, filters) {
  for (let filter in filters) {
    if (!filters[filter]) {
      delete filters[filter];
    }

    fields.forEach((field) => {
      if (field.key === filter && field?.filter?.mirrorSearchForFields?.length) {
        field.filter.mirrorSearchForFields.forEach((item) => (filters[item] = filters[filter]));
      }
    });
  }

  return Object.entries(filters).map(([column, value]) => {
    let operator;
    let relation;
    let method = "where";
    const mainField = fields.find((field) => field.key === column);

    if (!mainField) {
      fields.forEach((field) => {
        if (field?.filter?.mirrorSearchForFields) {
          const isRelated = field.filter.mirrorSearchForFields.some((item) => item === column);

          if (isRelated) {
            operator = field.filter.operator;
            method = "orWhere";
          }

          if (isRelated && field.filter?.column) {
            const hasRequiredSeparator = field.filter.column.includes(".");

            if (!hasRequiredSeparator) {
              throw new Error("Missing required separator [.] in filter.column !!!");
            }

            const columnData = field.filter.column.split(".");
            const columnName = columnData.pop();

            relation = column;
            column = columnName;
          }
        }
      });
    } else {
      operator = mainField.filter?.operator;

      if (!operator) throw new Error("No required field for filtering [operator]!");

      if (mainField.filter?.column) {
        const hasRequiredSeparator = mainField.filter.column.includes(".");

        if (!hasRequiredSeparator) {
          throw new Error("Missing required separator [.] in filter.column !!!");
        }

        const columnData = mainField.filter.column.split(".");
        const columnName = columnData.pop();

        relation = columnData.join(".");
        column = columnName;
      }
    }

    switch (operator) {
      case "beforeLike":
        operator = "like";
        value = `${value}%`;
        break;

      case "like":
        operator = "like";
        value = `%${value}%`;
        break;

      case "afterLike":
        operator = "like";
        value = `%${value}`;
        break;
    }

    const filterData = {
      method,
      column,
      operator,
      value,
    };

    if (relation) filterData.relation = relation;

    return filterData;
  });
}

import gql from "graphql-tag";

export const permissionsQuery = gql`
  query role($id: ID!) {
    role(id: $id) {
      name
      permissions {
        id
        name
        group
        isActive
      }
    }
  }
`;

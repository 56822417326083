import I18nServiceDefault from "@/services/_bit/I18nService";

class I18nService extends I18nServiceDefault {
  /**
   * Get the current active language code for DatoCMS
   */
  getActiveLanguageCode() {
    const languages = {
      ua: "uk",
      ru: "ru",
    };

    return languages[this.getActiveLanguage()];
  }
}

export default new I18nService();

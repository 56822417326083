<template>
  <div ref="OurTab" class="tab" :class="tabClasses" :data-cy="dataCy" @click="onClickSetValue">
    <!-- @slot Use it to add something instead of label. -->
    <slot>
      {{ label }}
    </slot>
  </div>
</template>

<script>
export default {
  name: "OurTab",

  props: {
    /**
     * Set label.
     * @ignore
     */
    label: {
      type: String,
      required: true,
    },

    /**
     * Set component value.
     */
    value: {
      type: String,
      default: "",
    },

    /**
     * Make tab inactive.
     * @ignore
     */
    disabled: {
      type: Boolean,
      default: false,
    },

    /**
     * Sets data-cy attribute for correct element sampling in tests.
     */
    dataCy: {
      type: String,
      default: "",
    },
  },

  data: () => ({
    size: "md",
    tabs: {},
  }),

  computed: {
    selectedItem: {
      get() {
        const selectedItem = this.tabs.selectedItem;

        if (selectedItem && selectedItem !== this.value) {
          return;
        }

        return this.value;
      },
      set(value) {
        if (this.tabs.selectedItem) {
          this.tabs.selectedItem = value;
        }
      },
    },

    tabClasses() {
      const size = `size-${this.size}`;
      const selected = this.selectedItem === this.value && !this.disabled ? "tab-selected" : "";
      const disabled = this.disabled ? "tab-disabled" : "";

      return [size, selected, disabled];
    },
  },

  mounted() {
    this.tabs = this.$refs.OurTab.__vue__.$parent;
    this.size = this.tabs.size;
  },

  methods: {
    setValue() {
      if (!this.disabled) {
        this.selectedItem = this.value;
      }
    },

    setDefaultValue() {
      if (this.tabs.selectedItem !== this.value) {
        return;
      }

      this.selectedItem = this.value;
    },

    onClickSetValue() {
      this.setValue();
    },
  },
};
</script>

<style lang="postcss" scoped>
.tab {
  @apply w-max;
  @apply pb-2;
  @apply text-center;
  @apply cursor-pointer;

  &-selected {
    @apply border-b-2 border-gray-900;
  }

  &-disabled {
    @apply text-gray-500;
  }
}

.size {
  &-sm {
    @apply text-xs;
  }

  &-md {
    @apply text-sm;
  }

  &-lg {
    @apply text-sm md:text-base;
  }
}
</style>

import gql from "graphql-tag";

export const noticeEmailsQuery = gql`
  query noticeEmails {
    noticeEmails {
      id
      email
    }
  }
`;

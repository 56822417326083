import dealerUsers from "@/router/layouts/mainLayout/settings/dealer-users";
import accessGroups from "@/router/layouts/mainLayout/settings/access-groups";
import users from "@/router/layouts/mainLayout/settings/users";
import dealerCompany from "@/router/layouts/mainLayout/settings/dealer-company";
import baseKnowledge from "@/router/layouts/mainLayout/settings/base-knowledge";
import roles from "@/router/layouts/mainLayout/settings/roles";
import newNotices from "@/router/layouts/mainLayout/settings/new-notices";
import customers from "@/router/layouts/mainLayout/settings/customers";

const settings = [
  ...dealerUsers,
  ...accessGroups,
  ...users,
  ...dealerCompany,
  ...baseKnowledge,
  ...roles,
  ...newNotices,
  ...customers,
];

export default settings;

<template>
  <div class="users-list">
    <template v-if="isMobileDevice">
      <div v-if="items.length">
        <CustomersCard
          v-for="(item, index) of items"
          :key="index"
          :item="item"
          @clickOnOrder="onClickRow({ id: item.id })"
        />
      </div>
      <div v-else>{{ $t("page.dashboard.emptyMessage") }}</div>
    </template>

    <OurTable
      v-else
      :headers="tableHeaders"
      :items="items"
      :table-fixed="false"
      class="users-list-table"
      @clickRow="onClickRow"
    >
      <template #cell-companies="{ value }">
        <div class="company-block">
          <div v-if="value.length">
            <div v-for="val in value" :key="val.id" class="text">{{ val.name }}<span>,</span></div>
          </div>
          <div v-else class="text">{{ "-" }}</div>
        </div>
      </template>

      <template #cell-dealers="{ value }">
        <div class="company-block">
          <div v-if="value.length" class="title">
            <div v-for="val in value" :key="val.id" class="text">
              {{ val.firstName }} {{ val.lastName }}<span>,</span>
            </div>
          </div>
          <div v-else class="text">{{ "-" }}</div>
        </div>
      </template>

      <template #cell-status="{ value }">
        <OurTag size="md" :text="value?.text" :color="value?.color" />
      </template>
    </OurTable>

    <OurPagination
      v-if="isMoreThanOnePage"
      :current-page="paginationData.currentPage"
      :per-page="paginationData.perPage"
      :total="paginationData.total"
      @pageChange="onPageChange"
    />
  </div>
</template>

<script>
import CustomersCard from "@/views/settings/customers/index/_components/CustomersCard.vue";
import { mapGetters } from "vuex";

export default {
  name: "CustomersList",
  components: { CustomersCard },

  props: {
    items: {
      type: Array,
      default: () => [],
    },

    paginationData: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    ...mapGetters("breakpoint", ["isMobileDevice"]),
    tableHeaders() {
      const data = [
        {
          value: "id",
          text: this.$t("page.users.id"),
          thClass: "w-24",
          tdClass: "max-w-[2rem] min-w-[5rem]",
        },
        {
          value: "name",
          text: this.$t("page.customers.title"),
          thClass: "w-[10rem]",
          tdClass: "max-w-[12rem] min-w-[10rem]",
        },
        {
          value: "companies",
          text: this.$t("page.customers.companies"),
          thClass: "w-[5rem]",
          tdClass: "max-w-[10rem] min-w-[10rem]",
        },
        {
          value: "accessGroup",
          text: this.$t("page.customers.accessGroup"),
          thClass: "w-[10rem]",
          tdClass: "max-w-[12rem] min-w-[10rem]",
        },
        {
          value: "dealers",
          text: this.$t("page.customers.users"),
          thClass: "w-[8rem]",
          tdClass: "max-w-[20rem] min-w-[20rem]",
        },
        {
          value: "status",
          text: this.$t("page.dealersCompany.status"),
          thClass: "w-[7rem]",
          tdClass: "max-w-[9rem] min-w-[7rem]",
        },
      ];

      return data;
    },

    isMoreThanOnePage() {
      return this.paginationData.lastPage > 1;
    },
  },

  methods: {
    onPageChange(page) {
      this.$emit("pageChange", page);
    },

    onClickRow(row) {
      this.$emit("onClickRow", row.id);
    },
  },
};
</script>

<style lang="postcss" scoped>
.users-list {
  .company-block title {
    @apply flex flex-wrap gap-x-2 gap-y-1;
  }
}
.text:last-child span {
  display: none;
}
</style>

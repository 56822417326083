<template>
  <OurPage :back-route="backRoute" :title="$t('title.knowledgeBase.add')" width="md">
    <BaseKnowledgeForm ref="baseKnowledgeForm" v-model="form" />

    <template #footer-left>
      <OurButton class="button" :text="$t('button.add')" @click="onClickSaveButton" />
      <OurLink :route="backRoute">
        <OurButton class="button" :text="$t('button.back')" variant="secondary" />
      </OurLink>
    </template>
  </OurPage>
</template>

<script>
import apolloMixin from "@/mixins/apollo.mixin";
import BaseKnowledgeForm from "@/views/settings/base-knowledge/_components/BaseKnowledgeForm.vue";
import { createKnowledgeBaseSectionMutation } from "@/views/settings/base-knowledge/add/gql/mutations";

export default {
  name: "BaseKnowledgeAdd",

  components: {
    BaseKnowledgeForm,
  },

  mixins: [apolloMixin],

  data() {
    return {
      backRoute: {
        name: "BaseKnowledge",
        title: this.$t("title.knowledgeBase.chapters"),
      },
      form: {
        name: "",
      },
    };
  },

  methods: {
    async onClickSaveButton() {
      this.$refs.baseKnowledgeForm.emitForm();

      if (this.form.isValid) {
        const { createKnowledgeBaseSection } = await this.$post(
          createKnowledgeBaseSectionMutation,
          this.form,
          {
            delaySuccessNotify: true,
          }
        );

        if (createKnowledgeBaseSection.id) await this.$router.push({ name: "BaseKnowledge" });
      }
    },
  },
};
</script>

import i18n from "@/plugins/vue-i18n";

import DealerCompany from "@/views/settings/dealer-company/index";
import DealerCompanyAdd from "@/views/settings/dealer-company/add";
import DealerCompanyEdit from "@/views/settings/dealer-company/edit";

const dealerCompany = [
  {
    path: "settings/dealer-company",
    name: "DealerCompany",
    component: DealerCompany,
    meta: {
      title: i18n.t("title.dealersCompany._"),
    },
  },
  {
    path: "settings/dealer-company/add",
    name: "DealerCompanyAdd",
    component: DealerCompanyAdd,
    meta: {
      title: i18n.t("title.dealersCompany.add"),
    },
  },
  {
    path: "settings/dealer-company/:id/edit",
    name: "DealerCompanyEdit",
    component: DealerCompanyEdit,
    meta: {
      title: i18n.t("title.dealersCompany.edit"),
    },
  },
];

export default dealerCompany;

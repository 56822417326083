<template>
  <OurPage
    :title="notice.name"
    :back-route="backRoute"
    gray
    class="flea-market-details"
    width="2xl"
  >
    <template #header-right>
      <OurLink v-if="isEditButton && havePermissionEdit" :route="editRoute">
        <OurButton
          :text="editButtonText"
          :variant="editButtonVariant"
          :filled="isMobileDevice"
          :size="editButtonSize"
        >
          <OurSvgIcon v-if="isMobileDevice" :src="icons.EditSquare" />
        </OurButton>
      </OurLink>
    </template>

    <template v-if="!isMobileDevice">
      <OurCard>
        <div class="main-card mb-10">
          <div class="slider-wrapper">
            <ImageSlider :title="notice.name" :images="notice.files" />
          </div>
          <div class="content">
            <div v-if="isLocationStatusInformation" class="location-status-information">
              <div v-if="notice.location" class="location">
                <OurSvgIcon :src="icons.Marker1" color="gray" />
                <div>{{ notice.location }}</div>
              </div>

              <div v-else />

              <div>
                <OurTag
                  v-if="isOwn && notice.status"
                  class="status me-2"
                  :text="notice.status.text"
                  :color="notice.status.color"
                />

                <OurTag
                  v-if="isOwn && notice.modStatus"
                  class="status"
                  :text="notice.modStatus.text"
                  :color="notice.modStatus.color"
                />
              </div>
            </div>

            <div v-if="isLocationStatusInformation" class="line" />

            <div class="main-information">
              <div class="information-line" />

              <div class="information-block">
                <OurTextField :label="$t('page.fleaMarket.pricePiece')">
                  <OurMoney
                    class="price"
                    size="2xl"
                    align="left"
                    weight="bold"
                    :sum="notice.price?.sum"
                    :currency-symbol="notice.price?.currencySymbol"
                  />
                </OurTextField>

                <OurTextField :label="$t('page.fleaMarket.quantity')" :value="quantityValue" />

                <OurTextField
                  :label="$t('page.fleaMarket.productCondition._')"
                  :value="notice.productConditionText"
                />
              </div>

              <div class="information-block">
                <OurTextField :label="$t('page.fleaMarket.priceGeneral')">
                  <OurMoney
                    class="price"
                    size="2xl"
                    align="left"
                    weight="bold"
                    :sum="priceGeneral?.sum"
                    :currency-symbol="priceGeneral?.currencySymbol"
                  />
                </OurTextField>

                <OurTextField :label="$t('label.category')" :value="category" />

                <OurRatingInput
                  class="condition"
                  :value="notice.condition"
                  :label="$t('page.fleaMarket.productCondition.assessment')"
                  size="lg"
                  no-counter
                />
              </div>
            </div>

            <div class="line" />

            <OurTextField class="contact-information" :label="$t('label.contacts')">
              <div class="name">{{ notice.contactName }}</div>
              <div class="phone">{{ notice.contactPhone }}</div>
            </OurTextField>

            <OurTextField
              class="description"
              :label="$t('label.description')"
              :value="notice.description"
            />
          </div>
        </div>
        <div v-if="havePermission" class="flex items-center justify-end">
          <OurButton
            class="me-5"
            :text="$t('button.reject')"
            :variant="editButtonVariant"
            :filled="isMobileDevice"
            :size="editButtonSize"
            @click="moderateNotice('rejected')"
          >
            <OurSvgIcon v-if="isMobileDevice" :src="icons.EditSquare" />
          </OurButton>
          <OurButton
            :text="$t('button.confirm')"
            :variant="editButtonVariant"
            :filled="isMobileDevice"
            :size="editButtonSize"
            @click="moderateNotice('confirmed')"
          >
            <OurSvgIcon v-if="isMobileDevice" :src="icons.EditSquare" />
          </OurButton>
        </div>
      </OurCard>
    </template>

    <template v-else>
      <OurCard>
        <div class="mobile-card">
          <div class="slider-wrapper">
            <ImageSlider :title="notice.name" :images="notice.files" />
          </div>

          <div v-if="isLocationStatusInformation" class="location-status-information">
            <div v-if="notice.location" class="location">
              <OurSvgIcon :src="icons.Marker1" color="gray" />
              <div>{{ notice.location }}</div>
            </div>

            <div v-else />

            <div>
              <OurTag
                v-if="isOwn && notice.status"
                class="status me-2"
                :text="notice.status.text"
                :color="notice.status.color"
              />

              <OurTag
                v-if="isOwn && notice.modStatus"
                class="status"
                :text="notice.modStatus.text"
                :color="notice.modStatus.color"
              />
            </div>
          </div>

          <div v-if="isLocationStatusInformation" class="line" />

          <div class="main-information">
            <div class="information-line" />

            <div class="information-block">
              <OurTextField :label="$t('page.fleaMarket.pricePiece')">
                <OurMoney
                  class="price"
                  size="2xl"
                  align="left"
                  weight="bold"
                  :sum="notice.price?.sum"
                  :currency-symbol="notice.price?.currencySymbol"
                />
              </OurTextField>

              <OurTextField
                :label="$t('page.fleaMarket.productCondition._')"
                :value="notice.productConditionText"
              />
            </div>
            <div class="information-block">
              <OurTextField :label="$t('page.fleaMarket.priceGeneral')">
                <OurMoney
                  class="price"
                  size="2xl"
                  align="left"
                  weight="bold"
                  :sum="priceGeneral?.sum"
                  :currency-symbol="priceGeneral?.currencySymbol"
                />
              </OurTextField>

              <OurRatingInput
                class="condition"
                :value="notice.condition"
                :label="$t('page.fleaMarket.productCondition.assessment')"
                size="lg"
                no-counter
              />
            </div>
          </div>

          <div class="line" />

          <div class="quantity-category-information">
            <OurTextField :label="$t('page.fleaMarket.quantity')" :value="quantityValue" />

            <div class="line" />

            <OurTextField :label="$t('label.category')" :value="category" />
          </div>

          <div class="line" />

          <div class="contact-description-information">
            <OurTextField class="contact-information" :label="$t('label.contacts')">
              <div class="name">{{ notice.contactName }}</div>
              <div class="phone">{{ notice.contactPhone }}</div>
            </OurTextField>

            <OurTextField
              class="description"
              :label="$t('label.description')"
              :value="notice.description"
            />
          </div>
          <div v-if="havePermission" class="mt-5 flex items-center justify-end">
            <OurButton
              class="me-5"
              :text="$t('button.reject')"
              :filled="isMobileDevice"
              :size="editButtonSize"
              @click="moderateNotice('rejected')"
            >
            </OurButton>
            <OurButton
              :text="$t('button.confirm')"
              :filled="isMobileDevice"
              :size="editButtonSize"
              @click="moderateNotice('confirmed')"
            >
            </OurButton>
          </div>
        </div>
      </OurCard>
    </template>
  </OurPage>
</template>

<script>
import { mapGetters } from "vuex";
import apolloMixin from "@/mixins/apollo.mixin";
import { noticeAndCategoriesQuery } from "@/views/flea-market/details/gql/queries";
import { DEFAULT_IMAGE } from "@/api/files";

import ImageSlider from "@/components/ImageSlider";
import { updateNoticeModerationStatusMutation } from "@/views/settings/new-notices/details/gql/mutations";

export default {
  name: "NewNoticesDetails",

  components: {
    ImageSlider,
  },

  mixins: [apolloMixin],

  data() {
    return {
      notice: [],
      categoriesList: [],
      categories: [],
      isOwn: false,
      maxCondition: 5,
      backRoute: {
        name: "NewNotices",
        title: this.$t("title.fleaMarket.allNotices"),
      },
    };
  },

  computed: {
    ...mapGetters("user", ["isUserDealer", "getPermission"]),
    ...mapGetters("fleaMarket", ["getStatus", "getModerationStatus"]),
    ...mapGetters("currencies", ["getCode"]),
    ...mapGetters("breakpoint", ["isMobileDevice"]),

    editRoute() {
      if (!this.noticeId) return;

      return { name: "NewNoticesEdit", params: { id: this.noticeId } };
    },

    icons: () => ({
      EditSquare: require("@material-symbols/svg-400/outlined/edit_square.svg"),
      Marker1: require("@/components/_bit/IconSvg/icons/Marker1.svg"),
    }),

    isEditButton() {
      return this.isOwn || !this.isUserDealer;
    },

    editButtonText() {
      return this.isMobileDevice ? "" : this.$t("button.edit");
    },

    editButtonVariant() {
      return this.isMobileDevice ? "thirdary" : "primary";
    },

    editButtonSize() {
      return this.isMobileDevice ? "sm" : "md";
    },

    noticeId() {
      return this.$route.params.id;
    },

    isLocationStatusInformation() {
      return this.notice.location || (this.isOwn && this.notice.status);
    },

    priceGeneral() {
      const sum = this.notice.price?.sum * this.notice.quantity;
      const currencySymbol = this.notice.price?.currencySymbol;

      return {
        sum,
        currencySymbol,
      };
    },

    quantityValue() {
      return `${this.notice.quantity} ${this.$t("page.fleaMarket.pieces")}`;
    },

    category() {
      if (!this.notice.category?.id) return;

      return this.categories.find((category) => category.id === this.notice.category.id)?.label;
    },

    havePermission() {
      return this.getPermission["notice.update_moderation_status"];
    },

    havePermissionEdit() {
      return this.isUserDealer
        ? this.getPermission["notice.update_own"]
        : this.getPermission["notice.update"];
    },
  },

  watch: {
    categoriesList: {
      handler: "getCategories",
      immediate: true,
      deep: true,
    },
  },

  created() {
    this.getData(this.noticeId);
  },

  methods: {
    async getData(id) {
      const { notice, productCategories } = await this.$get(noticeAndCategoriesQuery, { id });

      if (notice && productCategories) {
        const {
          name,
          description,
          category,
          currency,
          price,
          quantity,
          location,
          isNew,
          condition,
          contactName,
          contactPhone,
          isActive,
          moderationStatus,
          isOwn,
          files,
        } = notice;

        this.categoriesList = productCategories;

        const noticeFiles = files.length ? files : [{ link: DEFAULT_IMAGE }];

        const productConditionText = isNew
          ? this.$t("page.fleaMarket.productCondition.new")
          : this.$t("page.fleaMarket.productCondition.used");
        const productCondition = isNew ? this.maxCondition : condition;

        const status = this.getStatus(isActive);
        const modStatus = this.getModerationStatus(moderationStatus);

        this.isOwn = isOwn;
        this.notice = {
          name,
          description,
          category,
          price: {
            sum: price,
            currencySymbol: this.getCode(currency.code),
          },
          quantity,
          location,
          isNew,
          productConditionText,
          condition: productCondition,
          contactName,
          contactPhone,
          status,
          modStatus,
          files: noticeFiles,
        };
      }
    },

    getCategories() {
      this.categories = this.categoriesList
        .filter(({ parent }) => parent === null)
        .map((category) => {
          const { id, description: label } = category;

          return {
            id,
            label,
          };
        });
    },

    async moderateNotice(status) {
      const { updateNoticeModerationStatus } = await this.$post(
        updateNoticeModerationStatusMutation,
        { id: this.noticeId, moderationStatus: status },
        {
          delaySuccessNotify: true,
        }
      );

      if (updateNoticeModerationStatus.id) await this.$router.push({ name: this.backRoute.name });
    },
  },
};
</script>

<style lang="postcss" scoped>
.flea-market-details {
  .main-card {
    @apply flex items-start space-x-8;

    .slider-wrapper {
      @apply w-[15.625rem];
    }

    .content {
      @apply w-full;
      @apply mt-0 pr-0;
      @apply relative;

      .line {
        @apply h-px w-auto;
        @apply bg-gray-100;
        @apply mx-auto mb-4;
      }

      .location-status-information {
        @apply w-full;
        @apply flex items-start justify-between;
        @apply mb-[1.125rem];

        .location {
          @apply flex items-center space-x-1;
          @apply text-sm;
        }
      }

      .main-information {
        @apply flex items-start;
        @apply mb-[1.125rem];
        @apply relative;

        .information-line {
          @apply absolute top-[3.7rem];
          @apply h-px w-full;
          @apply bg-gray-100;
        }

        .information-block {
          @apply flex flex-col space-y-[1.125rem];

          .price:deep {
            @apply mb-3;

            .sum {
              .penny {
                @apply text-lg;
              }

              .currency-symbol {
                @apply text-lg;
              }
            }
          }

          .condition:deep {
            .label-title {
              @apply mb-1;
            }
          }
        }

        .information-block + .information-block {
          @apply ml-14;
        }
      }

      .contact-information {
        @apply mb-4;

        .name {
          @apply text-gray-700;
          @apply mb-1.5;
        }

        .phone {
          @apply font-bold;
        }
      }
    }
  }

  .mobile-card {
    @apply flex flex-col;

    .line {
      @apply h-px w-full;
      @apply bg-gray-100;
      @apply mx-auto my-4;
    }

    .slider-wrapper {
      @apply flex items-center justify-center;
      @apply mb-4;
    }

    .location-status-information {
      @apply flex items-start justify-between;
      @apply h-6 w-full;

      .location {
        @apply flex items-center space-x-1;
        @apply text-sm;
      }
    }

    .main-information {
      @apply flex flex-row;
      @apply m-0 p-0;
      @apply relative;

      .information-line {
        @apply absolute top-[3rem];
        @apply h-px w-full;
        @apply bg-gray-100;
        @apply mx-auto my-4;
      }

      .information-block {
        @apply flex flex-col space-y-8;

        .condition:deep {
          .label-title {
            @apply mb-1;
          }
        }

        .price:deep {
          .sum {
            .penny {
              @apply text-2xl;
            }

            .currency-symbol {
              @apply text-lg;
            }
          }
        }
      }

      .information-block + .information-block {
        @apply ml-10;
      }
    }

    .quantity-category-information,
    .contact-description-information {
      @apply flex flex-col space-y-4;
      @apply mt-0 !important;
    }

    .contact-description-information {
      .contact-information {
        .name {
          @apply mb-1.5;
        }

        .phone {
          @apply font-bold;
        }
      }
    }
  }
}
</style>

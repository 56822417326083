import gql from "graphql-tag";

export const rolesQuery = gql`
  query roles {
    roles {
      id
      name
      code
      parent {
        code
      }
    }
  }
`;

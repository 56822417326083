import i18n from "@/plugins/vue-i18n";

const KnowledgeBase = () =>
  import(/* webpackChunkName: "base-knowledge" */ "@/views/knowledge-base/index");

const KnowledgeBaseDetails = () =>
  import(/* webpackChunkName: "base-knowledge" */ "@/views/knowledge-base/details");

const knowledgeBase = [
  {
    path: "/knowledge-base",
    name: "KnowledgeBase",
    component: KnowledgeBase,
    meta: {
      title: i18n.t("title.knowledgeBase._"),
    },
  },
  {
    path: "/knowledge-base/:id",
    name: "KnowledgeBaseDetails",
    component: KnowledgeBaseDetails,
    meta: {
      title: i18n.t("title.knowledgeBase.details"),
    },
  },
];

export default knowledgeBase;

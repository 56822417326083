<template>
  <div class="slider">
    <div class="slider-header" :data-cy="dataCy">
      <div class="slider-title">
        {{ title }}
      </div>

      <div v-if="!isEnd || !isBeginning" class="slider-controls">
        <OurSvgIcon
          :src="icons.Left2"
          :color="getColor('prev')"
          size="lg"
          :variant="getVariant('prev')"
          :interactive="!isBeginning"
          :data-cy="`${dataCy}-prev`"
          @click="onClickPrev"
        />
        <OurSvgIcon
          :src="icons.Right2"
          :color="getColor('next')"
          size="lg"
          :variant="getVariant('next')"
          :interactive="!isEnd"
          :data-cy="`${dataCy}-next`"
          @click="onClickNext"
        />
      </div>
    </div>

    <swiper
      ref="slider"
      :slides-per-view="slidesPerView"
      :space-between="spaceBetween"
      :breakpoints="sliderBreakpoints"
      class="slider-content"
    >
      <swiper-slide v-for="(item, index) in items" :key="index" class="slider-item">
        <slot :item="item" :data-cy="`${dataCy}-item-${index}`">
          <div class="image">
            <img :alt="item.name" :src="item.image" />
          </div>
        </slot>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper-vue2";
import "swiper/css";

import OurButton from "@/components/_bit/Button";
import OurMoney from "@/components/_bit/TextMoney";
import OurSvgIcon from "@/components/_bit/IconSvg";

export default {
  name: "OurSlider",

  components: {
    Swiper,
    SwiperSlide,
    OurMoney,
    OurButton,
    OurSvgIcon
  },

  props: {
    /**
     * Set title.
     */
    title: {
      type: String,
      default: ""
    },

    /**
     * Set slides data.
     */
    items: {
      type: Array,
      required: true
    },

    /**
     * Set number of visible slides.
     */
    slidesPerView: {
      type: Number,
      default: 4
    },

    /**
     * Set margin between slide.
     */
    spaceBetween: {
      type: Number,
      default: 24,
    },

    /**
     * Sets data-cy attribute for correct element sampling in tests.
     */
    dataCy: {
      type: String,
      default: "",
    },
    sliderBreakpoints: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      swiper: {},
      show: false,
    };
  },

  watch: {
    items() {
      this.$nextTick(() => {
        this.swiper.update();
      });
    },
  },

  computed: {
    icons: () => ({
      Left2: require("@/components/_bit/IconSvg/icons/Left-2.svg"),
      Right2: require("@/components/_bit/IconSvg/icons/Right-2.svg")
    }),

    isBeginning() {
      return this.swiper.isBeginning;
    },

    isEnd() {
      return this.swiper.isEnd;
    },
  },

  mounted() {
    this.swiper = this.$refs.slider.$el.swiper;
  },

  methods: {
    getColor(control) {
      if (control === "prev") return this.isBeginning ? "gray" : "";
      else return this.isEnd ? "gray" : "";
    },

    getVariant(control) {
      if (control === "prev") return this.isBeginning ? "light" : "";
      else return this.isEnd ? "light" : "";
    },

    onClickPrev() {
      this.swiper.slidePrev();
    },

    onClickNext() {
      this.swiper.slideNext();
    },
  },
};
</script>

<style lang="postcss" scoped>
.slider {
  @apply overflow-hidden;

  .slider-header {
    @apply mb-7 flex justify-between;

    .slider-title {
      @apply text-2xl font-bold;
    }

    .slider-controls {
      @apply space-x-7;
    }
  }

  .slider-content {
    .slider-item {
      @apply rounded-lg border border-solid border-gray-100;
      @apply px-4 pb-4;

      .image {
        @apply flex justify-center;
        @apply p-2;
      }
    }
  }
}
</style>

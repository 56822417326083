<template>
  <OurModal v-model="isShownModal" :title="$t('title.users.add')" mobile-footer-reverse>
    <UsersForm ref="userForm" v-model="form" class="user-form" :company-disabled="true" />
    <template #footer-left>
      <OurButton :text="$t('button.add')" @click="onClickApplyButton" />
      <OurButton :text="$t('button.back')" variant="secondary" @click="onClickCloseButton" />
    </template>
  </OurModal>
</template>

<script>
import UsersForm from "@/views/settings/dealer-users/_components/UserForm.vue";
import apolloMixin from "@/mixins/apollo.mixin";
import { mapMutations } from "vuex";
import { rolesQuery } from "@/views/settings/dealer-users/add/gql/queries";
import DataService from "@/services/data.service";
import { createDealerMutation } from "@/views/settings/dealer-users/add/gql/mutations";

export default {
  name: "AddDealersModal",
  components: { UsersForm },
  mixins: [apolloMixin],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        roleId: "",
        customerId: this.$route.params.id,
        comment: "",
      },
    };
  },
  computed: {
    isShownModal: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  created() {
    this.getData();
  },
  methods: {
    ...mapMutations("dealerUsers", ["SET_ROLES", "SET_COMPANIES"]),

    closeModal() {
      this.isShownModal = false;
    },

    cleanForm() {
      this.form = {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        roleId: "",
        customerId: this.$route.params.id,
        comment: "",
      };
    },

    onClickCloseButton() {
      this.closeModal();
    },

    async getData() {
      const { roles } = await this.$get(rolesQuery);

      this.SET_ROLES(roles);
    },

    async onClickApplyButton() {
      this.$refs.userForm.emitForm();

      if (this.form.isValid) {
        const form = { ...this.form, phone: DataService.getPreparedPhone(this.form.phone) };

        const { createDealer } = await this.$post(createDealerMutation, form);

        if (createDealer.id) {
          this.$emit("updateDealers");
          this.cleanForm();
          this.closeModal();
        }
      }
    },
  },
};
</script>

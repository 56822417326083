<template>
  <OurModal v-model="isShownModal" width="xl" :title="$t('title.users._')" mobile-footer-reverse>
    <UsersList :items="dealers" :pagination-data="{}" :headers="tableHeaders" />
    <template #footer-left>
      <OurButton :text="$t('button.add')" @click="onClickApplyButton" />
      <OurButton :text="$t('button.back')" variant="secondary" @click="onClickCloseButton" />
    </template>
  </OurModal>
</template>

<script>
import UsersList from "@/views/settings/dealer-users/index/_components/UsersList.vue";

export default {
  name: "DealersModal",
  components: { UsersList },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    dealers: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    isShownModal: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    tableHeaders() {
      const data = [
        {
          value: "id",
          text: this.$t("page.users.id"),
          thClass: "w-24",
          tdClass: "max-w-[2rem] min-w-[5rem]",
        },
        {
          value: "user",
          text: this.$t("page.users.user"),
          thClass: "w-[5rem]",
          tdClass: "max-w-[10rem] min-w-[10rem]",
        },
        {
          value: "contacts",
          text: this.$t("page.users.contacts"),
          thClass: "w-[10rem]",
          tdClass: "max-w-[12rem] min-w-[10rem]",
        },
        {
          value: "status",
          text: this.$t("page.fleaMarket.status"),
          thClass: "w-[7rem]",
          tdClass: "max-w-[9rem] min-w-[7rem]",
        },
      ];

      return data;
    },
  },
  methods: {
    closeModal() {
      this.isShownModal = false;
    },

    onClickCloseButton() {
      this.closeModal();
    },

    onClickApplyButton() {
      this.closeModal();
      this.$emit("addDealer");
    },
  },
};
</script>

<style lang="postcss">
.our-modal-body .users-list .table-wrapper .table-wrap .our-table tbody,
.our-modal-body .users-list .table-wrapper .table-wrap .our-table thead th {
  background-color: #f9fafb;
}
</style>

import store from "@/store";
import NotifyService from "@/services/notify.service";
import { layout } from "@/configs/ourApp.config";

export default async (to, from, next) => {
  const authPages = ["Login", "AdminLogin", "ForgotPassword", "AdminForgotPassword"];
  const isAuthPage = authPages.includes(to.name);
  const authTokenInQuery = to.query?.auth;
  const { isAuthenticatedUser } = store.getters;
  const { user } = store.state.user;
  const { isProduction, redirectLink } = layout.adminMono;

  if (authTokenInQuery) {
    await store.dispatch("loginByToken", authTokenInQuery);
  }

  NotifyService.getDelayed();

  if (isAuthPage) {
    if (isProduction) {
      if (isAuthenticatedUser) {
        next({ name: "MainLayout" });
      } else if (!authTokenInQuery) {
        next((window.location.href = redirectLink));
      }
    } else {
      if (isAuthenticatedUser) next({ name: "MainLayout" });
    }
  } else {
    await store.dispatch("verifyAuth");

    if (isAuthenticatedUser && !user) {
      await store.dispatch("user/getProfile");
    }
  }

  next();
};

import i18n from "@/plugins/vue-i18n";

const DEALER = "dealer";

export default {
  namespaced: true,
  state: {
    status: {
      activated: {
        text: i18n.t("dealerUsers.status.activated"),
        color: "green",
        value: "activated",
      },
      deactivated: {
        text: i18n.t("dealerUsers.status.deactivated"),
        color: "gray",
        value: "deactivated",
      },
      invited: {
        text: i18n.t("dealerUsers.status.invited"),
        color: "yellow",
        value: "invited",
      },
      request: {
        text: i18n.t("dealerUsers.status.request"),
        color: "blue",
        value: "request",
      },
      deleted: {
        text: i18n.t("dealerUsers.status.deleted"),
        color: "red",
        value: "deleted",
      },
    },
    visit: {
      just: i18n.t(`page.users.lastActivity.just`),
      online: i18n.t(`page.users.lastActivity.online`),
      notEnter: i18n.t(`page.users.lastActivity.notEnter`),
    },
    roles: [],
    companies: [],
    customers: [],
    filtersForm: null,
  },

  getters: {
    getStatuses(state) {
      return (isUserDealerAdmin) => {
        let status = state.status;

        if (isUserDealerAdmin) {
          delete status.deleted;
        }

        return Object.values(state.status);
      };
    },

    getStatus(state) {
      return (status) => {
        return state.status[status];
      };
    },

    getRoleLocalization() {
      return (code) => {
        return i18n.t(`dealerUsers.roles.${code}`);
      };
    },

    getFiltersOption() {
      return (column, value) => {
        const columns = ["phone", "email"];
        const operator = columns.includes(column) ? "like" : "=";
        const option = {
          method: "where",
          column,
          operator,
          value,
        };

        if (column === "customerId") option.relation = "dealer";

        if (column === "roleId") {
          option.relation = "roles";
          option.column = "id";
        }

        return option;
      };
    },

    getFiltersOptions(state, getters) {
      return () => {
        const filtersForm = state.filtersForm;
        let filtersOptions = [];

        if (filtersForm) {
          Object.keys(filtersForm).forEach((key) => {
            if (filtersForm[key]) {
              const column = key;
              const value = filtersForm[key];

              filtersOptions.push(getters.getFiltersOption(column, value));
            }
          });
        }

        return filtersOptions;
      };
    },
  },

  mutations: {
    SET_ROLES(state, roles) {
      if (roles) {
        state.roles = roles
          .filter((role) => role.parent?.code === DEALER)
          .map((role) => {
            const { id, name } = role;

            return {
              id,
              label: name,
            };
          });
      }
    },

    SET_COMPANIES(state, companies) {
      if (companies) {
        state.companies = companies.map((company) => {
          const { id, name: label } = company;

          return { id, label };
        });
      }
    },

    SET_CUSTOMERS(state, customers) {
      if (customers) {
        state.customers = customers.map((customer) => {
          const { id, name: label } = customer;

          return { id, label };
        });
      }
    },

    SET_FILTERS_FORM(state, form) {
      state.filtersForm = form;
    },
  },
};

import i18n from "@/plugins/vue-i18n";

import Customers from "@/views/settings/customers/index";
import CustomersAdd from "@/views/settings/customers/add/index.vue";
import CustomersEdit from "@/views/settings/customers/edit/index.vue";

const customers = [
  {
    path: "settings/customers",
    name: "Customers",
    component: Customers,
    meta: {
      title: i18n.t("title.customers._"),
    },
  },
  {
    path: "settings/customers/0",
    name: "CustomersAdd",
    component: CustomersAdd,
    meta: {
      title: i18n.t("title.customers.add"),
    },
  },
  {
    path: "settings/customers/:id/edit",
    name: "CustomersEdit",
    component: CustomersEdit,
    meta: {
      title: i18n.t("title.customers.edit"),
    },
  },
];

export default customers;

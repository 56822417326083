import Vue from "vue";
import VueRouter from "vue-router";
import authLayout from "@/router/layouts/authLayout";
import mainLayout from "@/router/layouts/mainLayout";
import errorLayout from "@/router/layouts/errorLayout";
import beforeEachMiddleware from "@/router/beforeEachMiddleware";
import afterEachMiddleware from "@/router/afterEachMiddleware";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_BASE_PATH,
  routes: [
    ...authLayout,
    ...errorLayout,
    ...mainLayout,
    {
      path: "*",
      redirect: "/404",
    },
  ],
});

router.beforeEach(beforeEachMiddleware);
router.afterEach(afterEachMiddleware);

export default router;

import i18n from "@/plugins/vue-i18n";

const Market = () => import(/* webpackChunkName: "dashboard" */ "@/views/dashboard/index");

const market = [
  {
    path: "/dashboard",
    name: "Market",
    component: Market,
    meta: {
      title: i18n.t("title.market"),
    },
  },
];

export default market;

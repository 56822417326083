<template>
  <OurPage
    :back-route="backRoute"
    class="access-groups"
    :title="$t('title.knowledgeBase.chapter')"
    width="md"
  >
    <DeleteModal
      :show.sync="isShownDeleteModal"
      :article-id="articleId"
      :article-name="articleName"
      @deleteArticle="getData"
    />

    <template #header-right>
      <OurLink v-if="!isUserDealer && getPermission['knowledge_base.create']" :route="addRoute">
        <OurButton :text="$t('button.add')" />
      </OurLink>
    </template>
    <OurTableList
      :have-permission-delete="getPermission['knowledge_base.delete']"
      :have-permission-edit="getPermission['knowledge_base.update']"
      :list="articles"
      @clickEdit="onClickEdit"
      @clickDelete="onClickDelete"
      @dragSort="dragHandler"
    />
  </OurPage>
</template>

<script>
import { mapGetters } from "vuex";
import { articlesQuery } from "@/views/settings/base-knowledge/chapter/index/gql/queries";
import apolloMixin from "@/mixins/apollo.mixin";
import DeleteModal from "@/views/settings/base-knowledge/chapter/delete/Modal.vue";
import { reorderArticlesMutation } from "@/views/settings/base-knowledge/chapter/index/gql/mutations";

export default {
  name: "Chapter",
  components: { DeleteModal },
  mixins: [apolloMixin],
  data() {
    return {
      backRoute: {
        name: "BaseKnowledge",
        title: this.$t("title.knowledgeBase.chapters"),
      },
      articles: [],
      isShownDeleteModal: false,
      articleId: "",
      articleName: "",
    };
  },
  computed: {
    ...mapGetters("user", ["isUserDealer", "getPermission"]),

    addRoute: () => ({ name: "ChapterAdd" }),
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      const { articles } = await this.$get(articlesQuery);

      if (articles) {
        this.articles = articles
          .filter((article) => article.section?.id === this.$route.params.id)
          .map((article) => {
            const { id, name } = article;

            return {
              id,
              name,
            };
          });
      }
    },

    onClickEdit(id) {
      this.$router.push({ name: "ChapterEdit", params: { articleId: id } });
    },

    onClickDelete(id) {
      this.isShownDeleteModal = true;
      this.articleId = id;
      this.articleName = this.articles.find((article) => article.id === id).name;
    },

    async dragHandler(data) {
      const order = data.map((object) => {
        return object.id;
      });

      await this.$post(
        reorderArticlesMutation,
        { order: order, sectionId: this.$route.params.id },
        {
          delaySuccessNotify: false,
        }
      );
    },
  },
};
</script>

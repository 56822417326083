import i18n from "@/plugins/vue-i18n";

const Products = () => import(/* webpackChunkName: "products" */ "@/views/products/index");
const ProductDetails = () => import(/* webpackChunkName: "products" */ "@/views/products/details");
const ProductsCart = () => import(/* webpackChunkName: "products" */ "@/views/products/cart");

const products = [
  {
    path: "/products",
    name: "Products",
    component: Products,
    meta: {
      title: i18n.t("title.catalog._"),
    },
  },
  {
    path: "/products/:id",
    name: "ProductDetails",
    component: ProductDetails,
    meta: {
      title: i18n.t("title.product"),
    },
  },
  {
    path: "/products-cart",
    name: "ProductsCart",
    component: ProductsCart,
    meta: {
      title: i18n.t("title.cart._"),
    },
  },
];

export default products;

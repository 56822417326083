import gql from "graphql-tag";

export const articleQuery = gql`
  query article($id: ID!) {
    article(id: $id) {
      id
      name
      type
      content
      files {
        id
        name
        link
      }
    }
  }
`;

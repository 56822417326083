import i18n from "@/plugins/vue-i18n";

export default {
  namespaced: true,
  state: {
    currencies: [],
    projectCurrencies: [{ code: "грн" }, { code: "USD" }, { code: "EUR" }, { code: "PLN" }],
  },

  getters: {
    currenciesList(state) {
      const currencies = [];

      state.currencies.forEach((currency) => {
        const { id, symbol, code } = currency;

        if (state.projectCurrencies.find((currency) => currency.code === code)) {
          currencies.push({
            id,
            symbol,
            label: i18n.t(`currency.${code === "грн" ? "UAH" : code}.full`),
          });
        }
      });

      return currencies;
    },

    getCode() {
      return (code) => {
        const key = `currency.${code}.short`;

        return i18n.te(key) ? i18n.t(key) : code;
      };
    },
  },

  mutations: {
    SET_CURRENCIES(state, currencies) {
      state.currencies = currencies;
    },
  },
};

<template>
  <div class="wrapper" :class="themeClass">
    <t-button
      class="button"
      :disabled="disabled"
      :class="sizeClass"
      :data-cy="dataCy"
      @click="onClick"
    >
      <OurSvgIcon :src="iconSrc" class="icon" color="gray" />

      <span class="button-text">{{ text }}</span>
    </t-button>

    <div class="line" />
  </div>
</template>

<script>
import { getThemeClass } from "@/services/_bit/UiService";

import OurSvgIcon from "@/components/_bit/IconSvg";
import TButton from "vue-tailwind/dist/t-button";

export default {
  name: "ButtonExpandItem",

  components: {
    OurSvgIcon,
    TButton,
  },

  props: {
    /**
     * The size of the button.
     * @values sm, md, lg
     */
    size: {
      type: String,
      default: "md",
    },

    /**
     * Set button text.
     */
    text: {
      type: String,
      required: true,
    },

    /**
     * Make button inactive.
     */
    disabled: {
      type: Boolean,
      default: false,
    },

    /**
     * Icon SVG data source path.
     */
    iconSrc: {
      type: String,
      default: "",
    },

    /**
     * Sets data-cy attribute for correct element sampling in tests.
     */
    dataCy: {
      type: String,
      default: "",
    },
  },

  computed: {
    themeClass() {
      return getThemeClass();
    },

    sizeClass() {
      return `size-${this.size}`;
    },
  },

  methods: {
    onClick() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="postcss" scoped>
.wrapper {
  &:last-child {
    .line {
      @apply hidden;
    }
  }

  .button {
    @apply flex items-center;
    @apply font-normal text-gray-900;
    @apply w-full text-left;
    @apply rounded-lg shadow-none outline-none;
    @apply border bg-transparent;

    &-text {
      @apply border-b border-transparent;
    }

    .icon {
      @apply mr-3;
    }

    &:hover {
      .button-text {
        @apply transition duration-100 ease-in-out;
        @apply border-b border-dashed border-gray-900;
      }
    }

    &:focus {
      @apply border-transparent;
      @apply ring-0;
    }

    &:disabled {
      @apply text-gray-400;
    }
  }

  .line {
    @apply border border-l-0 border-r-0 border-t-0 border-gray-200;
    @apply my-2;
  }
}

.size {
  &-sm {
    @apply text-sm;
    @apply px-3;
  }

  &-md {
    @apply text-base;
    @apply px-4;
  }

  &-lg {
    @apply text-lg;
    @apply px-5;
  }
}
</style>

import i18n from "@/plugins/vue-i18n";

const MutualSettlements = () =>
  import(/* webpackChunkName: "mutual-settlements" */ "@/views/mutual-settlements/index");

const leads = [
  {
    path: "/mutual-settlements",
    name: "MutualSettlements",
    component: MutualSettlements,
    meta: {
      title: i18n.t("title.mutualSettlements"),
    },
  },
];

export default leads;

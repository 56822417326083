<template>
  <div class="more-buttons-wrapper" :data-cy="dataCy">
    <!-- @slot Use it to add button expand item instead. -->
    <slot v-for="(button, index) in buttons" :button="button" :data-cy="`${dataCy}-item-${index}`">
      <OurExpandButtonItem
        v-if="button.isShown"
        :text="button.text"
        :icon-src="button.iconSrc"
        :data-cy="`${dataCy}-item-${index}`"
      />
    </slot>
  </div>
</template>

<script>
import OurExpandItem from "@/components/_bit/ButtonExpandItem";

export default {
  name: "OurExpandButton",

  components: {
    OurExpandItem,
  },

  props: {
    /**
     * Set buttons data for expand button.
     */
    buttons: {
      type: Array,
      default: () => [],
    },

    /**
     * Sets data-cy attribute for correct element sampling in tests.
     */
    dataCy: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="postcss" scoped>
.more-buttons-wrapper:empty {
  @apply hidden;
}
</style>

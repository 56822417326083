import DataService from "@/services/data.service";

export default class FormDataService {
  /**
   * Use for return prepared form
   * @returns Object
   */
  get(form = {}) {
    const {
      id,
      name,
      description,
      category,
      price,
      currency,
      quantity,
      location,
      isNew,
      condition,
      files,
      contactName,
      contactPhone,
      isActive,
    } = form;

    const preparedPhone = DataService.getPreparedPhone(contactPhone);
    const productPrice = DataService.getPreparedPrice(price);
    const preparedLocation = location ? DataService.getPreparedLocation(location) : "";

    const formData = {
      id,
      name,
      description,
      currency: {
        associate: currency,
      },
      price: productPrice,
      quantity: parseInt(quantity),
      location: preparedLocation,
      isNew,
      condition,
      contactName,
      contactPhone: preparedPhone,
      isActive,
      files: {
        sync: files,
      },
    };

    if (category) {
      formData.category = {
        associate: category,
      };
    } else if (form.defaultCategory) {
      formData.category = {
        disassociate: true,
      };
    }

    return formData;
  }
}

<template>
  <div :data-cy="dataCy">
    <h3 v-if="label" class="label">{{ label }}</h3>

    <div class="files-block">
      <slot>
        <OurFile
          v-for="(option, index) in options"
          :key="option.text"
          :text="option.text"
          :url="option.url"
          :data-cy="`${dataCy}-item-${index}`"
        />
      </slot>
    </div>
  </div>
</template>

<script>
import OurFile from "@/components/_bit/File";

export default {
  name: "OurFiles",

  components: {
    OurFile,
  },

  props: {
    /**
     * Set files label.
     */
    label: {
      type: String,
      default: "",
    },

    /**
     * Set options for files.
     */
    options: {
      type: Array,
      default: () => [],
    },

    /**
     * Sets data-cy attribute for correct element sampling in tests.
     */
    dataCy: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="postcss" scoped>
.label {
  @apply block max-w-max;
  @apply text-sm font-normal text-gray-500;
  @apply mb-2;
}
</style>

<template>
  <OurLink class="file-block" :url="url" no-focus-ring target-blank :data-cy="dataCy">
    <div class="file-block-info">
      <OurSvgIcon
        :src="icons.File"
        color="gray"
        class="icon"
        interactive
        @focus="onFocus"
        @blur="onBlur"
      />
      <span class="text" :class="focusClass">{{ text }}</span>
    </div>
  </OurLink>
</template>

<script>
import OurLink from "@/components/_bit/ButtonLink";
import OurSvgIcon from "@/components/_bit/IconSvg";

export default {
  name: "OurFile",

  components: {
    OurLink,
    OurSvgIcon,
  },

  props: {
    /**
     * Set url link for the file.
     */
    url: {
      type: String,
      default: "",
    },

    /**
     * Set text.
     */
    text: {
      type: String,
      default: "",
    },

    /**
     * Sets data-cy attribute for correct element sampling in tests.
     */
    dataCy: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      focus: false,
    };
  },

  computed: {
    icons: () => ({
      File: require("@/components/_bit/IconSvg/icons/File.svg"),
    }),

    focusClass() {
      return this.focus ? "focus" : "";
    },
  },

  methods: {
    onFocus() {
      this.focus = true;
    },

    onBlur() {
      this.focus = false;
    },
  },
};
</script>

<style lang="postcss" scoped>
.file-block:deep {
  .file-block-info {
    @apply flex items-start;
  }

  .icon {
    @apply mr-3;
  }

  .text {
    @apply whitespace-normal;
    @apply pt-0.5;

    &:hover {
      @apply underline decoration-dashed underline-offset-4;
    }
  }
}

.focus {
  @apply underline decoration-dashed underline-offset-4;
}
</style>

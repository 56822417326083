<template>
  <div class="our-empty-block" :class="sizeClass" :data-cy="dataCy">
    <div class="our-empty-block-header">
      <!-- @slot Use it to add something to header. -->
      <slot name="header">
        <OurSvgIcon :src="icons.EmojiFoodBeverage" color="gray" :size="iconSize" pill />
      </slot>
    </div>

    <!-- @slot Use it to add something inside. -->
    <slot>
      <div v-if="title" class="our-empty-block-title">
        {{ title }}
      </div>
      <div v-if="description" class="our-empty-block-description">
        {{ description }}
      </div>
    </slot>

    <!-- @slot Use it to add something to footer. -->
    <slot name="footer" />
  </div>
</template>

<script>
import OurSvgIcon from "@/components/_bit/IconSvg";

export default {
  name: "OurEmptyBlock",

  components: {
    OurSvgIcon,
  },

  props: {
    /**
     * Set title.
     */
    title: {
      type: String,
      default: "",
    },

    /**
     * Set component description.
     */
    description: {
      type: String,
      default: "",
    },

    /**
     * The size of the component.
     * @values sm, md, lg
     */
    size: {
      type: String,
      default: "md",
    },

    /**
     * Sets data-cy attribute for correct element sampling in tests.
     */
    dataCy: {
      type: String,
      default: "",
    },
  },

  computed: {
    icons: () => ({
      EmojiFoodBeverage: require("@material-symbols/svg-400/outlined/emoji_food_beverage.svg"),
    }),

    sizeClass() {
      return `size-${this.size}`;
    },

    iconSize() {
      const sizes = {
        sm: "xl",
        md: "2xl",
        lg: "3xl",
      };

      return sizes[this.size];
    },
  },
};
</script>

<style lang="postcss" scoped>
.our-empty-block {
  @apply flex flex-col items-center;
  @apply w-full;

  &-header {
    @apply flex justify-center;
    @apply mb-4;
  }

  &-title {
    @apply mb-2 font-medium;
  }

  &-title,
  &-description {
    @apply text-center;
  }
}

.size {
  &-sm {
    .our-empty-block {
      &-title {
        @apply text-base;
      }

      &-description {
        @apply text-sm;
      }
    }
  }

  &-md {
    .our-empty-block {
      &-title {
        @apply text-lg;
      }

      &-description {
        @apply text-base;
      }
    }
  }

  &-lg {
    .our-empty-block {
      &-title {
        @apply text-xl;
      }

      &-description {
        @apply text-lg;
      }
    }
  }
}
</style>

import i18n from "@/plugins/vue-i18n";

import BaseKnowledge from "@/views/settings/base-knowledge/index";
import BaseKnowledgeAdd from "@/views/settings/base-knowledge/add";
import BaseKnowledgeEdit from "@/views/settings/base-knowledge/edit";
import Chapter from "@/views/settings/base-knowledge/chapter/index/index.vue";
import ChapterAdd from "@/views/settings/base-knowledge/chapter/add";
import ChapterEdit from "@/views/settings/base-knowledge/chapter/edit";

const baseKnowledge = [
  {
    path: "settings/base-knowledge",
    name: "BaseKnowledge",
    component: BaseKnowledge,
    meta: {
      title: i18n.t("title.knowledgeBase._"),
    },
  },
  {
    path: "settings/base-knowledge/0",
    name: "BaseKnowledgeAdd",
    component: BaseKnowledgeAdd,
    meta: {
      title: i18n.t("title.knowledgeBase.add"),
    },
  },
  {
    path: "settings/base-knowledge/:id/edit",
    name: "BaseKnowledgeEdit",
    component: BaseKnowledgeEdit,
    meta: {
      title: i18n.t("title.knowledgeBase.edit"),
    },
  },
  {
    path: "settings/base-knowledge/:id",
    name: "Chapter",
    component: Chapter,
  },
  {
    path: "settings/base-knowledge/:id/add",
    name: "ChapterAdd",
    component: ChapterAdd,
  },
  {
    path: "settings/base-knowledge/:id/edit/:articleId",
    name: "ChapterEdit",
    component: ChapterEdit,
  },
];

export default baseKnowledge;

import Vue from "vue";
import VueTailwind from "vue-tailwind";

import {
  TInput,
  TTextarea,
  TButton,
  TModal,
  TDropdown,
  TCard,
  TTable,
  TCheckbox,
  TPagination,
  TRadio,
  TDatepicker,
} from "vue-tailwind/dist/components";

const components = {
  TInput,
  TTextarea,
  TButton,
  TModal,
  TDropdown,
  TCard,
  TCheckbox,
  TTable,
  TPagination,
  TRadio,
  TDatepicker,
};

Vue.use(VueTailwind, components);

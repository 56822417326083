import gql from "graphql-tag";

export const createEmployeeMutation = gql`
  mutation createEmployee(
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $roleId: ID!
    $isActive: Boolean
  ) {
    createEmployee(
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
      isActive: $isActive
      roleId: $roleId
    ) {
      id
    }
  }
`;

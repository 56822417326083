<template>
  <OurDropdownButton
    :closed="isClosed"
    class="change-type"
    @closeDropdown="(val) => (isClosed = val)"
  >
    <template #trigger="{ keydownHandler, mousedownHandler, blurHandler }">
      <OurLink
        class="button-link"
        :text="$t('button.changeType')"
        variant="secondary"
        size="xs"
        dashed
        @mousedown="mousedownHandler"
        @keydown="keydownHandler"
        @blur="blurHandler($event)"
      />
    </template>

    <div class="dropdown-block">
      <div
        v-for="(item, key) of options"
        :key="key"
        class="dropdown-block-item"
        :class="selectedItemClass(item.value)"
        @click="onClickItem(item.value)"
      >
        {{ item.label }}
      </div>
    </div>
  </OurDropdownButton>
</template>

<script>
export default {
  name: "ChangeType",

  props: {
    value: {
      type: String,
      default: "",
    },

    options: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      isClosed: false,
    };
  },

  computed: {
    selectValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  methods: {
    selectedItemClass(value) {
      return this.selectValue === value ? "selected" : "";
    },

    onClickItem(value) {
      this.selectValue = value;
      this.isClosed = true;
    },
  },
};
</script>

<style lang="postcss" scoped>
.change-type:deep {
  .dropdown-wrapper {
    @apply truncate;
  }

  .button-link {
    @apply pt-3;
  }

  .dropdown-block {
    @apply right-0 h-auto w-52;
    left: unset;

    &:hover {
      @apply border-gray-400;
      @apply transition duration-100 ease-in-out;
    }

    &-item {
      @apply text-base font-normal normal-case text-gray-900 no-underline;
      @apply cursor-pointer align-middle;
      @apply w-52 p-3;

      &:hover {
        @apply bg-gray-100;
      }

      &:active {
        @apply !bg-gray-200 font-medium;
      }
    }
  }
}

.selected {
  @apply !bg-gray-200 font-medium;
}
</style>

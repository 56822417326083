import { render, staticRenderFns } from "./VariantInput.vue?vue&type=template&id=550e5025&scoped=true&"
import script from "./VariantInput.vue?vue&type=script&lang=js&"
export * from "./VariantInput.vue?vue&type=script&lang=js&"
import style0 from "./VariantInput.vue?vue&type=style&index=0&id=550e5025&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "550e5025",
  null
  
)

export default component.exports
<template>
  <div class="access-groups-form">
    <OurGroup>
      <OurInput
        v-model="form.name"
        :label="$t('page.catalog.name')"
        :placeholder="$t('placeholder.baseKnowledgeName')"
        :error="nameError"
      />
    </OurGroup>
  </div>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import ValidationService from "@/services/validation.service";

const { TEXT_MAX_LENGTH } = ValidationService;

export default {
  name: "BaseKnowledgeForm",

  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      form: {
        name: "",
      },
    };
  },

  validations: {
    form: {
      name: { required, maxLength: maxLength(TEXT_MAX_LENGTH) },
    },
  },

  computed: {
    nameError() {
      const isDirty = this.$v.form.name.$dirty;
      const isFilled = this.$v.form.name.required;

      const maxLength = this.$v.form.name.maxLength;

      let error = "";

      if (isDirty && !isFilled) {
        error = this.$t("validation.required");
      } else if (isDirty && !maxLength) {
        error = this.$t("validation.textMaxLength", {
          length: TEXT_MAX_LENGTH,
        });
      }

      return error;
    },
  },

  watch: {
    value: {
      handler: "getForm",
      deep: true,
    },
  },

  methods: {
    getForm() {
      if (this.value) {
        this.form = { ...this.value };
      }
    },

    emitForm() {
      if (this.$v.$invalid) {
        this.$v.$touch();

        return;
      }

      this.form.isValid = true;
      this.$emit("input", this.form);
    },
  },
};
</script>

import gql from "graphql-tag";

export const rolesQuery = gql`
  query roles {
    roles {
      id
      name
      code
      parent {
        code
      }
    }
  }
`;
export const customersQuery = gql`
  query allCustomer {
    allCustomer {
      id
      name
    }
  }
`;

export const dealersQuery = gql`
  query dealers($filters: Filter, $page: Int, $perPage: Int, $trashed: Trashed) {
    dealers(
      orderBy: { column: "createdAt", direction: desc }
      filters: $filters
      page: $page
      first: $perPage
      trashed: $trashed
    ) {
      paginatorInfo {
        currentPage
        perPage
        total
        lastPage
      }
      data {
        id
        email
        firstName
        lastName
        secondName
        phone
        type
        detail {
          __typename
          ... on Dealer {
            id
            customer {
              name
            }
            status
          }
        }
        roles {
          name
        }
        lastActivityAt
      }
    }
  }
`;

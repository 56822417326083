<template>
  <OurPage :title="$t('title.newNotices._')">
    <template v-if="getPermission['notice.notifications']" #header-right>
      <OurButton
        v-if="!isMobileDevice"
        :text="$t('title.fleaMarket.settingsModal')"
        @click="onClickSettingsButton"
      />
      <OurButton v-else @click="onClickSettingsButton">
        <OurSvgIcon
          class="settings-icon"
          :src="icons.Settings"
          color="white"
          variant="light"
          size="md"
        />
      </OurButton>
    </template>

    <SettingsModal :show.sync="isShownSettingsModal" />

    <template v-if="!isMobileDevice">
      <NoticeList
        is-own
        :items="noticesDataOwn"
        :pagination-data="paginationDataOwn"
        @pageChange="getOwnNoticesData"
      />
    </template>
    <template v-else>
      <div v-if="noticesDataOwn.length">
        <NoticeCard
          v-for="(noticeItem, index) of noticesDataOwn"
          :id="noticeItem.id"
          :key="index"
          :is-own="true"
          :status="noticeItem?.status"
          :moderation-status="noticeItem.modStatus"
          :image="noticeItem.image"
          :name="noticeItem.name.primaryRow"
          :price="noticeItem.price"
          :price-general="noticeItem.priceGeneral"
          :quantity="noticeItem.quantity"
          :location="noticeItem.location"
          :description="noticeItem.description"
          class="notice-card mb-4"
        />
      </div>
      <div v-else>{{ $t("page.dashboard.emptyMessage") }}</div>
    </template>
  </OurPage>
</template>

<script>
import NoticeList from "@/views/settings/new-notices/index/_components/NoticeList.vue";
import { noticesQuery } from "@/views/flea-market/index/gql/queries";
import apolloMixin from "@/mixins/apollo.mixin";
import { mapGetters } from "vuex";
import NoticeCard from "@/views/flea-market/index/_components/NoticeCard.vue";
import SettingsModal from "@/views/settings/new-notices/settings/Modal.vue";

const DEFAULT_IMAGE = "@/components/_bit/IconSvg/icons/Image.svg";

export default {
  name: "NewNotices",
  components: { SettingsModal, NoticeCard, NoticeList },
  mixins: [apolloMixin],
  data() {
    return {
      paginationDataOwn: {},
      noticesDataOwn: [],
      isShownSettingsModal: false,
    };
  },
  computed: {
    ...mapGetters("fleaMarket", ["getStatus", "getModerationStatus"]),
    ...mapGetters("currencies", ["getCode"]),
    ...mapGetters("breakpoint", ["isMobileDevice"]),
    ...mapGetters("user", ["getPermission"]),

    icons: () => ({
      Settings: require("@material-symbols/svg-400/outlined/settings.svg"),
    }),
  },
  created() {
    this.getOwnNoticesData();
  },
  methods: {
    onClickSettingsButton() {
      this.isShownSettingsModal = true;
    },
    getOwnNoticesData(page) {
      this.getData(page);
    },
    async getData(page) {
      if (page && page === this.paginationDataOwn.currentPage) return;

      const filtersOptions = [
        {
          method: "where",
          column: "isActive",
          operator: "=",
          value: true,
        },
        {
          method: "where",
          column: "moderationStatus",
          operator: "=",
          value: "inModeration",
        },
      ];

      const { notices } = await this.$get(noticesQuery, {
        filtersOptions,
        page,
      });

      if (notices) {
        const noticesData = notices.data.map((notice) => {
          const {
            id,
            name,
            description,
            category,
            price,
            quantity,
            location,
            currency,
            files,
            isActive,
            moderationStatus,
          } = notice;
          const firstImage = files.length ? files[0].link : DEFAULT_IMAGE;
          const status = this.getStatus(isActive);
          const modStatus = this.getModerationStatus(moderationStatus);

          return {
            id,
            image: firstImage,
            name: {
              primaryRow: name,
              secondaryRow: category?.description || "",
            },
            description,
            quantity: `${quantity} ${this.$t("page.fleaMarket.pieces")}`,
            location: location || "",
            price: {
              sum: price,
              currencySymbol: this.getCode(currency.code),
            },
            priceGeneral: {
              sum: price * quantity,
              currencySymbol: this.getCode(currency.code),
            },
            status,
            modStatus,
          };
        });

        this.paginationDataOwn = notices.paginatorInfo;
        this.noticesDataOwn = noticesData;
      }
    },
  },
};
</script>

import ApiService from "@/services/api.service";

const USER_TYPE = {
  dealer: "dealer",
  employee: "employee",
};

async function getProfile() {
  const response = await ApiService.get("/auth/profile");

  return response.data;
}

export { USER_TYPE, getProfile };

import ApiService from "@/services/api.service";
const STATUS_TYPE = {
  delivery: "delivery",
  pay: "pay",
  canceled: "canceled",
  draft: "draft",
  new_made: "new",
  partially_shipped: "partiallyShipped",
};

const getWarrantyCard = async (id) => {
  try {
    const response = await ApiService.get(`shipments/${id}/warranty-card`, {
      responseType: "blob",
    });

    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export { STATUS_TYPE, getWarrantyCard };

import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("user", ["userProfile", "isUserDealer"]),

    getUserProfile() {
      return this.userProfile;
    },

    getUserPermissions() {
      return this.getUserProfile.permissions;
    },

    dealerRole() {
      return this.isUserDealer;
    },

    permissionStatus() {
      let obj = {};
      const permissionsNames = [...this.getUserPermissions].map((el) => el.nameOriginal);

      this.getUserPermissions.forEach((itm) => {
        if (permissionsNames.includes(itm.nameOriginal)) {
          Object.assign(obj, { [itm.nameOriginal]: itm.isActive });
        }
      });

      return obj;
    },
  },
  methods: {
    checkPermissions(item) {
      const { permissions } = item;

      if (permissions) {
        if (this.checkIsArray(permissions) && permissions.length <= 1) {
          return this.permissionStatus[permissions[0]];
        } else {
          return this.checkPermissionAccessGroup(permissions);
        }
      } else {
        return true;
      }
    },

    checkIsArray(key) {
      return Array.isArray(key);
    },

    checkPermissionAccessGroup(permissions) {
      return [...this.getUserPermissions]
        .filter((el) => permissions.includes(el.nameOriginal))
        .some((someEl) => someEl.isActive);
    },
  },
};

export default {
  namespaced: true,
  state: {
    categories: [],
    filtersForm: {
      name: "",
      sectionId: "",
    },
  },

  getters: {
    getFiltersOption() {
      return (column, value) => {
        const columns = ["name"];
        const operator = columns.includes(column) ? "like" : "";

        return {
          method: "where",
          column,
          operator,
          value,
        };
      };
    },
    getFiltersOptions(state, getters) {
      return () => {
        const filtersForm = state.filtersForm;
        let filtersOptions = [];

        if (filtersForm) {
          Object.keys(filtersForm).forEach((key) => {
            if (filtersForm[key]) {
              const column = key;
              const value = filtersForm[key];

              filtersOptions.push(getters.getFiltersOption(column, value));
            }
          });
        }

        return filtersOptions;
      };
    },
  },

  mutations: {
    SET_CATEGORIES(state, category) {
      state.categories = category;
    },

    SET_CATEGORY(state, form) {
      state.filtersForm.sectionId = form;
    },

    SET_SEARCH(state, search) {
      state.filtersForm.name = search;
    },
    SET_FILTERS_FORM(state, form) {
      state.filtersForm = form;
    },
  },
};

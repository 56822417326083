import I18nService from "@/services/i18n.service";

export default {
  namespaced: true,
  state: {
    regions: [],
  },

  mutations: {
    SET_REGIONS(state, regions) {
      state.regions = regions.map((region) => {
        const { id, name } = region;

        return {
          id,
          label: name[I18nService.getActiveLanguage()],
        };
      });
    },
  },
};

import Vue from "vue";

// Comment on components you do not use in project.
import OurAccordion from "./Accordion";
import OurDropdownList from "./DropdownList";
import OurAccordionItem from "./AccordionItem";
import OurAvatar from "./Avatar";
import OurButton from "./Button";
import OurDropdownButton from "./ButtonDropdown";
import OurButtonGroup from "./ButtonGroup";
import OurButtonGroupItem from "./ButtonGroupItem";
import OurDivider from "./Divider";
import OurLink from "./ButtonLink";
import OurDropdownLink from "./ButtonLinkDropdown";
import OurExpandButton from "./ButtonExpand";
import OurExpandButtonItem from "./ButtonExpandItem";
import OurCard from "./ContainerCard";
import OurConfirmModal from "./ContainerModalConfirm";
import OurModal from "./ContainerModal";
import OurPage from "./ContainerPage";
import OurRow from "./ContainerRow";
import OurEmptyBlock from "./EmptyBlock";
import OurGroup from "./ContainerGroup";
import OurGroups from "./ContainerGroups";
import OurSvgIcon from "./IconSvg";
import OurInput from "./Input";
import OurCounter from "./InputCounter";
import OurCheckbox from "./InputCheckbox";
import OurCheckboxGroup from "./InputCheckboxGroup";
import OurDot from "./Dot";
import OurMultiStateCheckbox from "./InputCheckboxMultiState";
import OurDatePicker from "./InputDatePicker";
import OurDateRangePicker from "./InputDatePickerRange";
import OurFileInput from "./InputFile";
import OurFile from "./File";
import OurFiles from "./Files";
import OurMoneyInput from "./InputMoney";
import OurPhoneInput from "./InputPhone";
import OurRadio from "./InputRadio";
import OurRadioCard from "./InputRadioCard";
import OurRadioGroup from "./InputRadioGroup";
import OurRatingInput from "./InputRating";
import OurSearchInput from "./InputSearch";
import OurSelect from "./InputSelect";
import OurMultiselect from "./InputSelectMulti";
import OurSwitch from "./InputSwitch";
import OurTextarea from "./InputTextarea";
import OurTab from "./Tab";
import OurTableList from "./TableList";
import OurTable from "./Table";
import OurTabs from "./Tabs";
import OurLogo from "./Logo";
import OurPagination from "./Pagination";
import OurSlider from "./Slider";
import OurStepper from "./Stepper";
import OurTextBlock from "./TextBlock";
import OurTextField from "./TextField";
import OurHint from "./TextHint";
import OurHeader from "./TextHeader";
import OurMoney from "./TextMoney";
import OurTag from "./TextTag";
import OurDropdownTag from "./TextTagDropdown";

Vue.component("OurAccordion", OurAccordion);
Vue.component("OurAccordionItem", OurAccordionItem);
Vue.component("OurAvatar", OurAvatar);
Vue.component("OurButton", OurButton);
Vue.component("OurDropdownButton", OurDropdownButton);
Vue.component("OurButtonGroup", OurButtonGroup);
Vue.component("OurButtonGroupItem", OurButtonGroupItem);
Vue.component("OurDivider", OurDivider);
Vue.component("OurLink", OurLink);
Vue.component("OurDropdownLink", OurDropdownLink);
Vue.component("OurExpandButton", OurExpandButton);
Vue.component("OurExpandButtonItem", OurExpandButtonItem);
Vue.component("OurCard", OurCard);
Vue.component("OurConfirmModal", OurConfirmModal);
Vue.component("OurModal", OurModal);
Vue.component("OurPage", OurPage);
Vue.component("OurRow", OurRow);
Vue.component("OurEmptyBlock", OurEmptyBlock);
Vue.component("OurGroup", OurGroup);
Vue.component("OurGroups", OurGroups);
Vue.component("OurSvgIcon", OurSvgIcon);
Vue.component("OurInput", OurInput);
Vue.component("OurCounter", OurCounter);
Vue.component("OurCheckbox", OurCheckbox);
Vue.component("OurCheckboxGroup", OurCheckboxGroup);
Vue.component("OurDot", OurDot);
Vue.component("OurMultiStateCheckbox", OurMultiStateCheckbox);
Vue.component("OurDatePicker", OurDatePicker);
Vue.component("OurDateRangePicker", OurDateRangePicker);
Vue.component("OurFileInput", OurFileInput);
Vue.component("OurFile", OurFile);
Vue.component("OurFiles", OurFiles);
Vue.component("OurMoneyInput", OurMoneyInput);
Vue.component("OurPhoneInput", OurPhoneInput);
Vue.component("OurRadio", OurRadio);
Vue.component("OurRadioCard", OurRadioCard);
Vue.component("OurRadioGroup", OurRadioGroup);
Vue.component("OurRatingInput", OurRatingInput);
Vue.component("OurSearchInput", OurSearchInput);
Vue.component("OurSelect", OurSelect);
Vue.component("OurMultiselect", OurMultiselect);
Vue.component("OurSwitch", OurSwitch);
Vue.component("OurTextarea", OurTextarea);
Vue.component("OurTab", OurTab);
Vue.component("OurTableList", OurTableList);
Vue.component("OurTable", OurTable);
Vue.component("OurTabs", OurTabs);
Vue.component("OurLogo", OurLogo);
Vue.component("OurPagination", OurPagination);
Vue.component("OurSlider", OurSlider);
Vue.component("OurStepper", OurStepper);
Vue.component("OurTextBlock", OurTextBlock);
Vue.component("OurTextField", OurTextField);
Vue.component("OurHint", OurHint);
Vue.component("OurHeader", OurHeader);
Vue.component("OurMoney", OurMoney);
Vue.component("OurTag", OurTag);
Vue.component("OurDropdownTag", OurDropdownTag);
Vue.component("OurDropdownList", OurDropdownList);

import {
  theme,
  fallbackLocale,
  backgroundsPath,
  layout,
  notify,
  withAccentColor,
  component,
} from "@/configs/ourApp.config";

export default class UiServiceDefault {
  isPWA = false;
  isMac = false;
  PX_IN_REM = 16;
  componentConfig = component;

  constructor() {
    const isBrowser = typeof window !== "undefined";

    this.isPWA = isBrowser && !!navigator.standalone;
    this.isMac = isBrowser && ~navigator.platform.toUpperCase().indexOf("MAC");
  }

  getTheme() {
    return theme || "";
  }

  getThemeClass() {
    let activeTheme = "";

    if (theme) {
      activeTheme = `${theme}-theme`;
    }

    if (withAccentColor) {
      activeTheme = `${activeTheme} accent`;
    }

    return activeTheme;
  }

  getBasePath() {
    const basePath = process.env.VUE_APP_BASE_PATH;

    if (!basePath || basePath === "/") {
      return "/";
    } else {
      return `/${basePath}`;
    }
  }

  convertHexInRgb(hex) {
    const color = hex.replace(/#/g, "");
    const r = parseInt(color.substr(0, 2), 16);
    const g = parseInt(color.substr(2, 2), 16);
    const b = parseInt(color.substr(4, 2), 16);

    return `${r}, ${g}, ${b}`;
  }

  getRandomId(idLength = 15) {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    const charactersLength = characters.length;
    let id = "";

    while (id.length < idLength) {
      id += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return id;
  }

  removeOutlineFocus() {
    const styleTag = document.createElement("style");
    let focusRing = true;

    document.head.appendChild(styleTag).innerHTML = `
      "body.hide-focus-ring *:focus {
        outline: none !important;
      }`;

    document.addEventListener("mousedown", () => {
      if (focusRing) document.body.classList.add("hide-focus-ring");

      focusRing = false;
    });

    document.addEventListener("keydown", () => {
      if (!focusRing) document.body.classList.remove("hide-focus-ring");

      focusRing = true;
    });
  }

  setFavicon(faviconPath) {
    if (!faviconPath) return;

    const head = document.querySelector("head");
    const faviconTag = document.createElement("link");

    faviconTag.setAttribute("rel", "shortcut icon");
    faviconTag.setAttribute("href", `${faviconPath}?${Math.random()}`);
    head.appendChild(faviconTag);
  }
}

export const {
  getTheme,
  getThemeClass,
  getBasePath,
  getRandomId,
  removeOutlineFocus,
  convertHexInRgb,
  setFavicon,
  isPWA,
  isMac,
  PX_IN_REM,
  componentConfig,
} = new UiServiceDefault();
export { fallbackLocale, backgroundsPath, layout, notify };

import gql from "graphql-tag";

export const knowledgeBaseSectionsQuery = gql`
  query knowledgeBaseSections {
    knowledgeBaseSections {
      id
      name
    }
  }
`;

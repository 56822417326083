import profile from "./profile";
import dashboard from "./dashboard";
import products from "./products";
import orders from "./orders";
import mutualSettlements from "./mutual-settlements";
import priceLists from "./price-lists";
import market from "./market";
import leads from "./leads";
import reserved from "./reserved";
import serviceCases from "./service-cases";
import fleaMarket from "./flea-market";
import news from "./news";
import knowledgeBase from "./knowledge-base";
import settings from "./settings";
import wish from "./wish-list";

const MainLayout = () => import(/* webpackChunkName: "MainLayout" */ "@/layouts/_bit/admin-mono");

// TODO: change for "Products" when development will be finish
const redirectPage = process.env.VUE_APP_FEATURE_FLAG_LEGACY === "0" ? "Dashboard" : "FleaMarket";

const mainLayout = [
  {
    path: "/",
    name: "MainLayout",
    component: MainLayout,
    redirect: { name: redirectPage },
    children: [
      ...dashboard,
      ...products,
      ...orders,
      ...mutualSettlements,
      ...priceLists,
      ...market,
      ...leads,
      ...reserved,
      ...serviceCases,
      ...fleaMarket,
      ...news,
      ...knowledgeBase,
      ...profile,
      ...settings,
      ...wish,
    ],
  },
];

export default mainLayout;

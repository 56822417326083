const { default: ourTopLoader } = require("./ourTopLoader.config");
const { default: notifyConfig } = require("./ourNotify.config");
const { default: adminMono } = require("./layoutAdminMono.config");
const { default: auth } = require("./layoutAuth.config");

module.exports = {
  brandName: "Atmosfera",
  fallbackLocale: "ua",
  withAccentColor: false,
  backgroundsPath: "static/backgrounds/",
  theme: "default",
  component: {
    ourTopLoader,
  },
  layout: {
    adminMono,
    auth,
  },
  notify: notifyConfig,
};

<template>
  <div class="users-list">
    <template v-if="isMobileDevice">
      <div v-if="items.length">
        <UsersCard
          v-for="(item, index) of items"
          :id="item.id"
          :key="index"
          :status="item.status"
          :user="item.user"
          :contacts="item.contacts"
          :visit="item.visit"
          @clickOnOrder="onClickRow({ id: item.id })"
        />
      </div>
      <div v-else>{{ $t("page.dashboard.emptyMessage") }}</div>
    </template>
    <OurTable
      v-else
      :headers="tableHeaders"
      :items="items"
      :table-fixed="false"
      class="users-list-table"
      @clickRow="onClickRow"
    >
      <template #cell-contacts="{ value }">
        <div class="contacts-block">
          <OurLink
            class="email"
            :text="value.email"
            :url="value.email"
            type="email"
            dashed
            size="sm"
            color="accent"
            no-focus-ring
            @click.native.stop
          />
        </div>
      </template>

      <template #cell-status="{ value }">
        <OurTag size="md" :text="value?.text" :color="value?.color" />
      </template>
    </OurTable>

    <OurPagination
      v-if="isMoreThanOnePage"
      :current-page="paginationData.currentPage"
      :per-page="paginationData.perPage"
      :total="paginationData.total"
      @pageChange="onPageChange"
    />
  </div>
</template>

<script>
import UsersCard from "@/views/settings/dealer-users/index/_components/UsersCard.vue";
import { mapGetters } from "vuex";

export default {
  name: "EmployeesList",
  components: { UsersCard },

  props: {
    items: {
      type: Array,
      default: () => [],
    },

    paginationData: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    ...mapGetters("breakpoint", ["isMobileDevice"]),
    tableHeaders() {
      const data = [
        {
          value: "id",
          text: this.$t("page.users.id"),
          thClass: "w-24",
          tdClass: "max-w-[2rem] min-w-[5rem]",
        },
        {
          value: "user",
          text: this.$t("page.users.user"),
          thClass: "w-[5rem]",
          tdClass: "max-w-[10rem] min-w-[10rem]",
        },
        {
          value: "contacts",
          text: this.$t("page.users.email"),
          thClass: "w-[10rem]",
          tdClass: "max-w-[12rem] min-w-[10rem]",
        },
        {
          value: "visit",
          text: this.$t("page.users.visit"),
          thClass: "w-[8rem]",
          tdClass: "max-w-[10rem] min-w-[8rem]",
        },
        {
          value: "status",
          text: this.$t("page.fleaMarket.status"),
          thClass: "w-[7rem]",
          tdClass: "max-w-[9rem] min-w-[7rem]",
        },
      ];

      return data;
    },

    isMoreThanOnePage() {
      return this.paginationData.lastPage > 1;
    },
  },

  methods: {
    onPageChange(page) {
      this.$emit("pageChange", page);
    },

    onClickRow(row) {
      this.$emit("onClickRow", row.id);
    },
  },
};
</script>

<style lang="postcss" scoped>
.users-list {
  .contacts-block {
    @apply overflow-hidden;

    .email {
      @apply truncate;
    }
  }
}
</style>

<template>
  <router-link
    :to="{ name: backRoute.name, params: backRoute.params }"
    class="arrow-link"
    :class="themeClass"
    :data-cy="dataCy"
  >
    <t-button class="arrow-button" @click="onClick">
      <OurSvgIcon class="icon" :src="icons.ArrowLeft" size="xs" color="gray" />

      <div v-if="!isMobileDevice" class="title">
        {{ backRoute.title }}
      </div>
    </t-button>
  </router-link>
</template>

<script>
import { getThemeClass } from "@/services/_bit/UiService";

import OurSvgIcon from "@/components/_bit/IconSvg";
import TButton from "vue-tailwind/dist/t-button";
import { mapGetters } from "vuex";

export default {
  name: "ArrowLink",

  components: {
    OurSvgIcon,
    TButton,
  },

  props: {
    backRoute: {
      type: Object,
      required: true,
      default: () => ({
        name: "",
        title: "",
        params: {},
      }),
      validator(backRoute) {
        const isExistName = "name" in backRoute;
        const isExistTitle = "title" in backRoute;

        return isExistName && isExistTitle;
      },
    },

    dataCy: {
      type: String,
      default: "",
    },
  },

  computed: {
    themeClass() {
      return getThemeClass();
    },

    icons: () => ({
      ArrowLeft: require("@/components/_bit/IconSvg/icons/Arrow-left.svg"),
    }),

    ...mapGetters("breakpoint", ["isMobileDevice"]),
  },

  methods: {
    onClick() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="postcss" scoped>
.arrow {
  &-link {
    @apply mb-2 block h-3.5;
  }

  &-button {
    @apply inline-flex items-center;
    @apply border-0 bg-transparent p-0 shadow-none;
    @apply cursor-pointer;

    &:hover {
      @apply bg-transparent;
    }

    .title {
      @apply text-xs font-normal text-gray-500;
      @apply ml-0.5;
    }

    .icon:deep(g rect) {
      @apply opacity-100;
    }
  }
}
</style>

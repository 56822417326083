<template>
  <OurPage
    class="flea-market-add"
    :title="$t('title.fleaMarket.details')"
    :back-route="backRoute"
    width="md"
  >
    <NoticeForm
      ref="noticeForm"
      v-model="form"
      class="notice-form"
      :type="type"
      :upload-files="uploadFiles"
      @uploadFiles="uploadFilesMutation"
      @deleteFiles="deleteFileMutation"
    />

    <template #footer-left>
      <OurButton class="button" :text="$t('button.save')" @click="onClickSaveButton" />
      <OurLink :route="backRoute">
        <OurButton class="button" :text="$t('button.back')" variant="secondary" />
      </OurLink>
    </template>
  </OurPage>
</template>

<script>
import { mapMutations } from "vuex";
import apolloMixin from "@/mixins/apollo.mixin";
import { updateNoticeMutation, uploadFilesMutation, deleteFileMutation } from "./gql/mutations";
import { noticeProductCategoriesAndCurrenciesQuery } from "./gql/queries";
import { TYPE_VALUES } from "@/api/fleaMarket";
import FormDataService from "@/views/flea-market/_services";
import NoticeForm from "@/views/flea-market/_components/NoticeForm";
import ChangeType from "@/views/flea-market/add/_components/ChangeType.vue";

const { own, all } = TYPE_VALUES;

export default {
  name: "NewNoticesEdit",

  components: {
    ChangeType,
    NoticeForm,
  },

  mixins: [apolloMixin],

  data() {
    return {
      type: own,
      typesList: [
        { value: own, label: this.$t("label.type.own") },
        { value: all, label: this.$t("label.type.all") },
      ],
      form: {
        name: "",
        description: "",
        category: "",
        price: "",
        currency: "",
        quantity: "",
        location: "",
        isNew: true,
        condition: 0,
        files: [],
        contactName: "",
        contactPhone: "",
        isActive: true,
        filesData: [],
      },
      uploadFiles: null,
      prevRoute: null,
    };
  },

  computed: {
    backRoute() {
      const noticeName = this.form.name;
      const preparedName = `${noticeName.charAt(0).toUpperCase()}${noticeName.slice(1)}`;

      return {
        name: "NewNoticesDetails",
        title: `${this.$t("title.fleaMarket.notices")} / ${preparedName}`,
      };
    },

    noticeId() {
      return this.$route.params.id;
    },
  },

  created() {
    this.getData();
  },

  methods: {
    ...mapMutations("fleaMarket", ["SET_CATEGORIES"]),
    ...mapMutations("currencies", ["SET_CURRENCIES"]),

    async getData() {
      const { notice, productCategories, currencies } = await this.$get(
        noticeProductCategoriesAndCurrenciesQuery,
        {
          id: this.noticeId,
        }
      );

      if (notice && productCategories && currencies) {
        const {
          category,
          name,
          description,
          currency,
          price,
          quantity,
          location,
          isNew,
          condition,
          files,
          contactName,
          contactPhone,
          isActive,
        } = notice;

        this.form = {
          name,
          description,
          category: category?.id,
          price,
          currency: currency.id,
          quantity,
          location: location || "",
          isNew,
          condition,
          contactName,
          contactPhone,
          isActive,
          filesData: files,
        };

        this.SET_CATEGORIES(productCategories);
        this.SET_CURRENCIES(currencies);
      }
    },

    onChangeType(value) {
      this.type = value;
    },

    async onClickSaveButton() {
      this.$refs.noticeForm.emitForm();

      if (this.form.isValid) {
        this.form.id = this.noticeId;
        const formData = new FormDataService().get(this.form);

        await this.$post(updateNoticeMutation, formData, { delaySuccessNotify: true });
        await this.$router.push({ name: "NewNoticesDetails", params: { id: this.noticeId } });
      }
    },

    async uploadFilesMutation(selectedFiles) {
      const { uploadFiles: files } = await this.$post(
        uploadFilesMutation,
        { files: selectedFiles },
        { withLoader: false, withNotify: false }
      );

      this.uploadFiles = files;
    },

    async deleteFileMutation(id) {
      await this.$post(deleteFileMutation, { id }, { withLoader: false, withNotify: false });
    },
  },
};
</script>

<style lang="postcss" scoped>
.flea-market-add {
  @apply relative;

  .button-link {
    @apply pt-3;
  }

  .change-type-select {
    @apply absolute;
    @apply right-4 top-[4.375rem] z-10;
  }

  .notice-form {
    @apply mb-6;
  }
}
</style>

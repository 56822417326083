<template>
  <OurPage :back-route="backRoute" :title="$t('title.roles.add')" width="md">
    <RolesForm ref="rolesForm" v-model="form" />

    <template #footer-left>
      <OurButton class="button" :text="$t('button.add')" @click="onClickSaveButton" />
      <OurLink :route="backRoute">
        <OurButton class="button" :text="$t('button.back')" variant="secondary" />
      </OurLink>
    </template>
  </OurPage>
</template>

<script>
import apolloMixin from "@/mixins/apollo.mixin";
import RolesForm from "@/views/settings/roles/_components/RolesForm.vue";
import { createRoleMutation } from "@/views/settings/roles/add/gql/mutations";

export default {
  name: "RolesAdd",

  components: {
    RolesForm,
  },

  mixins: [apolloMixin],

  data() {
    return {
      backRoute: {
        name: "Roles",
        title: this.$t("title.roles._"),
      },
      form: {
        name: "",
        permissions: [],
      },
    };
  },
  methods: {
    async onClickSaveButton() {
      this.$refs.rolesForm.emitForm();
      this.form.type = this.$route.params.id;

      if (this.form.isValid) {
        const { createRole } = await this.$post(createRoleMutation, this.form, {
          delaySuccessNotify: true,
        });

        if (createRole.id) await this.$router.push({ name: "Roles" });
      }
    },
  },
};
</script>

import gql from "graphql-tag";
export const companiesQuery = gql`
  query allCompanies($filters: Filter) {
    allCompanies(filters: $filters) {
      id
      name
      nameFull
    }
  }
`;

export const storagesQuery = gql`
  query storages {
    storages {
      id
      name
    }
  }
`;

export const accessGroupsQuery = gql`
  query accessGroups {
    accessGroups {
      id
      name
    }
  }
`;

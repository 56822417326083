import i18n from "@/plugins/vue-i18n";

const PriceLists = () => import(/* webpackChunkName: "dashboard" */ "@/views/price-lists/index");

const priceLists = [
  {
    path: "/price-list",
    name: "PriceLists",
    component: PriceLists,
    meta: {
      title: i18n.t("title.priceLists"),
    },
  },
];

export default priceLists;

import i18n from "@/plugins/vue-i18n";

const DealerUsers = () =>
  import(/* webpackChunkName: "dealer-users" */ "@/views/settings/dealer-users/index");

const DealerUsersAdd = () =>
  import(/* webpackChunkName: "dealer-users" */ "@/views/settings/dealer-users/add");

const DealerUsersEdit = () =>
  import(/* webpackChunkName: "dealer-users" */ "@/views/settings/dealer-users/edit");

const users = [
  {
    path: "settings/dealer-users",
    name: "DealerUsers",
    component: DealerUsers,
    meta: {
      title: i18n.t("title.users.dealers"),
    },
  },
  {
    path: "settings/dealer-users/0",
    name: "DealerUsersAdd",
    component: DealerUsersAdd,
    meta: {
      title: i18n.t("title.users.add"),
    },
  },
  {
    path: "settings/dealer-users/:id/edit",
    name: "DealerUsersEdit",
    component: DealerUsersEdit,
    meta: {
      title: i18n.t("title.users.edit"),
    },
  },
];

export default users;

<template>
  <div class="slider">
    <div class="active-image" @click="onClickOpenModal">
      <img v-if="bgActiveImage" :src="bgActiveImage" alt="image" @error="errorImageHandler" />
    </div>
    <template v-if="isMoreThanOneImage">
      <div class="counter">{{ activeSlideIndex + 1 }} / {{ images.length }}</div>

      <div class="buttons" @click="onClickOpenModal">
        <div class="button">
          <OurSvgIcon
            :src="icons.AngleLeft"
            size="md"
            color="white"
            :interactive="isBeginning"
            @click.native.stop="onClickPrev"
          />
        </div>

        <div class="button">
          <OurSvgIcon
            :src="icons.AngleRight"
            size="md"
            color="white"
            :interactive="isEnd"
            @click.native.stop="onClickNext"
          />
        </div>
      </div>

      <SliderPreview
        v-model="activeSlideIndex"
        :images="images"
        :is-mobile-device="isMobileDevice"
      />
    </template>

    <OurModal v-model="isShownModal" width="md" :title="title">
      <div class="modal-slider grid" :class="{ 'grid-cols-[0.5fr_2fr]': images.length > 1 }">
        <div class="slider">
          <SliderPreview
            v-if="images.length > 1"
            v-model="activeSlideIndex"
            :images="images"
            :is-mobile-device="isMobileDevice"
            :direction="'vertical'"
          />
        </div>
        <img
          class="modal-image"
          alt="active-image"
          :src="images[activeSlideIndex]?.link"
          @error="errorImageHandler"
        />
      </div>
    </OurModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import SliderPreview from "./components/SliderPreview";

export default {
  name: "ImageSlider",

  components: {
    SliderPreview,
  },

  props: {
    title: {
      type: String,
      default: "",
    },

    images: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      isShownModal: false,
      activeSlideIndex: 0,
    };
  },

  computed: {
    ...mapGetters("breakpoint", ["isMobileDevice"]),

    icons: () => ({
      AngleLeft: require("@/components/_bit/IconSvg/icons/Angle-left.svg"),
      AngleRight: require("@/components/_bit/IconSvg/icons/Angle-right.svg"),
    }),

    bgActiveImage() {
      return this.images[this.activeSlideIndex]?.link;
    },

    isMoreThanOneImage() {
      return this.images.length > 1;
    },

    isBeginning() {
      return this.activeSlideIndex > 0;
    },

    isEnd() {
      return this.activeSlideIndex < this.images.length - 1;
    },
  },

  methods: {
    errorImageHandler(event) {
      event.target.src = require("@/components/_bit/IconSvg/icons/Image.svg");
    },

    onClickOpenModal() {
      if (!this.isMobileDevice) this.isShownModal = true;
    },

    onClickPrev() {
      if (this.isBeginning) {
        this.activeSlideIndex--;
      }
    },

    onClickNext() {
      if (this.isEnd) {
        this.activeSlideIndex++;
      }
    },

    onSlideClick(index) {
      this.activeSlideIndex = index;
    },
  },
};
</script>

<style lang="postcss" scoped>
.slider {
  @apply h-auto w-full md:w-[15.625rem];
  @apply overflow-hidden;
  @apply relative;

  .active-image {
    @apply flex h-[15.625rem] w-full items-center justify-center md:w-[15.625rem];
    @apply rounded-lg border-2 border-solid border-gray-100 bg-cover bg-center;
    @apply mb-2;
    @apply cursor-pointer;
    img {
      @apply max-h-full;
    }
  }

  .counter {
    @apply absolute right-2 top-2;
    @apply flex items-center justify-center;
    @apply h-6 w-[3.375rem];
    @apply rounded-full bg-gray-100;
    @apply text-sm text-gray-900;
  }

  .buttons {
    @apply absolute top-32;
    @apply flex items-center justify-between;
    @apply w-full;
    @apply px-2.5;

    .button {
      @apply flex items-center justify-between;
      @apply h-6 w-6;
      @apply rounded-lg bg-gray-400;
    }
  }

  .modal-image {
    @apply min-w-full;
    @apply rounded-lg;
  }

  .modal-slider {
    .slider {
      @apply w-full;
    }
  }
}
</style>

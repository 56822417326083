<template>
  <OurModal
    v-model="isShownModal"
    :title="$t('title.fleaMarket.settingsModal')"
    :description="$t('fleaMarket.modalDesc')"
  >
    <SettingsForm
      ref="emailForm"
      v-model="form"
      @create="createNotice"
      @update="updateNotice"
      @delete="deleteNotice"
    />

    <template #footer-left>
      <OurButton :text="$t('button.close')" @click="closeModal" />
    </template>
  </OurModal>
</template>

<script>
import SettingsForm from "./components/SettingsForm.vue";
import apolloMixin from "@/mixins/apollo.mixin";
import { noticeEmailsQuery } from "@/views/settings/new-notices/settings/gql/queries";
import {
  createNoticeEmailMutation,
  deleteNoticeEmailMutation,
  updateNoticeEmailMutation,
} from "@/views/settings/new-notices/settings/gql/mutations";

export default {
  name: "SettingsModal",
  components: {
    SettingsForm,
  },
  mixins: [apolloMixin],

  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      form: {
        emails: [],
      },
    };
  },

  computed: {
    isShownModal: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },

  watch: {
    isShownModal: "onChangeShownModal",
  },

  created() {
    this.getData();
  },

  methods: {
    async getData() {
      const { noticeEmails } = await this.$get(noticeEmailsQuery);

      this.form.emails = noticeEmails;
    },

    async createNotice(email) {
      this.$refs.emailForm.emitForm();

      if (this.form.isValid) {
        const { createNoticeEmail } = await this.$post(
          createNoticeEmailMutation,
          { email: email },
          {
            delaySuccessNotify: false,
          }
        );

        if (createNoticeEmail.id) await this.getData();
      }
    },

    async updateNotice(object) {
      this.$refs.emailForm.emitForm();

      if (this.form.isValid) {
        const { updateNoticeEmail } = await this.$post(
          updateNoticeEmailMutation,
          { id: object.id, email: object.email },
          {
            delaySuccessNotify: false,
          }
        );

        if (updateNoticeEmail.id) await this.getData();
      }
    },

    async deleteNotice(id) {
      const { deleteNoticeEmail } = await this.$post(
        deleteNoticeEmailMutation,
        { id: id },
        {
          delaySuccessNotify: false,
        }
      );

      if (deleteNoticeEmail.id) await this.getData();
    },

    onChangeShownModal() {
      if (!this.isShownModal) {
        this.cleanForm();
      }
    },

    cleanForm() {
      this.form.emails = this.form.emails.filter((el) => el.id);
      this.getData();
    },

    closeModal() {
      this.isShownModal = false;
      this.cleanForm();
    },
  },
};
</script>

<template>
  <OurPage
    class="access-groups-edit"
    :back-route="backRoute"
    :title="$t('title.knowledgeBase.edit')"
    width="md"
  >
    <DeleteModal
      :show.sync="isShownDeleteModal"
      :knowledge-base-sections-id="knowledgeBaseSectionId"
      :knowledge-base-sections-name="form.name"
      delay-notify
      @deleteKnowledgeBaseSection="goToList"
    />

    <BaseKnowledgeForm ref="baseKnowledgeForm" v-model="form" />

    <template #footer-left>
      <OurButton class="button" :text="$t('button.save')" @click="onClickSaveButton" />
      <OurLink :route="backRoute">
        <OurButton class="button" :text="$t('button.back')" variant="secondary" />
      </OurLink>
    </template>

    <template #footer-right>
      <OurButton
        class="button"
        variant="thirdary"
        :text="$t('button.remove')"
        @click="onClickDeleteButton"
      />
    </template>
  </OurPage>
</template>

<script>
import apolloMixin from "@/mixins/apollo.mixin";
import { knowledgeBaseSectionQuery } from "./gql/queries";
import BaseKnowledgeForm from "@/views/settings/base-knowledge/_components/BaseKnowledgeForm.vue";
import DeleteModal from "@/views/settings/base-knowledge/delete/Modal.vue";
import { updateKnowledgeBaseSectionMutation } from "@/views/settings/base-knowledge/edit/gql/mutations";

export default {
  name: "BaseKnowledgeEdit",

  components: {
    DeleteModal,
    BaseKnowledgeForm,
  },

  mixins: [apolloMixin],

  data() {
    return {
      backRoute: {
        name: "BaseKnowledge",
        title: this.$t("title.knowledgeBase.chapters"),
      },
      form: {
        name: "",
      },
      isShownDeleteModal: false,
    };
  },

  computed: {
    knowledgeBaseSectionId() {
      return this.$route.params.id;
    },
  },

  created() {
    this.getData();
  },

  methods: {
    async getData() {
      const { knowledgeBaseSection } = await this.$get(knowledgeBaseSectionQuery, {
        id: this.knowledgeBaseSectionId,
      });

      if (knowledgeBaseSection) {
        this.form = {
          ...knowledgeBaseSection,
        };
      }
    },

    async onClickSaveButton() {
      this.$refs.baseKnowledgeForm.emitForm();

      if (this.form.isValid) {
        const { updateKnowledgeBaseSection } = await this.$post(
          updateKnowledgeBaseSectionMutation,
          this.form,
          {
            delaySuccessNotify: true,
          }
        );

        if (updateKnowledgeBaseSection.id) await this.$router.push({ name: "BaseKnowledge" });
      }
    },

    onClickDeleteButton() {
      this.isShownDeleteModal = true;
    },

    goToList() {
      this.$router.push({ name: "BaseKnowledge" });
    },
  },
};
</script>

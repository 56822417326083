<template>
  <t-dropdown
    :show="isShownOptions"
    :classes="dropdownClasses"
    toggle-on-focus
    :data-cy="dataCy"
    class="dropdown"
    @click="hideOptions"
  >
    <div slot="trigger" class="relative" :class="iconRotateClass">
      <OurLink
        ref="dropdown"
        :text="text"
        :size="size"
        :color="color"
        :dashed="dashed"
        :no-focus-ring="noFocusRing"
        :data-cy="`${dataCy}-button`"
        @mousedown.native="onMousedown"
      >
        <div v-if="dropdownIcon" class="dropdown-block">
          <span>
            {{ text }}
          </span>

          <OurSvgIcon
            :src="icons.AngleDown"
            class="icon"
            :color="color"
            :size="iconSize"
            :data-cy="`${dataCy}-caret`"
          />
        </div>
      </OurLink>
    </div>

    <div class="dropdown-list">
      <!-- @slot Use it to add dropdown list. -->
      <slot>
        <OurDropdownList
          v-model="selectValue"
          :options="options"
          :value-key="valueKey"
          :item-label="itemLabel"
          :data-cy="`${dataCy}-item`"
        />
      </slot>
    </div>
  </t-dropdown>
</template>

<script>
import TDropdown from "vue-tailwind/dist/t-dropdown";
import OurSvgIcon from "@/components/_bit/IconSvg";
import OurLink from "@/components/_bit/ButtonLink";
import OurDropdownList from "@/components/_bit/DropdownList";

export default {
  name: "OurDropdownLink",

  components: {
    TDropdown,
    OurSvgIcon,
    OurLink,
    OurDropdownList,
  },

  props: {
    /**
     * Set button text.
     */
    text: {
      type: String,
      default: "",
    },

    /**
     * The color of the link.
     * @values gray, red, orange, yellow, green, blue, violet, fuchsia, black, white
     */
    color: {
      type: String,
      default: "",
    },

    /**
     * The size of the button.
     * @values xs, sm, md, lg
     */
    size: {
      type: String,
      default: "md",
    },

    /**
     * Make a button border dashed.
     */
    dashed: {
      type: Boolean,
      default: undefined,
    },

    /**
     * Makes outline ring during focus inactive.
     */
    noFocusRing: {
      type: Boolean,
      default: false,
    },

    /**
     * Make the dropdown icon visible.
     */
    dropdownIcon: {
      type: Boolean,
      default: true,
    },

    /**
     * Set select value.
     */
    value: {
      type: [String, Number],
      default: "",
    },

    /**
     * Set options for component.
     */
    options: {
      type: Array,
      default: () => [],
    },

    /**
     * Label from option Object, that will be visible in the dropdown.
     */
    itemLabel: {
      type: String,
      default: "label",
    },

    /**
     * Set track value key.
     */
    valueKey: {
      type: String,
      default: "id",
    },

    /**
     * The position of dropdown list on the y-axis.
     * @values top, bottom
     */
    listYPosition: {
      type: String,
      default: "bottom",
    },

    /**
     * The position of dropdown list on the x-axis.
     * @values left, right
     */
    listXPosition: {
      type: String,
      default: "left",
    },

    /**
     * Sets data-cy attribute for correct element sampling in tests.
     */
    dataCy: {
      type: String,
      default: "",
    },
  },

  data: () => ({
    isShownOptions: false,
  }),

  computed: {
    selectValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.isShownOptions = false;
        this.$emit("input", value);
      },
    },

    icons: () => ({
      AngleDown: require("@/components/_bit/IconSvg/icons/Angle-down.svg"),
    }),

    dropdownListYPosition() {
      return this.listYPosition === "bottom" ? "top-0" : `dropdown-list-bottom-${this.size}`;
    },

    dropdownListXPosition() {
      return this.listXPosition === "right" ? "right-0" : "left-0";
    },

    iconRotateClass() {
      return this.isShownOptions ? "icon-rotate" : "";
    },

    iconSize() {
      const sizes = {
        xs: "2xs",
        sm: "xs",
        md: "sm",
        lg: "md",
      };

      return sizes[this.size];
    },

    dropdownClasses() {
      return {
        button: `block px-4 py-2 text-white transition duration-100 ease-in-out bg-blue-500 border
          border-transparent rounded shadow-sm hover:bg-blue-600 focus:border-blue-500 focus:ring-2
          focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50
          disabled:cursor-not-allowed`,
        wrapper: "inline-flex flex-col",
        dropdownWrapper: "relative z-10 mb-0 dropdown-list",
        dropdown: `${this.dropdownListYPosition} ${this.dropdownListXPosition} absolute
        rounded-lg shadow bg-white mt-1 dropdown-block`,
        enterClass: "opacity-0 scale-95",
        enterActiveClass: "transition transform ease-out duration-100",
        enterToClass: "opacity-100 scale-100",
        leaveClass: "opacity-100 scale-100",
        leaveActiveClass: "transition transform ease-in duration-75",
        leaveToClass: "opacity-0 scale-95",
      };
    },
  },

  created() {
    window.addEventListener("click", (event) => this.onClickOutside(event));
  },

  destroyed() {
    window.removeEventListener("click", (event) => this.onClickOutside(event));
  },

  methods: {
    onMousedown() {
      this.isShownOptions = !this.isShownOptions;
    },

    hideOptions() {
      this.isShownOptions = false;
    },

    onClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.isShownOptions = false;
      }
    },
  },
};
</script>

<style lang="postcss" scoped>
.dropdown {
  .dropdown-block {
    @apply flex items-center;
  }

  .icon {
    @apply transition-all duration-300;
  }

  .icon-rotate {
    .icon {
      @apply rotate-180 transform;
    }
  }

  .dropdown-list {
    @apply max-h-60 overflow-y-auto overflow-x-hidden md:!w-auto;
    @apply border-gray-300;
    @apply top-3;
  }

  :deep(.dropdown-list-bottom) {
    &-xs {
      @apply bottom-4;
    }

    &-sm {
      @apply bottom-5;
    }

    &-md {
      @apply bottom-6;
    }

    &-lg {
      @apply bottom-7;
    }
  }
}
</style>

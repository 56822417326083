<template>
  <div class="dropdown-list">
    <div
      v-for="(option, key) of options"
      :key="key"
      class="dropdown-list-option"
      :class="!canHighlightSearchResults && selectedOptionClass(option[valueKey])"
      @click="onClickOption(option[valueKey])"
    >
      <template v-if="options.length">
        <div
          v-if="canHighlightSearchResults"
          v-html="$options.filters.highlight(option[itemLabel], valueForHighlight)"
        ></div>
        <template v-else>
          {{ option[itemLabel] }}
        </template>
      </template>
    </div>
    <div v-if="!options.length && !loading" class="dropdown-list-plug text-center">
      {{ i18n.noDataToShow }}
    </div>
  </div>
</template>

<script>
import I18nServiceDefault from "@/services/_bit/I18nService";

export default {
  name: "OurDropdownList",

  filters: {
    highlight(word, query) {
      const regexp = new RegExp(query, "ig");

      return word.toString().replace(regexp, (matchedText) => `<strong>${matchedText}</strong>`);
    },
  },

  props: {
    /**
     * Set input select value.
     */
    value: {
      type: [String, Number],
      default: "",
    },

    /**
     * Set options for component.
     */
    options: {
      type: Array,
      default: () => [],
    },

    /**
     * Label from option Object, that will be visible in the dropdown.
     */
    itemLabel: {
      type: String,
      default: "label",
    },

    /**
     * Set track value key.
     */
    valueKey: {
      type: String,
      default: "id",
    },

    canHighlightSearchResults: {
      type: Boolean,
      default: false,
    },

    valueForHighlight: {
      type: String,
      default: "",
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    selectValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    getTranslation() {
      const translate = (path) => this.$t(path);
      const options = { componentName: this.$options.name };

      return new I18nServiceDefault(translate, options).getTranslation;
    },

    i18n() {
      return {
        noDataToShow: this.getTranslation("noDataToShow"),
      };
    },
  },

  methods: {
    selectedOptionClass(value) {
      return this.selectValue === value ? "selected" : "";
    },

    onClickOption(value) {
      this.selectValue = value;
    },
  },
};
</script>

<i18n>
en:
  noDataToShow: "No data to show."
ru:
  noDataToShow: "Нет данных."
ua:
  noDataToShow: "Дані відсутні."
</i18n>

<style lang="postcss" scoped>
.dropdown-list {
  &:hover {
    @apply transition duration-100 ease-in-out;
  }

  &-option {
    @apply text-base font-normal normal-case text-gray-900 no-underline;
    @apply cursor-pointer align-middle;
    @apply px-3 py-3;

    &:first-child {
      @apply rounded-t-lg;
    }

    &:last-child {
      @apply rounded-b-lg;
    }

    &:hover {
      @apply bg-gray-100;
    }

    &:active,
    &.selected {
      @apply !bg-gray-200 font-medium;
    }
  }

  &-plug {
    @apply whitespace-nowrap text-base font-normal normal-case text-gray-900 no-underline;
    @apply relative block min-h-full cursor-pointer align-middle;
    @apply px-3 py-2;
  }
}
</style>

import gql from "graphql-tag";

export const rolesQuery = gql`
  query roles {
    roles {
      id
      name
      code
      parent {
        code
      }
    }
  }
`;

export const employeesQuery = gql`
  query employees($filters: Filter) {
    employees(filters: $filters, orderBy: { column: "createdAt", direction: desc }) {
      data {
        id
        phone
        email
        firstName
        lastName
        secondName
        lastActivityAt
        roles {
          name
        }
        detail {
          ... on Employee {
            status
          }
        }
      }
      paginatorInfo {
        total
      }
    }
  }
`;

<template>
  <OurPage
    :back-route="backRoute"
    class="access-groups"
    :title="$t('title.knowledgeBase.addChapter')"
    width="md"
  >
    <ArticleForm
      ref="articleForm"
      v-model="form"
      :upload-files="uploadFiles"
      @uploadFiles="uploadFilesMutation"
      @deleteFiles="deleteFileMutation"
    />

    <template #footer-left>
      <OurButton class="button" :text="$t('button.save')" @click="onClickSaveButton" />
      <OurLink :route="backRoute">
        <OurButton class="button" :text="$t('button.back')" variant="secondary" />
      </OurLink>
    </template>
  </OurPage>
</template>

<script>
import ArticleForm from "@/views/settings/base-knowledge/chapter/_components/ArticleForm.vue";
import {
  createArticleMutation,
  deleteFileMutation,
  uploadFilesMutation,
} from "@/views/settings/base-knowledge/chapter/add/gql/mutations";
import apolloMixin from "@/mixins/apollo.mixin";

export default {
  name: "ChapterAdd",
  components: { ArticleForm },
  mixins: [apolloMixin],
  data() {
    return {
      backRoute: {
        name: "Chapter",
        title: this.$t("title.knowledgeBase.chapter"),
      },
      form: {
        name: "",
        type: "",
        content: "",
        files: [],
      },
      uploadFiles: [],
    };
  },
  methods: {
    async uploadFilesMutation(selectedFiles) {
      const { uploadFiles: files } = await this.$post(
        uploadFilesMutation,
        { files: selectedFiles },
        { withLoader: false, withNotify: false }
      );

      this.uploadFiles = files;
    },

    async deleteFileMutation(id) {
      await this.$post(deleteFileMutation, { id }, { withLoader: false, withNotify: false });
    },

    async onClickSaveButton() {
      this.$refs.articleForm.emitForm();

      if (this.form.isValid) {
        const { createArticle } = await this.$post(
          createArticleMutation,
          { sectionId: this.$route.params.id, ...this.form },
          {
            delaySuccessNotify: true,
          }
        );

        if (createArticle.id) await this.$router.push({ name: "Chapter" });
      }
    },
  },
};
</script>

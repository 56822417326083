<template>
  <div :class="themeClass">
    <div class="money-block" :class="moneyClasses">
      <div v-if="isExistSlot('left')" class="money-block-left-slot">
        <!-- @slot Use it to add something before money amount. -->
        <slot name="left" />
      </div>

      <div :data-cy="dataCy" class="sum">
        <span v-if="currencySymbolPosition.left" class="currency-symbol"
          >{{ currencySymbol }}
        </span>

        <span v-if="sum">{{ typeSymbol }}</span>

        <span>{{ preparedMoney.integer }}</span>

        <span v-if="!integer" class="penny"
          >{{ preparedMoney.delimiter }}{{ preparedMoney.penny }}
        </span>

        <span v-if="currencySymbolPosition.right" class="currency-symbol">{{
          currencySymbol
        }}</span>
      </div>

      <div v-if="isExistSlot('right')" class="money-block-right-slot">
        <!-- @slot Use it to add something after money amount. -->
        <slot name="right" />
      </div>
    </div>
  </div>
</template>

<script>
import MoneyService, { MONEY_SIGN_TYPE } from "@/services/_bit/MoneyService";
import { getThemeClass } from "@/services/_bit/UiService";

export default {
  name: "OurMoney",

  props: {
    /**
     * Set sum.
     */
    sum: {
      type: Number,
      default: 0,
    },

    /**
     * Set currency symbol.
     */
    currencySymbol: {
      type: String,
      default: "",
    },

    /**
     * Make sum planned (add brackets).
     */
    planned: {
      type: Boolean,
      default: false,
    },

    /**
     * Make sum integer.
     */
    integer: {
      type: Boolean,
      default: false,
    },

    /**
     * Set sign for sum.
     * @values default, positive, negative
     */
    sign: {
      type: String,
      default: "default",
    },

    /**
     * Set align for currency symbol.
     * @values right, left
     */
    currencySymbolAlign: {
      type: String,
      default: "right",
    },

    /**
     * Set text size of sum.
     * @values xs, sm, md, lg, xl, 2xl, 3xl, 4xl
     */
    size: {
      type: String,
      default: "md",
    },

    /**
     * Set weight.
     * @values regular, medium, bold
     */
    weight: {
      type: String,
      default: "regular",
    },

    /**
     * The color of the icon.
     * @values accent, gray, red, yellow, green, blue, violet, fuchsia, black, white
     */
    color: {
      type: String,
      default: "black",
    },

    /**
     * Set the numeral decimal scale after the comma.
     */
    numeralDecimalScale: {
      type: Number,
      default: 2,
    },

    /**
     * Set align for money block.
     * @values right, left
     */
    align: {
      type: String,
      default: "right",
    },

    /**
     * Sets data-cy attribute for correct element sampling in tests.
     */
    dataCy: {
      type: String,
      default: "",
    },
  },

  computed: {
    currencySymbolPosition() {
      return {
        left: this.currencySymbolAlign === "left",
        right: this.currencySymbolAlign === "right",
      };
    },

    typeSymbol() {
      let type = "";

      if (this.sign === MONEY_SIGN_TYPE.positive) type = "+";
      if (this.sign === MONEY_SIGN_TYPE.negative) type = "–";

      return type;
    },

    preparedMoney() {
      return new MoneyService().separatedMoney(Math.abs(this.sum), this.numeralDecimalScale);
    },

    themeClass() {
      return getThemeClass();
    },

    moneyClasses() {
      const size = `size-${this.size}`;
      const weight = `weight-${this.weight}`;
      const color = `color-${this.color}`;
      const blockAlink = this.align === "left" ? "money-block-left" : "money-block-right";
      const planned = this.planned ? "planned" : "";

      return [size, weight, color, blockAlink, planned];
    },
  },

  methods: {
    isExistSlot(slotName) {
      return !!this.$scopedSlots[slotName];
    },
  },
};
</script>

<style lang="postcss" scoped>
.money-block {
  @apply flex items-center;
  @apply whitespace-nowrap;

  &-right {
    @apply justify-end;
  }

  &-left {
    @apply justify-start;
  }

  &-right-slot {
    @apply ml-2;
  }

  &-left-slot {
    @apply mr-2;
  }
}

.planned {
  .sum {
    @apply !opacity-75;

    &:before {
      content: "(";
    }

    &:after {
      content: ")";
    }
  }
}

.size-xs {
  > .sum {
    @apply text-xs;

    .penny {
      @apply text-2xs;
    }
  }
}

.size-sm {
  > .sum {
    @apply text-sm;

    .penny {
      @apply text-xs;
    }
  }
}

.size-md {
  > .sum {
    @apply text-base;

    .penny {
      @apply text-sm;
    }
  }
}

.size-lg {
  > .sum {
    @apply text-lg;

    .penny {
      @apply text-base;
    }
  }
}

.size-xl {
  > .sum {
    @apply text-xl;

    .penny {
      @apply text-lg;
    }
  }
}

.size-2xl {
  > .sum {
    @apply text-2xl;

    .penny {
      @apply text-xl;
    }
  }
}

.size-3xl {
  > .sum {
    @apply text-3xl;

    .penny {
      @apply text-2xl;
    }
  }
}

.size-4xl {
  > .sum {
    @apply text-4xl;

    .penny {
      @apply text-3xl;
    }
  }
}

.weight {
  &-regular {
    .sum {
      @apply font-normal;
    }
  }

  &-medium {
    .sum {
      @apply font-medium;
    }
  }

  &-bold {
    .sum {
      @apply font-bold;
    }
  }
}

.color {
  &-accent {
    .sum {
      @apply text-accent;
    }
  }

  &-gray {
    .sum {
      @apply text-gray-450;
    }
  }

  &-red {
    .sum {
      @apply text-red-600;
    }
  }

  &-orange {
    .sum {
      @apply text-orange-600;
    }
  }

  &-yellow {
    .sum {
      @apply text-yellow-600;
    }
  }

  &-green {
    .sum {
      @apply text-green-600;
    }
  }

  &-blue {
    .sum {
      @apply text-blue-600;
    }
  }

  &-violet {
    .sum {
      @apply text-violet-600;
    }
  }

  &-fuchsia {
    .sum {
      @apply text-fuchsia-600;
    }
  }

  &-black {
    .sum {
      @apply text-black;
    }
  }

  &-white {
    .sum {
      @apply text-white;
    }
  }
}
</style>

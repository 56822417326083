import gql from "graphql-tag";

export const accessGroupsQuery = gql`
  query accessGroups {
    accessGroups {
      id
      name
    }
  }
`;

export const customersQuery = gql`
  query customers($filters: Filter, $page: Int, $perPage: Int) {
    customers(
      orderBy: { column: "createdAt", direction: desc }
      filters: $filters
      page: $page
      first: $perPage
    ) {
      paginatorInfo {
        currentPage
        perPage
        total
        lastPage
      }
      data {
        id
        name
        isActive
        companies {
          id
          name
        }
        accessGroup {
          id
          name
        }
        dealers {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const allCustomersQuery = gql`
  query allCustomer($filters: Filter) {
    allCustomer(filters: $filters) {
      id
      name
    }
  }
`;

<template>
  <OurModal
    v-model="isShownModal"
    :title="title"
    :width="width"
    :class="themeClass"
    :data-cy="dataCy"
    :color="color"
    no-divider
    class="our-confirm-modal"
  >
    <template #header-left-before>
      <slot name="header-left-before" />
    </template>

    <template #header-left>
      <slot name="header-left" />
    </template>

    <template #header-left-after>
      <slot name="header-left-after" />
    </template>

    <template #header-right>
      <slot name="header-right" />
    </template>

    <template #default>
      <slot />
    </template>

    <template #footer-left>
      <div class="footer">
        <OurButton
          :data-cy="`${dataCy}-accept`"
          :disabled="disableAcceptButton"
          :text="actionButtonText"
          :color="color"
          @click="emitConfirmAction"
        />

        <OurButton
          v-if="shownCancelButton"
          :color="color"
          variant="secondary"
          :text="i18n.cancel"
          :data-cy="`${dataCy}-close`"
          @click="onCloseModal"
        />
      </div>
    </template>
  </OurModal>
</template>

<script>
import { getThemeClass } from "@/services/_bit/UiService";
import I18nServiceDefault from "@/services/_bit/I18nService";

import OurButton from "@/components/_bit/Button";
import OurModal from "@/components/_bit/ContainerModal";

export default {
  name: "OurConfirmModal",

  components: {
    OurButton,
    OurModal,
  },

  props: {
    /**
     * Show cancel button.
     */
    shownCancelButton: {
      type: Boolean,
      default: true,
    },

    /**
     * Set disabled accept button.
     */
    disableAcceptButton: {
      type: Boolean,
      default: false,
    },

    /**
     * Set action button text.
     */
    actionButtonText: {
      type: String,
      default: "",
    },

    /**
     * Set width for modal.
     * @values xs, sm, md, lg, xl, 2xl, 3xl, 4xl, 5xl
     */
    width: {
      type: String,
      default: "sm",
    },

    /**
     * Set modal's title.
     */
    title: {
      type: String,
      default: "",
    },

    /**
     * Change modal state (hidden / shown).
     */
    value: {
      type: Boolean,
      default: false,
    },

    /**
     * The color of the button and modal title.
     * @values gray, red, orange, yellow, green, blue, violet, fuchsia
     */
    color: {
      type: String,
      default: "accent",
    },

    /**
     * Sets data-cy attribute for correct element sampling in tests.
     */
    dataCy: {
      type: String,
      default: "",
    },
  },

  computed: {
    isShownModal: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    themeClass() {
      return getThemeClass();
    },

    getTranslation() {
      const translate = (path) => this.$t(path);
      const options = { componentName: this.$options.name };

      return new I18nServiceDefault(translate, options).getTranslation;
    },

    i18n() {
      return {
        cancel: this.getTranslation("cancel"),
      };
    },
  },

  methods: {
    closeModal() {
      this.isShownModal = false;
    },

    onCloseModal() {
      this.closeModal();
    },

    emitConfirmAction() {
      this.$emit("actionConfirmed");
      this.closeModal();
    },
  },
};
</script>

<i18n>
en:
  cancel: "Back"
ru:
  cancel: "Назад"
ua:
  cancel: "Назад"
</i18n>

<style scoped lang="postcss">
.footer {
  @apply flex space-x-4;
}
</style>

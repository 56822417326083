import gql from "graphql-tag";

export const employeeQuery = gql`
  query employee($userId: ID!) {
    employee(userId: $userId) {
      isActive
      firstName
      lastName
      email
      isDeleted
      roles {
        id
      }
    }
    roles {
      id
      name
      code
      parent {
        code
      }
    }
  }
`;
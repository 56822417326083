<template>
  <div class="wrapper" :class="[gridColsClass, themeClass]">
    <label
      v-for="(item, index) in options"
      :key="item.value"
      class="card"
      :class="selectedItemClass(item.value)"
      :data-cy="`${dataCy}-item-${index}`"
    >
      <t-radio v-model="selectedItem" class="radio" :name="name" :value="item.value" />

      <div class="card-wrapper">
        <!-- @slot Use it to add icon. -->
        <slot v-if="withIcon" name="icon" :item="item">
          <OurSvgIcon :src="item.iconSrc" size="xl" color="blue" />
        </slot>

        <div class="card-title">{{ item.label }}</div>

        <div class="card-description">{{ item.description }}</div>
      </div>
    </label>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getThemeClass } from "@/services/_bit/UiService";

import OurSvgIcon from "@/components/_bit/IconSvg";
import TRadio from "vue-tailwind/dist/t-radio";

export default {
  name: "OurRadioCard",

  components: {
    OurSvgIcon,
    TRadio,
  },

  props: {
    /**
     * Set radio card name.
     */
    name: {
      type: String,
      required: true,
      default: "",
    },

    /**
     * Set options for component.
     */
    options: {
      type: Array,
      default: () => [],
    },

    /**
     * Set component value.
     */
    value: {
      type: [String, Number, Boolean],
      default: "",
    },

    /**
     * Set grid cols number.
     */
    gridCols: {
      type: Number,
      default: 2,
    },

    /**
     * Show / hide component icon.
     */
    withIcon: {
      type: Boolean,
      default: true,
    },

    /**
     * Sets data-cy attribute for correct element sampling in tests.
     */
    dataCy: {
      type: String,
      default: "",
    },
  },

  computed: {
    ...mapGetters("breakpoint", ["isMobileDevice"]),

    selectedItem: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    gridColsClass() {
      return {
        "grid-cols-1": this.isMobileDevice,
        "grid-cols-2": this.gridCols === 2 && !this.isMobileDevice,
        "grid-cols-3": this.gridCols === 3 && !this.isMobileDevice,
        "grid-cols-4": this.gridCols === 4 && !this.isMobileDevice,
      };
    },

    themeClass() {
      return getThemeClass();
    },
  },

  methods: {
    selectedItemClass(value) {
      return {
        "selected-option": this.selectedItem === value,
      };
    },
  },
};
</script>

<style lang="postcss" scoped>
.wrapper {
  @apply grid grid-rows-1 gap-4 md:gap-6;
}

.card {
  @apply rounded-lg border border-solid border-gray-300;
  @apply cursor-pointer pb-6 pl-6 pr-6 pt-4;
  @apply relative w-full;

  &:hover {
    @apply border-gray-400;
  }

  &:focus-within {
    @apply border-gray-500 ring-4 ring-gray-600 ring-opacity-15;
  }

  .radio {
    @apply border border-solid border-gray-300;
    @apply h-6 w-6;
    @apply absolute right-4 top-4;

    &:focus {
      @apply ring-0;
    }

    &:active {
      @apply border-gray-900 bg-gray-900;
    }

    &:checked {
      @apply border-gray-900 bg-gray-900;
    }
  }

  &-wrapper {
    @apply text-center;
  }

  &-title {
    @apply text-base font-normal text-gray-900;
    @apply mb-1 mt-3;
  }

  &-description {
    @apply text-xs font-normal text-gray-500/[85];
  }
}

.selected-option {
  @apply border-gray-500;
}
</style>

import i18n from "@/plugins/vue-i18n";

import Users from "@/views/settings/users/index";
import UsersAdd from "@/views/settings/users/add";
import UsersEdit from "@/views/settings/users/edit";

const users = [
  {
    path: "settings/users",
    name: "Users",
    component: Users,
    meta: {
      title: i18n.t("title.users._"),
    },
  },
  {
    path: "settings/users/add",
    name: "UsersAdd",
    component: UsersAdd,
    meta: {
      title: i18n.t("title.users.add"),
    },
  },
  {
    path: "settings/users/:id/edit",
    name: "UsersEdit",
    component: UsersEdit,
    meta: {
      title: i18n.t("title.users.edit"),
    },
  },
];

export default users;

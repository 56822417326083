import { ApolloClient } from "apollo-client";
import { setContext } from "apollo-link-context";
import { ApolloLink, from } from "apollo-link";
import { createUploadLink } from "apollo-upload-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { onError } from "apollo-link-error";
import { logErrorMessages } from "@vue/apollo-util";
import JwtService from "@/services/jwt.service";
import i18nService from "@/services/i18n.service";
import store from "@/store";

const gqlApiPrefix = process.env.VUE_APP_GQL_API_PREFIX;
let baseUrl = process.env.VUE_APP_API_DOMAIN;

const PROTOCOL_BACKSLASHES = "//";

const [protocol] = baseUrl.split(PROTOCOL_BACKSLASHES);
const isFullBaseUrl = protocol === "https:" || protocol === "http:";

baseUrl = baseUrl && isFullBaseUrl ? baseUrl : document.location.origin;

// HTTP connection to the API
const httpLink = createUploadLink({
  // You should use an absolute URL here
  uri: baseUrl + gqlApiPrefix,
});

const errorLink = onError((error) => {
  logErrorMessages(error);

  if (
    error.response.errors[0].message === "User is not active." ||
    error.response.errors[0].message === "Can not authenticate."
  ) {
    JwtService.destroyAuthToken();

    store.state.user.type !== "dealer"
      ? window.location.replace("/admin/login")
      : window.location.replace("/login");
  }
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = store.state.auth.token || JwtService.getAuthToken();

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
      "Version-Hash": JwtService.getVersionHash(),
      Language: i18nService.getActiveLanguage(),
    },
  };
});

const afterwareLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const context = operation.getContext();
    const { headers } = context.response;

    if (headers) {
      const token = headers.get("Refresh-Token");
      const expires = headers.get("Refresh-Expires");

      if (token) {
        store.commit("SET_AUTH", { token, expires });
      }
    }

    return response;
  });
});

// Cache implementation
const cache = new InMemoryCache();

const defaultOptions = {
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};

// Create the apollo client
const apolloClient = new ApolloClient({
  link: from([afterwareLink, errorLink, authLink, httpLink]),
  cache,
  defaultOptions,
});

export default apolloClient;

import gql from "graphql-tag";

export const createNoticeEmailMutation = gql`
  mutation createNoticeEmail($email: String!) {
    createNoticeEmail(email: $email) {
      id
    }
  }
`;

export const updateNoticeEmailMutation = gql`
  mutation updateNoticeEmail($id: ID!, $email: String!) {
    updateNoticeEmail(id: $id, email: $email) {
      id
    }
  }
`;

export const deleteNoticeEmailMutation = gql`
  mutation deleteNoticeEmail($id: ID!) {
    deleteNoticeEmail(id: $id) {
      id
    }
  }
`;

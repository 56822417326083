<template>
  <div :class="{ 'custom-width': !onlyCounter }" class="count-wrapper" :data-cy="dataCy">
    <div class="button" @click="onClickMinus">
      <OurSvgIcon :src="icons.Minus2" size="xs" :data-cy="`${dataCy}-minus`" />
    </div>
    <div v-if="onlyCounter" class="value">
      {{ count }}
    </div>
    <OurInput
      v-else
      class="input-only__digits"
      :only-digits="true"
      :value="count"
      @blur="onBlur"
      @keyup-enter="onKeyUpEnter"
      @click="onClick"
    />
    <div class="button" @click="onClickPlus">
      <OurSvgIcon class="plus" :src="icons.Plus2" size="xs" :data-cy="`${dataCy}-plus`" />
    </div>
  </div>
</template>

<script>
import OurSvgIcon from "@/components/_bit/IconSvg";
export default {
  name: "OurCounter",

  components: {
    OurSvgIcon,
  },

  props: {
    /**
     * Set value.
     */
    onlyCounter: {
      type: Boolean,
      default: true,
    },

    value: {
      type: Number,
      required: true,
    },

    /**
     * Set step size.
     */
    step: {
      type: Number,
      default: 1,
    },

    /**
     * Set min value.
     */
    min: {
      type: Number,
      default: 1,
    },

    /**
     * Set max value.
     */
    max: {
      type: Number,
      default: 999,
    },

    /**
     * Sets data-cy attribute for correct element sampling in tests.
     */
    dataCy: {
      type: String,
      default: "",
    },
  },

  computed: {
    count: {
      get() {
        return this.value;
      },

      set(value) {
        this.$emit("input", value);
      },
    },

    icons: () => ({
      Minus2: require("@/components/_bit/IconSvg/icons/Minus2.svg"),
      Plus2: require("@/components/_bit/IconSvg/icons/Plus2.svg"),
    }),
  },

  methods: {
    onClick(event) {
      event.stopPropagation();
    },
    onBlur(value) {
      this.$emit("input", value);
    },
    onKeyUpEnter(value) {
      this.$emit("input", value);
    },
    onClickMinus(event) {
      event.stopPropagation();

      const newCount = this.count - this.step;

      this.count = newCount >= this.min ? newCount : this.count;
    },

    onClickPlus(event) {
      event.stopPropagation();

      const newCount = this.count + this.step;

      this.count = newCount <= this.max ? newCount : this.count;
    },
  },
};
</script>

<style lang="postcss" scoped>
.count-wrapper {
  @apply flex items-center justify-between;
  @apply space-x-3;

  .button {
    @apply flex flex-shrink-0 items-center justify-center;
    @apply h-6 w-6 rounded-full;
    @apply bg-gray-100;
    @apply cursor-pointer;

    &:hover {
      @apply bg-gray-200;
    }

    &:active {
      @apply bg-gray-300;
    }

    .plus:deep(g rect) {
      @apply opacity-100;
    }
  }

  .value {
    @apply select-none text-sm font-normal;
    -moz-user-select: -moz-none;
    -o-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
  }

  .input-only__digits >>> input[type="number"] {
    -moz-appearance: textfield;
  }
  .input-only__digits >>> input::-webkit-outer-spin-button,
  .input-only__digits >>> input::-webkit-inner-spin-button {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  ::v-deep.input-only__digits {
    input {
      @apply p-1.5 text-center;
    }
  }
}

.custom-width {
  @apply w-full min-w-[8rem] max-w-[8rem] !important;
}
</style>

import Vue from "vue";
import Notifications from "vue-notification";
import { notify as notifyConfig } from "@/services/_bit/UiService";

Vue.use(Notifications);

const defaultDuration = {
  short: 4000,
  medium: 8000,
  long: 12000,
  permanent: 300000,
};

export default class NotifyServiceDefault {
  notify(settings) {
    const { type, title, text, code, duration, ignoreDuplicates, closeOnClick, data } = settings;

    Vue.notify({
      group: "notify",
      type,
      title: title || "",
      text: text || "",
      duration: duration || notifyConfig.duration.short || defaultDuration.short,
      ignoreDuplicates: ignoreDuplicates || false,
      closeOnClick: closeOnClick || false,
      data: { ...data, code },
    });
  }

  success(code, duration = notifyConfig.duration.short) {
    this.notify({
      type: "success",
      code: code,
      duration: duration,
    });
  }

  warning(code, duration = notifyConfig.duration.medium) {
    this.notify({
      type: "warning",
      code: code,
      duration: duration,
    });
  }

  error(code, duration = notifyConfig.duration.long) {
    this.notify({
      type: "error",
      code: code,
      duration: duration,
    });
  }

  clearAll() {
    Vue.notify({
      group: "notify",
      clean: true,
    });
  }

  setDelayed(type, message) {
    localStorage.setItem("notify", JSON.stringify({ type, message }));
  }

  getDelayed() {
    const notifyData = JSON.parse(localStorage.getItem("notify"));

    this.clearAll();

    if (notifyData) {
      const { type, message } = notifyData;

      this[type](message);

      localStorage.removeItem("notify");
    }
  }
}

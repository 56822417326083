<template>
  <div class="multistate-wrapper" :class="themeClass" @click="onClickChangeActiveOption">
    <div class="multistate-checkbox" :class="checkboxClasses">
      <OurSvgIcon
        v-if="selectedStateIcon"
        :class="selectedStateIcon"
        :src="currentInputIcon"
        :size="iconSize"
        color="white"
      />

      <t-checkbox class="checkbox" :value="selectedState" :data-cy="dataCy" />
    </div>

    <label v-if="selectedStateLabel" class="multistate-checkbox-label">
      {{ selectedStateLabel }}
    </label>
  </div>
</template>

<script>
import { getThemeClass } from "@/services/_bit/UiService";

import OurSvgIcon from "@/components/_bit/IconSvg";
import TCheckbox from "vue-tailwind/dist/t-checkbox";

export default {
  name: "OurMultiStateCheckbox",

  components: { OurSvgIcon, TCheckbox },

  props: {
    /**
     * Set checkbox multi state value.
     */
    value: {
      type: [String, Boolean, Number],
      default: "",
    },

    /**
     * Set options data for component.
     */
    optionsData: {
      type: Array,
      default: () => [
        { code: false, label: "" },
        { code: true, label: "" },
        { code: null, label: "" },
      ],
    },

    /**
     * Set component size.
     * @values sm, md, lg
     */
    size: {
      type: String,
      default: "md",
    },

    /**
     * Sets data-cy attribute for correct element sampling in tests.
     */
    dataCy: {
      type: String,
      default: "",
    },
  },

  data: () => ({
    index: 0,
    selectedState: "",
  }),

  computed: {
    themeClass() {
      return getThemeClass();
    },

    icons: () => ({
      Check: require("@/components/_bit/IconSvg/icons/Check.svg"),
      Minus2: require("@/components/_bit/IconSvg/icons/Minus2.svg"),
    }),

    options() {
      const [unSelected, selectedFull, selectedPartially] = this.optionsData;

      return [
        {
          selected: unSelected?.code,
          label: unSelected?.label || "",
        },
        {
          iconName: "Check",
          selected: selectedFull?.code,
          label: selectedFull?.label || "",
        },
        {
          iconName: "Minus2",
          selected: selectedPartially?.code,
          label: selectedPartially?.label || "",
        },
      ];
    },

    checkboxClasses() {
      const checked = this.index > 0 ? "multistate-checkbox-checked" : "";
      const size = `size-${this.size}`;

      return [checked, size];
    },

    selectedStateIcon() {
      return this.options[this.index]?.iconName;
    },

    currentInputIcon() {
      return this.icons[this.selectedStateIcon];
    },

    isCheck() {
      return this.options[this.index]?.iconName === "Check";
    },

    iconSize() {
      const size = this.size;
      const check = this.isCheck;
      let iconSize;

      if (!check && size === "lg") {
        iconSize = "md";
      } else if (check && size === "md") {
        iconSize = "xs";
      } else {
        iconSize = "sm";
      }

      return iconSize;
    },

    selectedStateLabel() {
      return this.options[this.index]?.label;
    },
  },

  watch: {
    value: {
      handler: "onChangeValue",
      immediate: true,
    },
  },

  methods: {
    onClickChangeActiveOption() {
      this.index += 1;

      if (this.index >= this.options.length) {
        this.index = 0;
      }

      this.changeSelectedState();
    },

    onChangeValue() {
      this.index = this.options.findIndex((item) => item.selected === this.value);
    },

    changeSelectedState() {
      const activeOption = this.options[this.index];

      this.selectedState = activeOption.selected;

      this.$emit("input", this.selectedState);
    },
  },
};
</script>

<style lang="postcss" scoped>
.multistate {
  &-wrapper {
    @apply flex;
    @apply cursor-pointer;
  }

  &-checkbox {
    @apply flex items-center justify-center;
    @apply rounded border border-solid border-gray-300;
    @apply bg-white;
    @apply relative;

    &:hover {
      @apply border-gray-400;
    }

    &:focus-within {
      @apply border-gray-500 ring-4 ring-gray-200;
    }

    &:active {
      @apply border-gray-900 bg-gray-900;
    }

    .checkbox {
      @apply opacity-0;
      @apply absolute left-0 top-0;
      @apply h-full w-full;
      @apply cursor-pointer;
    }

    &-checked {
      @apply border-gray-900 bg-gray-900;

      &:hover {
        @apply border-gray-900;
      }
    }

    &-label {
      @apply flex flex-col justify-center;
      @apply text-base font-normal text-gray-900;
      @apply pl-4;
    }
  }
}

.Check {
  &:deep(g) {
    @apply stroke-white stroke-[1.75];
  }

  &:deep(polygon) {
    @apply hidden;
  }
}

.size {
  &-sm {
    @apply h-5 w-5;

    & + .multistate-checkbox-label {
      @apply text-sm;
    }
  }

  &-md {
    @apply h-6 w-6;
  }

  &-lg {
    @apply h-7 w-7;

    & + .multistate-checkbox-label {
      @apply text-lg;
    }
  }
}
</style>

<template>
  <div
    :data-cy="dataCy"
    class="our-link-wrapper"
    @focus="onFocus"
    @keydown="onKeydown"
    @blur="onBlur"
    @click.self="onClick"
    @mouseover="onMouseover"
  >
    <router-link
      v-if="isPresentRoute"
      class="our-link"
      :class="linkClasses"
      :to="route"
      :target="targetValue"
      :event="getEvent"
    >
      <span class="our-link-content" @click="onClick">
        <slot>
          {{ text }}
        </slot>
      </span>
    </router-link>

    <a
      v-else
      class="our-link"
      :class="linkClasses"
      :href="href"
      :data-cy="dataCy"
      @click.prevent="onClick"
    >
      <slot>
        {{ text }}
      </slot>
    </a>
  </div>
</template>

<script>
import { getThemeClass } from "@/services/_bit/UiService";

export default {
  name: "OurLink",

  props: {
    /**
     * Set button text.
     */
    text: {
      type: String,
      default: "",
    },

    /**
     * Set url link for the button.
     */
    url: {
      type: String,
      default: "",
    },

    /**
     * Set the router link for the button.
     */
    route: {
      type: Object,
      default: () => ({}),
    },

    /**
     * The color of the link.
     * @values gray, red, orange, yellow, green, blue, violet, fuchsia, black, white
     */
    color: {
      type: String,
      default: "",
    },

    /**
     * The size of the button.
     * @values xs, sm, md, lg
     */
    size: {
      type: String,
      default: "md",
    },

    /**
     * Make a button border dashed.
     */
    dashed: {
      type: Boolean,
      default: undefined,
    },

    /**
     * Open url link in new window.
     */
    targetBlank: {
      type: Boolean,
      default: false,
    },

    /**
     * Makes a link inactive.
     */
    disabled: {
      type: Boolean,
      default: false,
    },

    /**
     * Makes outline ring during focus inactive.
     */
    noFocusRing: {
      type: Boolean,
      default: false,
    },

    /**
     * Makes link inline.
     */
    inline: {
      type: Boolean,
      default: false,
    },

    /**
     * Sets a button type.
     * @values phone, email, link
     */
    type: {
      type: String,
      default: "link",
    },

    /**
     * Sets data-cy attribute for correct element sampling in tests.
     */
    dataCy: {
      type: String,
      default: "",
    },
  },

  computed: {
    targetValue() {
      return this.targetBlank ? "_blank" : "_self";
    },

    href() {
      const types = {
        phone: "tel:",
        email: "mailto:",
        link: "",
      };

      return `${types[this.type]}${this.url}`;
    },

    linkClasses() {
      const size = `size-${this.size}`;
      const themeClass = getThemeClass();
      const focusRing = this.noFocusRing || this.$slots["default"] ? "" : "focus-ring";
      const display = this.$slots["default"] ? "flex" : this.inline ? "inline" : "inline-block";
      const classes = {
        "our-link-dashed": this.dashed === true,
        "our-link-dashed-hidden": this.dashed === false,
        "our-link-dashed-hover": this.dashed === undefined && !this.$slots["default"],
      };

      return [size, themeClass, focusRing, display, classes, this.color];
    },

    isPresentRoute() {
      for (let key in this.route) return true;

      return false;
    },

    getEvent() {
      return !this.disabled ? "click" : "";
    },
  },

  methods: {
    onClick() {
      if (!this.url || this.disabled) {
        this.$emit("click");
      } else {
        window.open(this.href, this.targetValue);
      }
    },

    onMouseover() {
      this.$emit("mouseover");
    },

    onFocus() {
      this.$emit("focus");
    },

    onKeydown() {
      this.$emit("keydown");
    },

    onBlur(event) {
      this.$emit("blur", event);
    },
  },
};
</script>

<style lang="postcss" scoped>
.size {
  &-xs {
    @apply text-xs ring-offset-1;
  }

  &-sm {
    @apply text-sm ring-offset-1;
  }

  &-md {
    @apply text-base ring-offset-2;
  }

  &-lg {
    @apply text-lg ring-offset-4;
  }
}

.our-link {
  @apply transition duration-100 ease-in-out;

  &-wrapper {
    @apply inline-block;
  }

  &-content {
    @apply w-full;
  }

  &-dashed {
    @apply border-b border-dashed;

    &-hover {
      @apply mb-px border-none;

      &:hover {
        @apply mb-0 border-b border-dashed;
      }
    }

    &-hidden {
      @apply border-none;
    }
  }

  &:hover {
    @apply !text-opacity-80;
  }

  &:active {
    @apply !text-opacity-70;
  }

  &:focus {
    @apply !text-opacity-80;
  }
}

.focus-ring {
  &:focus {
    @apply rounded;
    @apply ring-4 !ring-opacity-10;
  }
}

.accent {
  @apply border-accent text-accent;

  &:focus {
    @apply ring-accent;
  }
}

.gray {
  @apply border-gray-500 text-gray-500;

  &:focus {
    @apply ring-gray-500;
  }
}

.red {
  @apply border-red-500 text-red-500;

  &:focus {
    @apply ring-red-500;
  }
}

.orange {
  @apply border-orange-500 text-orange-500;

  &:focus {
    @apply ring-orange-500;
  }
}

.yellow {
  @apply border-yellow-500 text-yellow-500;

  &:focus {
    @apply ring-yellow-500;
  }
}

.green {
  @apply border-green-500 text-green-500;

  &:focus {
    @apply ring-green-500;
  }
}

.blue {
  @apply border-blue-500 text-blue-500;

  &:focus {
    @apply ring-blue-500;
  }
}

.violet {
  @apply border-violet-500 text-violet-500;

  &:focus {
    @apply ring-violet-500;
  }
}

.fuchsia {
  @apply border-fuchsia-500 text-fuchsia-500;

  &:focus {
    @apply ring-fuchsia-500;
  }
}

.black {
  @apply border-gray-900 text-gray-900;

  &:focus {
    @apply ring-gray-900;
  }
}

.white {
  @apply border-white text-white;

  &:focus {
    @apply ring-white;
  }
}
</style>

import i18n from "@/plugins/vue-i18n";

import Roles from "@/views/settings/roles/index";
import RolesAdd from "@/views/settings/roles/add";
import RolesEdit from "@/views/settings/roles/edit";

const roles = [
  {
    path: "settings/roles",
    name: "Roles",
    component: Roles,
    meta: {
      title: i18n.t("title.roles._"),
    },
  },
  {
    path: "settings/roles/add/:id",
    name: "RolesAdd",
    component: RolesAdd,
    meta: {
      title: i18n.t("title.roles.add"),
    },
  },
  {
    path: "settings/roles/:id/edit",
    name: "RolesEdit",
    component: RolesEdit,
    meta: {
      title: i18n.t("title.roles.edit"),
    },
  },
];

export default roles;

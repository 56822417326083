<template>
  <div class="notice-form">
    <OurGroups>
      <OurGroup class="product-information" :title="$t('title.fleaMarket.basic')">
        <OurSelect
          v-model="form.category"
          :options="categories"
          :label="$t('label.category')"
          :placeholder="$t('placeholder.selectCategory')"
        />

        <OurInput
          v-if="isOwnType"
          v-model="form.name"
          :label="$t('page.fleaMarket.productName')"
          :placeholder="$t('placeholder.productName')"
          :error="nameError"
        />

        <OurInput
          v-if="isAllType"
          v-model="form.name"
          :label="$t('page.fleaMarket.productName')"
          :placeholder="$t('placeholder.selectProductName')"
          :error="nameError"
        />

        <div class="switch-rating-block">
          <OurSwitch
            v-model="isUsed"
            color="gray"
            name="condition"
            size="sm"
            :label="$t('page.fleaMarket.productCondition.productUsed')"
          />

          <OurRatingInput
            v-if="isUsed"
            v-model="form.condition"
            class="rating"
            :label="$t('page.fleaMarket.productCondition.assessment')"
            size="lg"
            selectable
            no-counter
          />
        </div>

        <OurTextarea
          v-model="form.description"
          :label="$t('label.description')"
          :placeholder="$t('placeholder.productDescription')"
          rows="4"
          :error="descriptionError"
        />

        <div class="location-block">
          <OurInput
            v-model="form.location"
            class="location"
            :label="$t('label.location')"
            :placeholder="$t('placeholder.location')"
          />

          <OurCheckbox v-model="form.isActive" :label="$t('page.fleaMarket.active')" />
        </div>
      </OurGroup>

      <OurGroup class="product-information" :title="$t('title.fleaMarket.quantityPrice')">
        <div class="quantity-currency-block">
          <OurInput
            v-model="form.quantity"
            class="quantity"
            :label="$t('label.quantity')"
            :placeholder="$t('placeholder.productQuantity')"
            :error="quantityError"
          />

          <OurSelect
            v-model="form.currency"
            class="currency"
            :options="currenciesList"
            :label="$t('label.currency')"
            :placeholder="$t('placeholder.selectCurrency')"
            :error="currencyError"
          />
        </div>

        <OurMoneyInput v-model="price" type="range" :disabled="disabled" :error="priceError" />
      </OurGroup>

      <OurGroup class="product-information" :title="$t('title.fleaMarket.images')">
        <OurFileInput
          :label="$t('label.selectFile')"
          :files="files"
          button-size="xl"
          multiple
          :max-files="maxFiles"
          :allowed-file-types="allowedFileTypes"
          @changeFiles="onChangeFiles"
          @deleteFile="deleteFile"
        />
      </OurGroup>

      <OurGroup :title="$t('title.contacts')">
        <OurInput
          v-model="form.contactName"
          class="input-name"
          :label="$t('label.fullName')"
          :placeholder="$t('placeholder.name')"
          :error="contactNameError"
        />

        <OurPhoneInput
          v-model="form.contactPhone"
          class="input-phone"
          :label="$t('label.phoneNumber')"
          :placeholder="$t('placeholder.phone')"
          :flags="false"
          :error="contactPhoneError"
          dropdown-disabled
        />
      </OurGroup>
    </OurGroups>
  </div>
</template>

<script>
import ValidationService from "@/services/validation.service";
import { required } from "vuelidate/lib/validators";
import { TYPE_VALUES } from "@/api/fleaMarket";
import { mapState, mapGetters } from "vuex";

const { phoneMaxLength, phoneMinLength } = ValidationService;

const { own, all } = TYPE_VALUES;

export default {
  name: "NoticeForm",

  props: {
    value: {
      type: Object,
      default: () => {},
    },

    type: {
      type: String,
      default: own,
    },

    uploadFiles: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      onlyCountries: [],
      allowedFileTypes: [".jpg", ".jpeg", ".png"],
      maxFiles: 10,
      files: [],
      disabled: {
        endRange: true,
      },
      price: {
        startRange: {
          placeholder: this.$t("placeholder.productPrice"),
          label: this.$t("page.fleaMarket.productPrice"),
          symbol: "",
          sum: "",
        },
        endRange: {
          placeholder: this.$t("placeholder.productPrice"),
          label: this.$t("page.fleaMarket.priceGeneral"),
          symbol: "",
          sum: "",
        },
      },
      isUsed: false,
      maxCondition: 5,
      form: {
        name: "",
        description: "",
        category: "",
        price: "",
        currency: "",
        quantity: "",
        location: "",
        isNew: true,
        condition: 0,
        files: [],
        contactName: "",
        contactPhone: "",
        isActive: true,
        filesData: [],
      },
    };
  },

  validations: {
    form: {
      name: { required },
      currency: { required },
      price: { required },
      quantity: { required },
      description: { required },
      contactName: { required },
      contactPhone: { required, phoneMinLength, phoneMaxLength },
    },
  },

  computed: {
    ...mapState("fleaMarket", ["categories"]),
    ...mapGetters("currencies", ["currenciesList"]),

    isOwnType() {
      return this.type === own;
    },

    isAllType() {
      return this.type === all;
    },

    startRange() {
      return this.price.startRange;
    },

    quantity() {
      return this.form.quantity;
    },

    priceGeneral() {
      const sum = this.form.price * this.form.quantity;
      const currencySymbol = this.currenciesList.find(
        (currency) => currency.id === this.form.currency
      )?.symbol;

      return {
        sum,
        currencySymbol,
      };
    },

    nameError() {
      const isDirty = this.$v.form.name.$dirty;
      const isFilled = this.$v.form.name.required;

      let error = "";

      if (isDirty && !isFilled) {
        error = this.$t("validation.required");
      }

      return error;
    },

    priceError() {
      const isDirty = this.$v.form.price.$dirty;
      const isFilled = this.$v.form.price.required;

      let error = "";

      if (isDirty && !isFilled) {
        error = this.$t("validation.required");
      }

      return error;
    },

    currencyError() {
      const isDirty = this.$v.form.currency.$dirty;
      const isFilled = this.$v.form.currency.required;

      let error = "";

      if (isDirty && !isFilled) {
        error = this.$t("validation.required");
      }

      return error;
    },

    quantityError() {
      const isDirty = this.$v.form.quantity.$dirty;
      const isFilled = this.$v.form.quantity.required;

      let error = "";

      if (isDirty && !isFilled) {
        error = this.$t("validation.required");
      }

      return error;
    },

    descriptionError() {
      const isDirty = this.$v.form.description.$dirty;
      const isFilled = this.$v.form.description.required;

      let error = "";

      if (isDirty && !isFilled) {
        error = this.$t("validation.required");
      }

      return error;
    },

    contactNameError() {
      const isDirty = this.$v.form.contactName.$dirty;
      const isFilled = this.$v.form.contactName.required;

      let error = "";

      if (isDirty && !isFilled) {
        error = this.$t("validation.required");
      }

      return error;
    },

    contactPhoneError() {
      const isDirty = this.$v.form.contactPhone.$dirty;
      const isFilled = this.$v.form.contactPhone.required;
      const isMinLength = this.$v.form.contactPhone.phoneMinLength;
      const isMaxLength = this.$v.form.contactPhone.phoneMaxLength;

      let error = "";

      if (isDirty) {
        if (!isFilled) error = this.$t("validation.required");
        else if (!isMinLength || !isMaxLength) error = this.$t("validation.phoneLength");
      }

      return error;
    },
  },

  watch: {
    value: {
      handler: "getForm",
      deep: true,
    },

    isUsed: {
      handler: "getCondition",
      deep: true,
    },

    files: {
      handler: "getFormFiles",
      deep: true,
    },

    uploadFiles: {
      handler: "getUploadFiles",
      deep: true,
    },

    startRange: {
      handler: "onChangeStartRange",
      deep: true,
    },

    quantity: {
      handler: "onChangeQuantity",
      deep: true,
    },
  },

  methods: {
    getForm() {
      if (this.value) {
        this.form = { ...this.value, defaultCategory: this.value?.category };
        this.isUsed = !this.value.isNew;

        const price = this.form.price;
        const { filesData } = this.value;

        this.price.startRange = price;
        this.price.endRange = price * this.form.quantity;

        if (filesData.length) {
          this.files = filesData.map((file) => {
            const { id, name, link: imageUrl } = file;

            return { id, name, imageUrl };
          });
        }
      }
    },

    getFormFiles() {
      this.form.files = this.files.map((file) => file.id);
      this.form.filesData = this.files.map((file) => {
        const { id, name, imageUrl: link } = file;

        return { id, name, link };
      });
    },

    async onChangeFiles(selectedFiles) {
      if (selectedFiles.length) {
        await this.$emit("uploadFiles", selectedFiles);
      }
    },

    getUploadFiles() {
      const files = this.uploadFiles;

      files.forEach((file) => {
        const { id, name, link: imageUrl } = file;

        this.files.push({ id, name, imageUrl });
      });
    },

    async deleteFile(id) {
      await this.$emit("deleteFiles", id);

      this.files.forEach((file) => {
        if (file.id !== id) return file;
      });
    },

    getCondition() {
      this.form.isNew = !this.isUsed;
      if (this.form.isNew) this.form.condition = this.maxCondition;
    },

    onChangeStartRange() {
      this.form.price = this.price.startRange;
      this.price.endRange = this.form.price * this.form.quantity;
    },

    onChangeQuantity() {
      this.price.endRange = this.form.price * this.form.quantity;
    },

    emitForm() {
      if (this.$v.$invalid) {
        this.$v.$touch();

        return;
      }

      this.form.isValid = true;
      this.$emit("input", this.form);
    },
  },
};
</script>

<style lang="postcss" scoped>
.notice-form {
  .location-block {
    @apply flex justify-between;

    .location {
      @apply w-1/2;
    }
  }

  .switch-rating-block {
    @apply flex flex-col space-y-4;
    @apply pl-4;
  }

  .quantity-currency-block {
    @apply flex space-x-4;

    .quantity {
      @apply w-1/3;
    }

    .currency {
      @apply w-2/3;
    }
  }
}
</style>

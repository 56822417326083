import ApiService from "@/services/api.service";

async function login(userData) {
  const response = await ApiService.post("/auth/login", userData);

  return response.data.data;
}

async function loginByToken(token) {
  const response = await ApiService.post("/auth/login-by-token", { auth: token });

  return response.data.data;
}

async function refreshAuth() {
  const response = await ApiService.post("/auth/refresh");

  return response.data.data;
}

async function resetPassword(userData) {
  return ApiService.patch("/auth/reset-password", userData, {
    withNotify: true,
  });
}

async function changeUserPassword(userData) {
  const response = await ApiService.patch("/users/change-password", userData, {
    withNotify: true,
  });

  return response.data.data;
}

async function logout() {
  await ApiService.post("/auth/logout");
}

export { login, loginByToken, refreshAuth, resetPassword, changeUserPassword, logout };

<template>
  <tbody :class="theme">
    <tr>
      <td :colspan="colspan">
        <slot>
          <OurEmptyBlock
            class="empty-table-msg"
            :description="emptyTableMsg"
            size="sm"
            data-cy="empty-table-msg"
          />
        </slot>
      </td>
    </tr>
  </tbody>
</template>

<script>
import { getThemeClass } from "@/services/_bit/UiService";

import OurEmptyBlock from "@/components/_bit/EmptyBlock";

export default {
  name: "OurEmptyTableMsg",

  components: { OurEmptyBlock },

  props: {
    colspan: {
      type: Number,
      default: 1,
    },

    filters: {
      type: Object,
      default: () => ({}),
    },

    i18n: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    emptyTableMsg() {
      const isFiltersApplied = !!Object.keys(this.filters).length;

      return isFiltersApplied ? this.i18n.noResultsForFilters : this.i18n.noItems;
    },

    theme() {
      return getThemeClass();
    },
  },
};
</script>

<style lang="postcss" scoped>
.empty-table-msg {
  @apply my-8;
}
</style>

import gql from "graphql-tag";

export const updateEmployeeMutation = gql`
  mutation updateEmployee(
    $userId: ID!
    $firstName: String!
    $lastName: String!
    $email: String!
    $isActive: Boolean
    $roleId: ID
  ) {
    updateEmployee(
      userId: $userId
      firstName: $firstName
      lastName: $lastName
      email: $email
      isActive: $isActive
      roleId: $roleId
    ) {
      id
    }
  }
`;

<template>
  <div class="date-button" :dataCy="dataCy">
    <t-button
      class="arrow arrow-left py-2"
      :data-cy="`${dataCy}-prev`"
      @click="onClickShiftRange('prev')"
    >
      <OurSvgIcon class="icon" size="sm" :src="icons.AngleLeft" />
    </t-button>

    <t-button
      :id="id"
      class="date-current"
      :text="title"
      :data-cy="`${dataCy}-title`"
      @blur="onBlur"
      @click="onClickRangeSet"
    />

    <t-button
      class="arrow arrow-right"
      :data-cy="`${dataCy}-next`"
      @click="onClickShiftRange('next')"
    >
      <OurSvgIcon class="icon" size="sm" :src="icons.AngleRight" />
    </t-button>
  </div>
</template>

<script>
import OurSvgIcon from "@/components/_bit/IconSvg";
import TButton from "vue-tailwind/dist/t-button";

export default {
  components: {
    OurSvgIcon,
    TButton,
  },

  props: {
    title: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },

    dataCy: {
      type: String,
      default: "",
    },
  },

  computed: {
    icons: () => ({
      AngleLeft: require("@/components/_bit/IconSvg/icons/Angle-left.svg"),
      AngleRight: require("@/components/_bit/IconSvg/icons/Angle-right.svg"),
    }),
  },

  methods: {
    onClickShiftRange(action) {
      this.$emit("clickShiftRange", action);
    },

    onClickRangeSet() {
      this.$emit("clickRangeSet");
    },

    onBlur() {
      this.$emit("blur");
    },
  },
};
</script>

<style lang="postcss" scoped>
.date {
  &-button {
    @apply flex rounded-lg;
    @apply h-full;

    &:active {
      @apply bg-opacity-5;
    }

    &:focus-within {
      @apply ring-4 ring-gray-600 ring-opacity-15;
    }
  }

  &-current {
    @apply rounded-none shadow-none;
    @apply text-sm font-medium text-gray-900 sm:text-base;
    @apply border-0 bg-gray-900 bg-opacity-5;

    &:hover {
      @apply bg-opacity-10;
    }

    &:focus {
      @apply border-0 ring-0;
    }
  }
}

.arrow {
  @apply flex items-center;
  @apply bg-gray-900 bg-opacity-5 shadow-none;
  @apply border-0;
  @apply py-[0.71875rem];

  &:hover {
    @apply bg-opacity-10;
  }

  &:focus {
    @apply border-0 ring-0;
  }

  &-left {
    @apply rounded-l-lg rounded-r-none;
  }

  &-right {
    @apply rounded-l-none rounded-r-lg;
  }
}

.icon {
  @apply cursor-pointer;

  &:deep(g [fill]) {
    @apply fill-current text-gray-900;
  }
}

@media (max-width: 576px) {
  .date-current {
    padding-left: 2px;
    padding-right: 2px;
  }
}
</style>

<template>
  <div class="access-groups-form">
    <OurGroup>
      <OurInput
        v-model="form.name"
        :label="$t('page.catalog.name')"
        :placeholder="$t('placeholder.rolesName')"
        :error="nameError"
      />
      <permissions-list
        v-if="permissionsList.length"
        :permissions-list="permissionsList"
        @updated-permissions-list="generatePermissionsIdsList"
      />
    </OurGroup>
  </div>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import PermissionsList from "./PermissionsList.vue";
import ValidationService from "@/services/validation.service";
import { permissionsQuery } from "@/views/settings/roles/add/gql/queries";
import apolloMixin from "@/mixins/apollo.mixin";
import _ from "lodash";
const { TEXT_MAX_LENGTH } = ValidationService;

export default {
  name: "RolesForm",
  components: { PermissionsList },

  mixins: [apolloMixin],

  props: {
    value: {
      type: Object,
      default: () => {},
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      form: {
        name: "",
        permissions: [],
      },
      permissionsList: [],
    };
  },

  validations: {
    form: {
      name: { required, maxLength: maxLength(TEXT_MAX_LENGTH) },
    },
  },

  computed: {
    nameError() {
      const isDirty = this.$v.form.name.$dirty;
      const isFilled = this.$v.form.name.required;

      const maxLength = this.$v.form.name.maxLength;

      let error = "";

      if (isDirty && !isFilled) {
        error = this.$t("validation.required");
      } else if (isDirty && !maxLength) {
        error = this.$t("validation.textMaxLength", {
          length: TEXT_MAX_LENGTH,
        });
      }

      return error;
    },
    roleId() {
      const employeeId = 1;
      const dealerId = 7;

      if (this.$route.params.id === "employee") {
        return employeeId;
      } else if (this.$route.params.id === "dealer") {
        return dealerId;
      }

      return this.$route.params.id;
    },
  },

  watch: {
    value: {
      handler: "getForm",
      deep: true,
    },
  },

  created() {
    this.getPermissions();
  },

  methods: {
    getForm() {
      if (this.value) {
        this.form = { ...this.value };
      }
    },

    generatePermissionsIdsList(list) {
      this.form.permissions = list.flatMap(({ groupList: array }) =>
        array.filter((item) => item.isActive).map(({ id }) => id)
      );
    },

    emitForm() {
      if (this.$v.$invalid) {
        this.$v.$touch();

        return;
      }

      this.form.isValid = true;
      this.$emit("input", this.form);
    },

    async getPermissions() {
      const { role } = await this.$get(permissionsQuery, { id: this.roleId });

      if (role) {
        this.form.name = this.isEdit ? role.name : "";

        this.permissionsList = _(role.permissions)
          .groupBy((x) => x.group)
          .map((groupList, groupName) => {
            const every = groupList.every((item) => item.isActive === true);
            const some = groupList.some((item) => item.isActive);

            return {
              groupName,
              isActive: this.isEdit ? (every === false ? some : every) : false,
              groupList: this.isEdit
                ? groupList
                : groupList.map((el) => ({ ...el, isActive: false })),
            };
          })
          .value();
      }
    },
  },
};
</script>

<style scoped lang="postcss">
.permission:deep {
  @apply flex flex-col;
  .switch-wrapper {
    width: unset;
  }
}
</style>

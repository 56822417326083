<template>
  <div class="user-form">
    <OurGroups>
      <OurGroup class="contact-information">
        <OurInput
          v-model="form.title"
          :label="$t('label.title')"
          :placeholder="$t('placeholder.title')"
          :disabled="editForm"
        />
        <OurInput
          v-model="form.taxNumber"
          :label="$t('label.taxNumber')"
          :placeholder="$t('placeholder.taxNumber')"
          :disabled="editForm"
        />
        <OurInput
          v-model="form.managerName"
          :label="$t('label.manager')"
          :placeholder="$t('placeholder.chooseFromList')"
          :disabled="editForm"
        />
        <OurLink
          v-if="dealers.length"
          size="sm"
          color="gray"
          :dashed="true"
          @click="openDealersTable"
        >
          <div>{{ $t("page.dealersCompany.users") }}: {{ dealers.length }}</div>
        </OurLink>
        <OurLink v-else size="sm" color="gray" :dashed="true" @click="openAddDealersModal">
          <div>{{ $t("page.dealersCompany.addUser") }}</div>
        </OurLink>
      </OurGroup>
    </OurGroups>
    <div class="mt-4">
      <OurCheckbox v-model="form.isActive" :label="$t('label.companyIsActive')" />
    </div>
  </div>
</template>

<script>
export default {
  name: "CompanyForm",

  props: {
    value: {
      type: Object,
      default: () => {},
    },

    employees: {
      type: Array,
      default: () => [],
    },

    editForm: {
      type: Boolean,
      default: false,
    },

    dealers: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      form: {
        title: "",
        oneCId: "",
        managerName: "",
        taxNumber: "",
        isActive: false,
      },
    };
  },

  watch: {
    value: {
      handler: "getForm",
      deep: true,
    },
  },

  methods: {
    getForm() {
      if (this.value) {
        this.form = { ...this.value };
      }
    },

    emitForm() {
      this.form.isValid = true;
      this.$emit("input", this.form);
    },
    addAccessGroup() {
      this.$emit("openModal");
    },
    openDealersTable() {
      this.$emit("openDealersModal");
    },
    openAddDealersModal() {
      this.$emit("openAddDealersModal");
    },
  },
};
</script>

<style lang="postcss" scoped>
.user-form {
  .footer-block {
    @apply flex justify-between;
    @apply w-full;
    @apply pl-4;
  }
}
</style>

import i18n from "@/plugins/vue-i18n";

const AccessGroups = () =>
  import(/* webpackChunkName: "access-groups" */ "@/views/settings/access-groups/index");

const AccessGroupsAdd = () =>
  import(/* webpackChunkName: "access-groups" */ "@/views/settings/access-groups/add");

const AccessGroupsEdit = () =>
  import(/* webpackChunkName: "access-groups" */ "@/views/settings/access-groups/edit");

const accessGroups = [
  {
    path: "settings/access-groups",
    name: "AccessGroups",
    component: AccessGroups,
    meta: {
      title: i18n.t("title.accessGroups._"),
    },
  },
  {
    path: "settings/access-groups/0",
    name: "AccessGroupsAdd",
    component: AccessGroupsAdd,
    meta: {
      title: i18n.t("title.accessGroups.add"),
    },
  },
  {
    path: "settings/access-groups/:id/edit",
    name: "AccessGroupsEdit",
    component: AccessGroupsEdit,
    meta: {
      title: i18n.t("title.accessGroups.edit"),
    },
  },
];

export default accessGroups;
